@charset "UTF-8";
.profile-notifications {
    .datetimepicker-inline {
        background: none;
        color: $link-color;
        border: none;
        cursor: pointer;
        font-weight: bold;
        text-align: center;
        width: 50px;
        &[disabled] {
            color: $muted-color;
            -webkit-text-fill-color: $muted-color;
            opacity: 1 !important;
            cursor: not-allowed;
        }
    }
    .checkbox label {
        > span {
            white-space: normal;
        }
        * {
            vertical-align: baseline;
        }
    }
    .selectpicker-inline {
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        font-size: inherit;
        line-height: inherit;
        min-height: initial;
        color: $link-color;
        font-weight: bold;
        float: none;
        vertical-align: initial;
        &.disabled {
            color: $muted-color;
            opacity: 1.0;
        }
        .bs-caret {
            display: none;
        }
    }
}