@charset "UTF-8";
.query-subtask-synopsis {
    @include custom-bs-grid;
    @include content-padding;
    @include clearfix;
    clear: both;
    margin-bottom: 50px;
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        .panel-separator {
            display: none;
        }
        .synopsis-states {
            margin-bottom: 20px;
        }
        .synopsis-caption {
            margin-top: 0;
            margin-bottom: 20px;
        }
    }
    .query-subtask-info {
        .card-item {
            margin-bottom: 0;
            @include max-width-breakpoint($breakpoint-pad-landscape) {
                margin-bottom: 20px;
            }
        }
        .card-item-header {
            width: 100%;
        }
        .card-item-header .card-item-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .subtask-summary-title {
        font-size: 16px;
        line-height: 18px;
        a {
            color: $info-alter-color;
        }
    }
    .subtask-summary-image {
        flex-shrink: 0;
        position: relative;
        width: 200px;
        height: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: opacity 300ms linear;
        will-change: opacity;
        z-index: 1;
        &:before {
            content: '\f00e';
            opacity: 0;
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            right: 50%;
            width: 22px;
            margin: {
                top: -12px;
                left: -12px;
                right: -11px;
            }
            font-size: 24px;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            color: rgba($light-color, 0.5);
            z-index: 3;
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.56);
            z-index: 2;
        }
        &:hover:before,
        &:hover:after {
            opacity: 1;
        }
    }
    .subtask-summary-photos {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        transform: translate3d(0,0,0);
        width: 100%;
        height: 200px;
        margin: {
            top: 30px;
            bottom: 20px;
        }
        border-radius: 3px;
        background-color: $body-color;
        overflow: hidden;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: auto;
            margin: {
                top: 25px;
                bottom: 0;
                left: -20px;
                right: -20px;
            }
        }
    }
    .subtask-summary-info {
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            order: 2;
        }
    }
    .subtask-summary-offers {
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            order: 1;
        }
    }
    .subtask-summary-description {
        font-size: 14px;
        line-height: 1.71;
    }
    .subtask-summary-properties {
        border-collapse:separate;
        width: 100%;
        margin-bottom: 30px;
        border-radius: 3px;
        background-color: $light-color;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px $border-color;
        tr {
            box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
        }
        td {
            padding: {
                left: 12px;
                right: 35px;
            }
            font-size: 12px;
            font-weight: 300;
            line-height: 2.5;
            color: $dark-color;
            &:first-child {
                font-weight: normal;
            }
        }
    }
    .map-wrapper {
        padding-bottom: 10px;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: auto;
            margin: {
                left: -20px;
                right: -20px;
            }
        }
        .map {
            max-width: 100%;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                height: 300px;
            }
        }
    }
    .customer-forbidden {
        padding: 12px;
        margin-bottom: 0px;
        border: 1px solid #faebcc;
        border-radius: 4px;
        font-size: 13px;
        color: #8a6d3b;
        background-color: #fcf8e3;
    }
}
