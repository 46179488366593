.activities {
    .activity-more {
        padding: {
            top: 12px;
            bottom: 21px;
        }
        background-color: $light-color;
        text-align: center;
        .activity-more-btn {
            color: #000000;
        }
    }
    &.pipeline-activities {
        margin-bottom: 35px;
        margin-top: 20px;
        padding: 0;
        .dd-control {
            display: none;
        }
        .pipeline-activities-content {
            padding-left: 25px;
        }
        .activity-item {
            position: relative;
            padding-left: 0;
            &:hover {
                .dd-control {
                    display: block;
                }
            }
        }
        .pipeline-activity-title {
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 1;
        }
        .pipeline-activity-item {
            &.required {
                font-weight: bold;
            }
        }
    }
}