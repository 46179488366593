@charset "UTF-8";
.map-with-point-write-autocomplete-input {
    top: 9px !important;
    width:  430px;
    height: 33px;
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        top: 50px !important;
        left: 9px !important;
        width: 92%;
    }
}