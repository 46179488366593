@charset "UTF-8";
.navbar-body-wrapper {
    position: fixed;
    top: $top-menu-height;
    right: 0;
    left: $sidebar-width-full;
    padding: {
        bottom: 4px;
    }
    margin-left: $sidbar-menu-width;
    background-color: $body-background-color;
    z-index: 3;
    overflow-x: auto;
    -webkit-transition: left 150ms ease-out;
    transition: left 150ms ease-out;
    will-change: left;
    @include max-width-breakpoint($breakpoint-desktop-mid) {
        left: 0;
        margin-left: 0 !important;
    }
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        padding-bottom: 0;
    }
    & + .main-content {
        padding-top: $toolbar-height;
    }
    .navbar-body-top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        width: 100%;
        margin: 0;
        padding: {
            left: $navbar-side-pad;
            right: $navbar-side-pad;
        }
        border-radius: 0;
        height: $toolbar-height;
        overflow-x: auto;
        overflow-y: hidden;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            padding-left: 0;
            padding-right: 0;
        }
        li {
            line-height: 0;
            padding: {
                top: 13px;
                bottom: 13px;
                left: 10px;
                right: 10px;
            }
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                padding: {
                    left: 10px;
                    right: 10px;
                }
                .btn-group {
                    font-size: 0;
                    white-space: nowrap;
                    .btn {
                        float: none;
                    }
                }
                &.pull-right {
                    float: left !important;
                }
                &.checkbox {
                    margin: {
                        left: 10px;
                    }
                    label {
                        white-space: nowrap;
                    }
                }
                &:first-child {
                    padding-left: $navbar-side-pad;
                }
                &::last-child {
                    padding-right: $navbar-side-pad;
                }
            }
            a {
                padding: 0;
            }
        }
        .btn-link {
            padding: 0;
            outline: 0;
            border: 0;
            line-height: 18px;
            &.btn-success {
                color: $success-color;
                &:focus,
                &:hover {
                    color: darken($success-color, 10%);
                    background-color: $light-color;
                }
            }
            &.btn-danger {
                color: $danger-color;
                &:focus,
                &:hover {
                    color: darken($danger-color, 10%);
                    background-color: $light-color;
                }
            }
            &.btn-simple {
                color: #5a5a5a;
                &:focus,
                &:hover {
                    color: #333333;
                    background-color: $light-color;
                }
            }
        }
    }
}