.deals-money-report {
    padding-top: 30px;
    &--container{
        padding-left: 15px;
        padding-right: 15px;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__search-read{
        padding-left: 17px;
        padding-right: 22px;
    }
    .table{
        .pl-30{
            padding-left: 30px !important;
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                padding-left: 15px !important;
            }
        }
        .table-item{
            padding: 15px 25px;
        }
        .table__empty-line{
            td{
                background-color: transparent !important;
                border: none !important;
            }
        }
    }
}