@charset "UTF-8";
.personal-security {
    .security-personal-settings-form {
        margin-bottom: 20px;
        .alert {
            margin-bottom: 0px;
        }
    }
    .form {
        hr {
            margin-top: 6px;
        }
    }
}