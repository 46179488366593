@charset "UTF-8";
.activity-item {
    position: relative;
    margin: 0;
    padding: {
        top: 13px;
        bottom: 13px;
        left: 12px;
        right: 12px;
    }
    border-radius: 0;
    background-color: $light-color;
    border-bottom: 1px solid #EEE;
    .activity-item-info {
        font-size: 14px;
        line-height: 1.17;
        color: #646464;
        padding-top: 10px;
    }
    .activity-item-title {
        font-size: 13px;
        font-weight: bold;
        line-height: 16px;
        color: #9B9B9B;
    }
    .activity-item-icon {
        display: inline-block;
        margin-top: -4px;
        color: $success-color;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 1px;
        text-align: center;
    }
    .activity-item-date {
        display: inline-block;
        font: {
            weight: normal;
            size: 11px;
        }
        line-height: 1.27;
        color: $navbar-link-color;
    }
    .activity-item-author {
        display: inline-block;
    }
    .activity-item-price {
        font: {
            weight: bold;
            size: 16px;
        }
        line-height: 18px;
        color: $success-color;
    }
    .activity-item-caption {
        padding: {
            top: 7px;
        }
        font-size: 13px;
        line-height: 1.23;
        color: $body-color;

        .workflow-title {
            font-size: 12px;
            font-weight: bold;
        }
    }

    .activity-item-content {
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 1.23;
        color: $body-color;
    }
    .activity-item-content + .activity-item-info{
        padding-top: 0;
    }

    .activity-item-fileslist {
        margin-top: 10px;
        clear: both;
        @include clearfix;
    }
    .activity-item-file {
        display: block;
        float: left;
        margin-right: 10px;
        font-size: 13px;
        line-height: 0.92;
        color: #3e8acc;
        @include max-width-breakpoint($breakpoint-phones) {
            float: none;
            margin-bottom: 2px;
        }
    }
    .activity-item-document {
        margin-right: 10px;
        font-size: 13px;
        line-height: 1.23;
        color: #3e8acc;
    }
    .delete-button{
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 34px;
        border: none;
        background-color: $dark-color;
        opacity: 0.25;
        color: $light-color;
        z-index: 2 !important;
        &:hover {
            background-color: $danger-color;
            opacity: 1.0;
        }

        @include max-width-breakpoint($breakpoint-tablet) {
            width: auto;
            position: inherit;
            top: inherit;
            left: inherit;
            top: inherit;
            bottom: inherit;
            min-height: 34px;
            background-color: $danger-color;
            color: #ffffff;
            font-size: 12px;
            margin-top: 13px;
            border-radius: 3px;
            display: block;
            opacity: 1;
            padding: 6px 12px;
        }
        
    }
    &:hover{
        .delete-button{
            display: block;
        }
    }
    
    &.activity-item-pinned {
        background-color: #fffcd3;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .well {
        display: inline-block;
        margin-bottom: 0;
        & + .well {
            margin-top: 20px;
        }
    }
    .group-button{
        display: flex;
        justify-content: flex-end;
    }
}