@charset "UTF-8";
.password-recovery {
    .password-recovery-form {
        position: absolute;
        width:    330px;
        top:      50%;
        left:     50%;
        padding:  15px;
        margin: {
            left: -165px;
            top: -146px;
        }
        .form-holder {
            margin-bottom: 5px;
        }
    }
    .password-recovery-textbox {
        position:      relative;
        padding:       10px;
        margin-bottom: 10px;
        height:        auto;
        font-size:     16px;
        box-sizing:    border-box;
    }
    .password-recovery-alert-danger {
        margin-bottom: 10px;
    }
    .password-recovery-alert-sucess {
        margin-bottom: 5px;
    }
}