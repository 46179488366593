@charset "UTF-8";
.query-subtask-conditions {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: {
        top: 20px;
        right: 0;
        bottom: 30px;
        left: 28px;
    }
}
