@charset "UTF-8";
.notfound {
    .notfound-wrapper-phone {
        width: 300px !important;
        position: absolute !important;
        top: 50%;
        left: 50%;
        margin: {
            top: -136px;
            left: -150px;
        }
    }
    .notfound-wrapper-desktop {
        width: 600px !important;
        position: absolute !important;
        top: 50%;
        left: 50%;
        margin: {
            top: -123px;
            left: -300px;
        }
    }
}