@charset "UTF-8";
.account-deal-stage {
    .edit-button {
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 34px;
        width: 34px;
        border: none;
        background-color: $dark-color;
        opacity: 0.25;
        color: $light-color;
        z-index: 999;
        &:hover {
            background-color: $primary-color;
            opacity: 1.0;
        }
        @include max-width-breakpoint($breakpoint-tablet) {
            width: auto;
            position: inherit;
            top: inherit;
            left: inherit;
            top: inherit;
            bottom: inherit;
            min-height: 34px;
            background-color: $primary-color;
            color: #ffffff;
            font-size: 12px;
            margin-top: 13px;
            border-radius: 3px;
            display: block;
            opacity: 1;
            margin-left: auto;
            padding: 6px 12px;
            margin-right: 10px;
        }
    }
    .activity-item:hover .edit-button {
        display: block;
    }
}