@charset "UTF-8";
.toggler {
    position: relative;
    display: inline-block;
    margin: 0;
    line-height: 1;
    user-select: none;
    cursor: pointer;
    &-indicator {
        position: relative;
        display: inline-block;
        &:before {
            content: '';
            display: block;
            width: 28px;
            height: 13px;
            background-color: $toggler-off-color;
            border-radius: 40px;
            transition: background-color 150ms ease;
            will-change: background-color;
        }
        &:after {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
            height: 11px;
            width: 11px;
            background-color: $light-color;
            border-radius: 50%;
            transition: transform 150ms ease;
            will-change: transform;
        }
        .checkbox:checked + &:before,
        .checkbox.checked + &:before {
            background-color: $toggler-on-color;
        }
        .checkbox:checked + &:after,
        .checkbox.checked + &:after {
            transform: translateX(14px); /*width before - width after + border width * 2 */
            transition: transform 150ms ease;
            will-change: transform;
        }
        .checkbox.unknown + &:after {
            transform: translateX(8px); /*width before - width after + border width * 2 */
            transition: transform 150ms ease;
            will-change: transform;
        }
        .checkbox:disabled + &:before,
        .checkbox:disabled + &:after {
            opacity: 0.65;
            cursor: not-allowed;
        }
    }
    &-label {
        font-weight: normal;
        position: relative;
        top: -1px;
        display: inline-block;
    }
    .checkbox {
        display: none;
    }
}
