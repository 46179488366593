@charset "UTF-8";
.account-deal-stages {
    .deal-stages-table {
        tr {
            &:hover {
                .dd-control {
                    display: block;
                }
            }
            .dd-control-cell {
                padding-right: 0 !important;
            }
            .dd-control {
                display: none;
                @include max-width-breakpoint($breakpoint-pad-portrait) {
                    padding-left: 5px;
                }
            }
        }
        .cell-stage-title {
            min-width: 250px !important;
        }
        .cell-stage-description {
            width: 100% !important;
        }
        th.text-center {
            text-align: center !important;
        }
    }
}