@charset "UTF-8";
.customer-create {
    .has-feedback .phone-group-field .form-control {
        padding-right: 12px;
    }
    .phone-country-code {
        @include clearfix;
    }
    .phone-group-field {
        width: 75%;
        float: left;
    }
    .phone-additional-input {
        width: 20%;
        float: right;
        padding-right: 0;
    }
    .phone-group-field .control-label {
        margin-top: 0;
    }
    .phone-help-block {
        margin: {
            left: 0;
            bottom: 0;
        }
    }
    .control-label-data {
        .control-label-span {
            color: $link-color;
            border-bottom: 1px dotted $link-color;
            cursor: pointer;
            &.active {
                color: $dark-color;
                border-bottom: none;
                cursor: default;
            }
        }
    }
    .modal-footer{
        &__flex{
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            &::after,
            &::before {
                display: none;
            }
            .btn{
                float: none !important;
                & + .btn{
                    margin-left: 0 !important;
                }
            }
        }
    }
}