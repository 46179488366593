@charset "UTF-8";
.customer-contacts {
    @include custom-bs-grid;
    @include content-padding;
    @include clearfix;
    clear: both;
    .contacts-container {
        width: 100%;
    }
}
