@charset "UTF-8";
.synopsis {
    @include custom-bs-grid;
    @include content-padding;
    @include clearfix;
    clear: both;
    .panel-separator {
        margin-bottom: 15px;
    }
    .customer-forbidden {
        padding: 12px;
        margin-bottom: 0px;
        color: #8a6d3b;
        background-color: #fcf8e3;
        border: 1px solid #faebcc;
        border-radius: 4px;
        font-size: 13px;
    }
}
.synopsis-title {
    padding-top: 5px;
    line-height: 2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &--between{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}
.synopsis-subtitle {
    font-size: 16px;
    line-height: 18px;
    color: $body-color;
    .checkbox {
        margin: {
            top: 2px;
            bottom: 0;
        }
    }
    @include max-width-breakpoint($breakpoint-iphones) {
        margin-top: 0;
    }
}
.synopsis-subtitle__pipeline{
    margin-bottom: 20px;
    @include max-width-breakpoint($breakpoint-pad-landscape) {
        padding-top: 40px;
    }
}
.synopsis-subtask-list {
    padding-top: 10px;
}
.synopsis-tasks {
    @include clearfix;
    position: relative;
    .card-item {
        position: relative;
        display: inline-block;
        margin-right: 20px;
        width: 100%;
        float: none;
        z-index: 0;
        font-size: 13px;
        vertical-align: top;
    }
}
.synopsis-tasks-toggler {
    position: absolute;
    top: 10px;
    right: 10px;
    .synopsis-toggler {
        margin-left: 15px;
    }
    .synopsis-toggler-label {
        margin-right: 5px;
        font-size: 14px;
        color: #9b9b9b;
    }
    .checkbox {
        margin: 0;
        float: right;
        @include max-width-breakpoint($breakpoint-iphones) {
            float: none;
        }
    }
    @include max-width-breakpoint($breakpoint-iphones) {
        position: relative;
        top: 0;
        right: 0;
        .synopsis-toggler-label {
            display: block;
            margin-bottom: 8px;
            clear: both;
        }
        .synopsis-toggler {
            margin-left: 0;
            margin-right: 15px;
        }
    }
}
.synopsis-info {
    &-item {
            margin-bottom: 15px !important;
    }
    &-value,
    &-name {
        display: block;
        font-size: 13px;
    }
    .customer-card-holder {
        margin: {
            top: 10px;
            bottom: 0;
        }
    }
    .card-item {
        margin-bottom: 0;
        @include max-width-breakpoint($breakpoint-pad-landscape) {
            margin-bottom: 20px;
        }
    }
}
.synopsis-states {
    width: 100%;
    max-width: 100%;
    padding-top: 2px;
    font-size: 13px;
    line-height: 1.67;
    color: #333;
    td {
        width: 50%;
        border-bottom: 1px solid $border-color;
        .fa {
            margin-left: 5px;
        }
    }
    td + td {
        padding-left: 10px;
    }
}
.synopsis-caption {
    font-size: 13px;
    line-height: 1.38;
}
.synopsis-activity {
    @include clearfix;
    margin-bottom: 15px;
}
.synopsis-info-value{
    .text-info{
        color: $dark-text;
        &.success{
            color: $success-color;
        }
        &.fail{
            color: $danger-color;
        }
    }
}
.synopsis-row--custom{
    margin-left: -22px !important;
    margin-right: -22px !important;
    & > [class^="col-"] {
        padding-left: 22px;
        padding-right: 22px;
    }
}