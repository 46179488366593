@charset "UTF-8";
.customer-communications {
    .nothing-found-message-wrapper {
        margin-top:   -40px;
        margin-left:   40px;
        margin-right:  40px;
    }
    .content-title {
        line-height: 2;
    }
    .communications-table.table {
        td {
            width: auto;
            ul {
                margin-bottom: 0px;
                padding-left:  0px;
                list-style:    none;
                li:before {
                    content:       "–";
                    padding-right: 5px;
                    color:         #4990E2;
                }
            }
        }
        th {
            color: $dark-color;
        }
        th.text-center {
            text-align: center;
        }
        .cell-checkbox {
            width: 19px;
            padding-right: 0 !important;
            input[type="radio"], input[type="checkbox"] {
                margin-top: 0;
            }
            .radio, .checkbox {
                margin-top: 0;
                padding-top: 0;
                label > span {
                    padding-left: 0;
                }
                label > span::after,
                label > span::before {
                    top: -7px;
                }
            }
        }
        .cell-type {
            width: 190px;
        }
        .cell-moment {
            width:       80px;
            white-space: nowrap;
        }
        .cell-item {
            width:       100px;
            white-space: nowrap;
        }
        .cell-direction {
            width:         10px;
            white-space:   nowrap;
            padding-left:  0!important;
            padding-right: 0!important;
            font-size:     15px;
        }
        .cell-info {
            width:       80px;
            white-space: nowrap;
            text-align:  left;
            .play-button {
                font-size:    1.0em;
                margin-right: 5px;
            }
            .duration {
                padding-left: 5px;
            }

            .play {
                padding-top:    3px;
                padding-bottom: 2px;
                padding-left:   7px;
                padding-right:  7px;
                i {
                    font-size: 1.0em;
                }
            }
            .mejs__container {
                display: inline-block;
                vertical-align: middle;
            }
            .mejs__duration {
                color: $body-color;
            }
        }
        .cell-contract {
            width:       100px;
            white-space: nowrap;
        }
        .cell-comment {
            width: 400px;
            display: none;
        }
    }
    .result-item-pager {
        margin-top:    40px;
        margin-bottom: 40px;
    }
}