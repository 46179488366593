@charset "UTF-8";
.base-master {
    .required-asterisk {
        font: {
            size: 12px;
            weight: bold;
        }
        line-height: 1.67;
        color: $danger-color;
    }
    .col-half {
        width: 50%;
        float: left;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            padding-right: 10px;
        }
        @include max-width-breakpoint($breakpoint-iphones) {
            width: 100%;
            float: none;
            padding-right: 0;
            .form-group {
                float: none;
                max-width: 100% !important;
            }
        }
        & + .col-half {
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                padding-left: 10px;
            }
            @include max-width-breakpoint($breakpoint-iphones) {
                padding-left: 0;
            }
            .form-group {
                float: right;
                width: 100%;
                @include max-width-breakpoint($breakpoint-iphones) {
                    float: none;
                    max-width: 100%;
                }
            }
        }
    }
    .help-block {
        margin-top: 4px;
        font: {
            size: 11px;
            weight: bold;
        }
        line-height: 1.27;
        color: #8a6d3b;
    }
    .clearfix-full {
        clear: both;
    }
    .pt-0 {
        padding-top: 0 !important;
    }
    .p-0 {
        padding: 0 !important;
    }
    .m-0 {
        margin: 0 !important;
    }
    .mb-0 {
        margin-bottom: 0 !important;
    }
    .mb-15 {
        margin-bottom: 15px !important;
    }
    .mb-30 {
        margin-bottom: 30px !important;
    }
    .mt-0 {
        margin-top: 0 !important;
    }
    .mt-15 {
        margin-top: 15px !important;
    }
    .ml-a {
        margin-left: auto;
    }
    .ml-30{
        margin-left: 30px;
    }
    .ml-sm-a{
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            margin-left: auto;
        }
    }
    .d-flex {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
    }
    .justify-content-between {
        -ms-flex-pack: justify;
        -webkit-box-pack: justify;
                justify-content: space-between;
    }
    .align-items-center {
        -ms-flex-align: center;
        -webkit-box-align: center;
                align-items: center;
    }
    .control-helper {
        margin-top: 16px;
        font-size: 14px;
        color: $dark-color;
        vertical-align: middle;
        outline: none;
        &.question {
            color: $warning-color;
        }
        &.info {
            color: $success-color;
        }
    }
    .w-100 {
        width: 100%;
    }
    .text-truncate {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .text-italic {
        font-style: italic;
    }
    .text-bold {
        font-weight: bold;
    }
    .hidden-mobile {
        @include max-width-breakpoint($breakpoint-mobile-menu) {
            display: none !important;
        }
    }
    .hidden-desktop {
        @include min-width-breakpoint($breakpoint-mobile-menu) {
            display: none !important;
        }
    }
    .space__nowrap {
        white-space: nowrap;
        
    }
    .space__normal--md{
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            white-space: normal;
        }
    }
    .fl-left{
        float: left;
    }
    .overflow-none{
        overflow: initial !important;
    }
}
