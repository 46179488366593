@charset "UTF-8";
.customer-card {
    margin: {
        top: 15px;
        bottom: 0px;
    }
    @include max-width-breakpoint($breakpoint-iphones) {
        margin: {
            bottom: 0px;
        }
    }
}
.customer-card-index {
    padding: {
        top: 13px;
        left: 13px;
        right: 13px;
    }
    .not-selected-message {
        padding-bottom: 13px;
    }
}
.customer-card-title {
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.25;
    color: #4990e2;
    @include max-width-breakpoint($breakpoint-iphones) {
        display: block;
        float: left;
        width: 60%;
    }
}
.customer-card-agent {
    float: right;
    margin-top: 5px;
    font: {
        size: 11px;
        weight: bold;
    }
    line-height: 0.9;
    color: $navbar-link-color;
    @include max-width-breakpoint($breakpoint-iphones) {
        display: block;
        width: 40%;
        text-align: right;
    }
}
.customer-card-name {
    display: block;
    margin-top: 10px;
    font: {
        size: 13px;
        weight: bold;
    }
    line-height: 1.25;
    color: #4990e2;
    clear: both;
}
.customer-card-description {
    font-size: 13px;
    line-height: 1.54;
    color: $body-color;
}
.customer-card-contact {
    font: {
        size: 13px;
        weight: bold;
    }
    line-height: 1.54;
    color: $body-color;
    & + .customer-card-contact {
        margin-top: 5px;
    }
    .fa {
        width: 22px;
        text-align: center;
    }
}
.customer-card-name {
    display: block;
    font-weight: bold;
}
.customer-card-row {
    @include clearfix;
    padding: {
        top: 10px;
        bottom: 10px;
    }
    border-bottom: 1px solid #eeeeee;
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }
}