@charset "UTF-8";
.personal-accounts {
    .accounts-table.recrm-table {
        th, td {
            white-space: nowrap;
        }
        .disabled {
            width: 8%;
            color: #cccccc;
        }
        .account {
            width: 8%;
        }
        .owner {
            width: 6%;
        }
        .status {
            width: 8%;
        }
        .is-default {
            width: 8%;
            text-align:  center;
        }
        .actions {
            min-width: 80px;
            position: relative;
            text-align:  center;
            .btn {
                position: absolute;
                left: 16px;
                top: 50%;
                margin-top: -15px;
            }
        }
    }
    .account-item.leaving {
        .main-status {
            display: none;
        }
        .leaving-status {
            display: inline-block;
        }
        .leave-button {
            display: inline-block;
        }
    }
    .account-item {
        height: 48px;
        .leaving-status {
            display: none;
        }
        .leave-button {
            display: none;
        }
    }
    .account-item:hover {
        .leave-button {
            display: inline-block;
        }
    }
    .invite-item {
        height: 48px;
        .accept-button {
            display: none;
        }
    }
    .invite-item:hover {
        .accept-button {
            display: inline-block;
        }
    }
}
