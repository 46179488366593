@charset "UTF-8";
%create-subtask-base {
    .map-modal-subtask {
        height: 500px;
        margin: {
            bottom: 15px;
            top: 0;
        }
    }

    .alert.validation-summary {
        margin-top: 0;
    }
}