@charset "UTF-8";
// Класс называется adverts-page, а не adverts, чтобы его не блокировал AdBlock
.adverts-page {
    .nothing-found-message-wrapper {
        margin-top:   -40px;
        margin-left:   40px;
        margin-right:  40px;
    }
    .content-title {
        line-height: 2;
    }
    .adverts-table.table {
        td {
            width: auto;
        }
        th {
            color: $dark-color;
        }
        th.text-center {
            text-align: center;
        }
        th.text-left{
            text-align: left;
        }
        .cell-checkbox {
            width: 19px;
            padding-right: 0 !important;
            input[type="radio"], input[type="checkbox"] {
                margin-top: 0;
            }
            .radio, .checkbox {
                margin-top: 0;
                padding-top: 0;
                label > span {
                    padding-left: 0;
                }
                label > span::after,
                label > span::before {
                    top: -7px;
                }
            }
        }
        .cell-updated {
            width: 80px;
            white-space: nowrap;
        }
        .cell-status {
            width: 70px;
        }
        .table-input-container {
            .form-control, .btn {
                padding: 3px 6px;
                height: auto;
                min-height: auto;
            }
            .form-control {
                min-width: 80px;
            }
        }
        .edit-button {
            color: $link-color;
            padding: 0px;
            margin: 0px;
            border: none;
            border-bottom: 1px dotted $link-color;
            background: none;
            line-height: inherit;
            font-size: inherit;
            min-height: initial;
            float: none;
            vertical-align: baseline;
            cursor: pointer;
            white-space: nowrap;
            &:disabled {
                cursor: not-allowed;
                color: $muted-color;
                opacity: 1.0;
                border-bottom: none;
            }
        }
        .th-images{
            padding: 0 !important;
        }
        .td-images{
            padding-right: 0 !important;
            font-size: 24px;
            color: $disabled;
            text-align: center;
            [data-popup-img]{
                width: 36px;
                height: 36px;
                display: block !important;
                margin-left: auto;
                margin-right: auto;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .adverts-item-title {
            min-width: 160px;
            @include max-width-breakpoint($breakpoint-notebook-big){
                min-width: 130px;
            }
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 160px;
            }

        }
        @include max-width-breakpoint($breakpoint-1800){

            th, td{
                padding-left: 18px !important;
                padding-right: 18px !important;
            }

        }
        @include max-width-breakpoint($breakpoint-notebook-big){
            th, td{
                padding-left: 13px !important;
                padding-right: 13px !important;
            }
            .cell-updated{
                white-space: normal;
            }
        }
    }
    .result-item-pager {
        margin-top:    40px;
        margin-bottom: 40px;
    }
}

.adverts-sidebar {
    .control-label-data {
        .control-label-span {
            margin-left: 5px;
            color: $link-color;
            border-bottom: 1px dotted $link-color;
            cursor: pointer;
        }
    }
}

.fancybox-toolbar{
    opacity: 1 !important;
    visibility: visible !important;
}

.content-title-sort-group{
    .dropdown-menu{
        display: flex !important;
        position: inherit;
        left: inherit;
        top: inherit;
        float: none;
        padding: 0;
        margin: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background-color: transparent;
        margin-left: -15px;
        margin-right: -15px;
        li{
            padding-left: 15px;
            padding-right: 15px;
            a{
                padding: 0;
                color: #568FE1;
                font-size: 14px;
                &:focus{
                    outline: none;
                }
            }
            &.selected{
                display: flex;
                align-items: center;
                a{
                    font-weight: bold;
                }
                &::after{
                    content: '\f885';
                    font-family: 'Font Awesome 5 Free';
                    font-size: 14px;
                    color: #568FE1;
                    font-weight: bold;
                    margin-left: 5px;
                }
            }
        }
    }
    .bootstrap-select{
        margin-left: 0;
        & + .bootstrap-select{
            margin-left: 30px;
        }
    }
    .dropdown-toggle{
        display: none !important;
    }
    [data-original-index="1"]{
        &::after{
            transform: scaleY(-1);
        }
    }
}

.group-btn-sort{
    list-style-position: none;
    padding: 0;
    margin: 0;
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    justify-content: flex-end;
    @include max-width-breakpoint($breakpoint-pad-portrait){
        flex-wrap: wrap;
    }
    li{
        padding-left: 15px;
        padding-right: 15px;
        display: flex;
        align-items: center;
    }
    button{
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;
        color: #568FE1;
        font-size: 14px;
        position: relative;
        &.active{
            font-weight: bold;
            &::after{
                //content: '\f885';
                font-family: 'Font Awesome 5 Free';
                font-size: 14px;
                color: #568FE1;
                font-weight: bold;
                margin-left: 5px;
                transition: transform .25s ease;
                display: inline-block;
            }
            &.up{
                &::after{
                    content: '\f885';
                }
            }
            &.down{
                &::after{
                    content: '\f160';
                }
            }
        }
        &:focus{
            outline: none;
        }

    }
}
