@charset "UTF-8";
.base-master {
    .base-master-body {
        &.sidebar-hidden {
            .main {
                margin-left: -$sidebar-width-full;
                -webkit-transition: margin-left 200ms linear;
                transition: margin-left 200ms linear;
                will-change: margin-left;
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    margin-left: 0
                }
            }
            .navbar-body-wrapper {
                left: 0;
                -webkit-transition: left 200ms linear;
                transition: left 200ms linear;
                will-change: left;
            }
        }
        .sidebar {
            -webkit-box-flex: 0;
                -ms-flex: none;
                    flex: none;
            width: $sidebar-width-full;
            min-height: 100%;
            margin-top: -$top-menu-height;
            margin-bottom: 0;
            margin-right: 0;
            margin-left: inherit;
            background-color: $light-color;
            -webkit-transform: translateX(-$sidebar-width-full);
            transform: translateX(-$sidebar-width-full);
            -webkit-transition: transform 200ms linear;
            -webkit-transition: -webkit-transform 200ms linear;
            transition: -webkit-transform 200ms linear;
            transition: transform 200ms linear;
            transition: transform 200ms linear, -webkit-transform 200ms linear;
            z-index: $animation-layers-index + 10;
            will-change: transform;
            border-right: 1px solid $border-color;
            border-radius: 0;
            & + .main-content {
                -webkit-transform: translateX(-$sidebar-width-full);
                transform: translateX(-$sidebar-width-full);
                -webkit-transition: -webkit-transform 200ms linear;
                transition: -webkit-transform 200ms linear;
                transition: transform 200ms linear;
                transition: transform 200ms linear, -webkit-transform 200ms linear;
                will-change: transform;
            }
            &:not(.is-active) {
                & + .main-content {
                    width: 100%;
                }
            }
            &.is-active {
                -webkit-transform: translateX(0);
                transform: translateX(0);
                will-change: transform;
                & + .main-content {
                    -webkit-box-flex: inherit;
                        -ms-flex: inherit;
                            flex: inherit;
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                }
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    overflow-y: auto;
                    -webkit-transform: translateX(-$sidebar-width - $sidbar-menu-full-width);
                    transform: translateX(-$sidebar-width - $sidbar-menu-full-width);
                    -webkit-transition: none;
                    transition: none;
                    & + .main-content {
                        -webkit-box-flex: inherit;
                            -ms-flex: inherit;
                                flex: inherit;
                        -webkit-transform: translateX(0);
                        transform: translateX(0);
                    }
                }
            }
            &.is-mobile-active {
                display: block;
                margin-top: 0;
                margin-left: 0;
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
                -webkit-transform: translateX(0);
                -webkit-transform: translate3d(0, 0, 0);
                transform: translateX(0);
                transform: translate3d(0, 0, 0);
                -webkit-transition: -webkit-transform 300ms ease-out;
                transition: -webkit-transform 300ms ease-out;
                transition: transform 300ms ease-out;
                transition: transform 300ms ease-out, -webkit-transform 300ms ease-out;
                -webkit-transition: transform 300ms ease-out;
                & + .main-content {
                    -webkit-box-flex: inherit;
                        -ms-flex: inherit;
                            flex: inherit;
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                }
            }
            .form-menu {
                margin-bottom: 15px;
                &:last-child {
                    margin-bottom: 0;
                }
                .btn {
                    background-color: #728ba2;
                    border-color: #728ba2;
                }
                .dropdown-toggle {
                    padding: {
                        top: 7px;
                        bottom: 8px;
                    }
                    text-align: left;
                    .caret {
                        float: right;
                        margin-top: 5px;
                    }
                }
                .dropdown-toggle-text {
                    display: block;
                    float: left;
                    width: 95%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    line-height: 15px;
                    font: {
                        size: 14px;
                        weight: 500;
                    }
                    color: $light-color;
                }
            }
            .submenu {
                .btn-group {
                    .btn {
                        padding: {
                            top: 5px;
                            bottom: 5px;
                            left: 0px;
                            right: 0px;
                        }
                        color: $dark-color;
                        & + .btn {
                            border-left: 0;
                        }
                    }
                }
            }
            .btn-group {
                .btn {
                    padding: {
                        top: 8px;
                        bottom: 6px;
                        left: 12px;
                        right: 12px;
                    }
                    font-size: 12px;
                    line-height: 1.5;
                    &:active,
                    &.active {
                        -webkit-box-shadow: initial;
                                box-shadow: initial;
                        border-color: #ADADAD;
                        color: $dark-color;
                    }
                }
            }
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                position: fixed;
                width: $sidebar-width;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: $animation-layers-index + 10;
                -webkit-transform: translateX(-$page-width);
                transform: translateX(-$page-width);
                & + .main-content {
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                    -webkit-transition: none;
                    transition: none;
                }
                .sidebar-close {
                    display: block;
                    cursor: pointer;
                }
            }
        }
        .sidebar-breadcrumbs {
            margin: 0;
            padding: 0;
            list-style-type: none;
            @include clearfix;
            li {
                float: left;
                padding-right: 2px;
                a {
                    color: $navbar-link-color;
                }
            }
        }
        .sidebar-close {
            &:hover {
                color: $dark-color;
                cursor: pointer;
            }
        }
        .sidebar-header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            position: relative;
            position: -webkit-sticky;
            position: sticky;
            top: 0;
            height: $top-menu-height;
            padding: {
                left: 20px;
                right: 20px;
            }
            color: $navbar-link-color;
            border-bottom: 1px solid $border-color;
            background-color: $light-color;
            z-index: 2;
        }
        .sidebar-title {
            font-size: 14px;
            color: $navbar-link-color;
            line-height: 1.43;
            &-link {
                color: $link-color;
            }
            span {
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .sidebar-body {
            position: relative;
            padding: 20px;
            z-index: 1;
        }
        .sidebar-footer {
            padding: 20px;
            border-top: 1px solid $border-color;
        }
        .sidebar-divider {
            width: 100%;
            margin: 10px 0;
            height: 1px;
            background-color: $border-color;
        }
        .sidebar-nav-link {
            position: relative;
            display: block;
            padding: 12px;
            color: $link-color;
            line-height: 20px;
            border-radius: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            .badge {
                margin-top: 2px;
            }
        }
        .sidebar-nav-item {
            &.active {
                .sidebar-nav-link {
                    background-color: $unit-color;
                    color: $light-color;
                    .badge {
                        color: $primary-color;
                        background-color: $light-color;
                        margin-top: 2px;
                    }
                }
            }
        }
        .sidebar-nav-title {
            &.active {
                .sidebar-nav-link {
                    font-weight: bold;
                    color: $light-color;
                }
                .sidebar-nav-button {
                    color: $light-color;
                    &:hover {
                        color: $primary-color;
                        border-top: 1px solid $primary-color;
                        border-bottom: 1px solid $primary-color;
                        border-right: 1px solid $primary-color;
                    }
                    &:focus,
                    &:active {
                        color: $light-color;
                    }
                }
            }
            .sidebar-nav-link {
                padding-right: 40px;
                font-weight: bold;
            }
        }
        .sidebar-nav-button {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 40px;
            background: none;
            border: none;
            color: $primary-color;
            outline: none;
            &:hover {
                background-color: #eeeeee;
            }
            &.active,
            &:focus,
            &:active {
                background-color: $unit-color;
                color: $light-color;
            }
            .fa {
                vertical-align: middle;
            }
        }
        .sidebar-toggler {
            display: block;
            width: 50px;
            height: 50px;
            margin-left: $sidbar-menu-full-width;
            padding: {
                top: 15px;
                bottom: 15px;
            }
            float: left;
            font-size: 14px;
            line-height: 1.43;
            text-align: center;
            background-color: $sidebar-bg;
            color: $light-color;
            cursor: pointer;
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                margin-left: 0;
            }
            &:hover {
                color: $light-color;
                background-color: $sidebar-hover;
            }
            &.is-collapsed {
                margin-left: $sidbar-menu-width;
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    margin-left: 0;
                }
            }
            &.is-active {
                margin-right: $sidebar-width - $sidbar-menu-width;
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    margin-right: 0;
                }
            }
        }
        .sidebar-filter {
            .hint {
                position: relative;
                float: right;
                margin-top: 7px;
                &:hover {
                    .hint-popup {
                        height: auto;
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            .hint-popup {
                position: absolute;
                min-width: 165px;
                height: 0;
                top: 12px;
                right: 0;
                margin: 0;
                padding: {
                    top: 5px;
                    bottom: 5px;
                    left: 8px;
                    right: 8px;
                }
                opacity: 0;
                visibility: hidden;
                border-radius: 3px;
                background-color: $light-color;
                -webkit-box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
                        box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
                border: solid 1px rgba(0, 0, 0, 0.1);
                -webkit-transition: opacity 300ms ease-out;
                transition: opacity 300ms ease-out;
                list-style-type: none;
                z-index: 6;
            }
            .hint-popup-item {
                display: block;
                overflow: hidden;
                white-space: nowrap;
                .hint-box {
                    margin: {
                        top: 6px;
                        right: 7px;
                    }
                }
            }
            .hint-text {
                float: left;
                white-space: nowrap;
                color: #9b9b9b;
                font-size: 11px;
                font-style: italic;
                line-height: 1.82;
            }
            .hint-box {
                float: left;
                margin-left: 4px;
                width: 8px;
                height: 8px;
                &.type {
                    &0 {
                        background-color: #4c7db7;
                    }
                    &1 {
                        background-color: #a4d56f;
                    }
                    &2 {
                        background-color: #cb7fdb;
                    }
                    &3 {
                        background-color: #88674a;
                    }
                    &4 {
                        background-color: #f7c97e;
                    }
                    &5 {
                        background-color: #7ec9e0;
                    }
                }
            }
            .form-group {
                .control-label {
                    width: 100%;
                    margin-bottom: 5px;
                    .billing-period {
                        display: inline-block;
                    }
                    .area-unit {
                        display: inline-block;
                    }
                }
                .form-control,
                .filter-option {
                    font-size: 13px;
                    line-height: 1.54;
                }
                .filter-option {
                    max-width: 95%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .input-group {
                    .form-control {
                        width: 50%;
                        & + .form-control {
                            margin-left: -1px;
                        }
                    }
                    .fill {
                        width: 100%;
                    }
                }
            }
            .btn-primary {
                border-radius: 5px;
                background-color: #428bca;
                border: solid 1px #357ebd;
                padding: {
                    top: 4px;
                    bottom: 6px;
                }
                .dropdown-toggle-text {
                    font: {
                        size: 14px;
                        weight: 500;
                    }
                }
            }
            .search-button-wrapper {
                position: fixed;
                position: -webkit-sticky;
                position: sticky;
                bottom: 0;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
                border-top: 1px solid $border-color;
                background-color: $light-color;
                margin-left: -20px;
                margin-right: -20px;
                z-index: 2;
            }
        }
        .sidebar-dropdown {
            display: none;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            margin-top: 0;
            padding: 0;
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                display: block;
                position: relative;
                width: 100%;
                margin: 0;
            }
            .sidebar-header {
                position: relative;
                min-height: $top-menu-height;
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    display: none;
                }
            }
            .sidebar-body {
                max-height: 100%;
                padding-top: 10px;
                padding-bottom: 10px;
                overflow-y: auto;
                .current {
                    color: $info-color;
                }
                .accounts-list {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                            flex-direction: column;
                }
                .accounts-list-item {
                    padding: 0;
                    &.current {
                        padding: 10px 5px;
                    }
                }
            }
        }
        .modal {
            @media screen and (min-width: $breakpoint-pad-portrait + 1) and (max-width: $breakpoint-desktop-mid) {
                .sidebar {
                    &.is-active {
                        -webkit-transform: translateX(0);
                                transform: translateX(0);
                        & + .main-content {
                            -webkit-transform: translateX(0);
                                    transform: translateX(0);
                        }
                    }
                    & + .main-content {
                        -webkit-transform: translateX(0);
                                transform: translateX(0);
                    }
                }
            }
        }
    }
}
