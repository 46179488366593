@charset "UTF-8";
.contract-table {
    thead,
    tbody {
        tr {
            td {
                width: 150px;
            }
            th.status,
            td.status {
                padding-left: 14px !important;
                padding-right: 14px !important;
                width: 130px !important;
                &.center {
                    text-align: center;
                }
                .fa-check {
                    font-size: 12px;
                }
                .fa-circle {
                    font-size: 8px;
                }
            }

        }
    }
    tbody {
        tr {
            td.status.status-done {
                background-color: #FBFBFB;
            }
        }
    }
    .contract-link,
    .customer-link {
        color: $alter-color;
    }
}
