@charset "UTF-8";
.account-settings {
    .map {
        width:  100%;
        max-width: $breakpoint-pad-landscape + 1px;
        height: 400px;
        border-radius: 5px;
        border: 1px solid $border-color;
    }
    .form {
        max-width: $breakpoint-pad-landscape + 59px;
        padding: {
            top: 0;
            right: 30px;
            bottom: 20px;
            left: 28px;
        }
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            padding: {
                top: 0;
                right: 20px;
                bottom: 20px;
                left: 20px;
            }
            padding: 0 20px 20px 20px;
        }
        .property-card {
            margin-bottom: 30px;
            &:first-child {
                margin-top: 0;
            }
            .checkbox {
                margin: {
                    top: 4px;
                    bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .form-row {
                margin: {
                    top: 20px;
                    bottom: 15px;
                }
                .control-label {
                    &:first-child {
                        padding-top: 0;
                    }
                }
            }
        }
        clear: both;
    }
    .form-control-map {
        margin: {
            top: 10px;
            bottom: 10px;
        }
    }
    .checkbox-currency {
        width: 67px;
        padding: {
            right: 0;
        }
        margin: {
            top: 0;
            bottom: 15px;
            right: 3px;
            left: 0;
        }
    }
    .visit-card {
        padding-top: 18px;
        .panel-body {
            padding: {
                top: 0;
                right: 0;
                left: 0;
            }
            .col-half {
                padding: {
                    left: 20px;
                    right: 20px;
                }
            }
            .row:first-child {
                margin-bottom: 20px;
            }
        }
    }
    .watermark-wrapper {
        margin: 10px 0;
        .watermark-image {
            max-height: 160px;
            max-width: 160px;
        }
    }
    .logo-wrapper {
        margin: 10px 0;
        .logo-image {
            max-height: 160px;
            max-width: 160px;
        }
    }
}
