@charset "UTF-8";
$header-background-color: #303941;
$header-hover-color: #080808;
$body-background-color: #f2f2f2;
$primary-color: #428bca;
$success-color: #5CB85C;
$danger-color: #a94442;
$warning-color: #f5a623;
$secondary-color: #6C757D;
$lighter-secondary-color: #BFBFBF;
$info-color: #17a2b8;
$info-alter-color: #1b809e;
$dark-color: #343A40;
$navbar-link-color: #9D9D9D;
$body-color: #4A4A4A;
$light-color: #FFF;
$dark-color: #000;
$link-color: #568fe1;
$alter-color: #856AD9;
$muted-color: #9B9B9B;
$success-lt-color: #e8ffda;
$border-color: #DDD;
$unit-color: #598bc8;
$sidebar-bg: #3461ab;
$sidebar-hover: #315ca2;
$sidebar-active: #598bc8;
$muted-grey: #D8D8D8;
$disabled: #ccc;
$dark-text: #4A4A4A;
$td-hover: #fefcd4;
$sidebar-help: #fefcd4;
$black-color: #000;

$sidbar-menu-width: 50px;
$sidbar-menu-full-width: 168px;
$footer-heigth: 60px;
$header-margin-top: 5px;
$page-width: 1260px;
$sidebar-width: 310px;
$sidebar-width-full: $sidebar-width;
$sidebar-modal-width-full: $sidebar-width;
$menu-mobile-width: 160px;
$container-width: 1000px;
$breakpoint-desktop-middle: 1480px;
$breakpoint-desktop-small: 1400px;
$breakpoint-desktop-notebook: 1365px;
$breakpoint-1800: 1800px;
$breakpoint-notebook-big: 1650px;
$breakpoint-desktop-wide: 1920px;
$breakpoint-desktop-mid: 1239px;
$breakpoint-desktop: 1200px;
$breakpoint-tablet: 1024px;
$breakpoint-pad-landscape: 1023px;
$breakpoint-mobile-menu: 900px;
$breakpoint-pad-portrait: 767px;
$breakpoint-iphones: 560px;
$breakpoint-phones: 320px;
$notification-width: 300px;
$top-menu-height: 50px;
$toolbar-height: 60px;
$paddingSideItem: 12px;
$navbar-side-pad: 18px;
$user-size: 28px;
$bs-witdh: 3;
$card-radius: $bs-witdh;

$animation-layers-index: 1020;
$footer-index: 5;

$font-family: Arial, sans-serif;

$toggler-on-color: #5CB85C;
$toggler-off-color: #cd807e;

@mixin max-width-breakpoint($width) {
    @media screen and (max-width: $width) {
       @content;
    }
}

@mixin max-width-and-breakpoint($max-width, $min-width) {
    @media screen and (max-width:$max-width) and  (min-width: $min-width){
       @content;
    }
}

@mixin min-width-breakpoint($width) {
    @media screen and (min-width: $width + 1) {
       @content;
    }
}
@mixin clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
@mixin custom-bs-grid {
    .row {
        display: flex;
        flex-flow: row wrap;
        margin-left: -10px;
        margin-right: -10px;
    }
    [class*="col-"]{
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 -1px;
    }
}
@mixin content-padding {
    padding: {
        top: 15px;
        left: 28px;
        right: 28px;
        bottom: 15px;
    }
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        padding: {
            top: 0px;
            bottom: 20px;
            left: 20px;
            right: 20px;
        }
    }
}
@mixin dottedBorder($color: #8f8f8f, $orientation: horizontal, $position: top, $spacing: 5px, $size: 1px) {
  background-position: $position;
  @if $orientation == horizontal {
    background-image: linear-gradient(to right, $color $size/$spacing * 100%, rgba(255,255,255,0) 0%);
    background-size: $spacing $size;
    background-repeat: repeat-x;
  }
  @else {
    background-image: linear-gradient($color $size/$spacing * 100%, rgba(255,255,255,0) 0%);
    background-size: $size $spacing;
    background-repeat: repeat-y;
  }
  .ie9 & {
    border-#{$position}:1px dotted $color;
  }
}