@charset "UTF-8";
.property-subtask-synopsis {
    @include custom-bs-grid;
    @include content-padding;
    @include clearfix;
    margin-bottom: 50px;
    clear: both;
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        .panel-separator {
            display: none;
        }
        .synopsis-states {
            margin-bottom: 20px;
        }
    }
    .property-subtask-synopsis-condition {
        .card-item-price {
            float: right;
            text-align: right;
        }
        .card-item-row:last-child {
            border-bottom: 0;
        }
        .card-item-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 0;

            &::after{
                display: none;
            }

            .price-secondary{
                font-style: normal;
                font-size: 14px;
                color: $dark-text;
                text-align: right;
            }

            &.description{
                font-size: 14px;
                font-style: italic;
            }
        }
        .card-item-row__title{
            align-items: flex-start;
            padding-bottom: 10px;

            .card-item-price{
                float: none;
                order: 2;
                .price{
                    font-weight: bold;

                    .price-small{
                        font-weight: normal;
                        font-size: 16px;
                    }
                }
                .price-secondary{
                    font-size: 14px;
                    font-style: normal;
                    color: $dark-text;
                }
            }
            .card-item-title{
                float: none;
                order: 1;
                font-size: 16px !important;
                padding-top: 5px;
            }
        }

        .card-item-row__name{
            color: $dark-text;
            font-size: 14px;
            & + div{
                font-weight: bold;
                font-size: 14px;
                color: $dark-text;
                text-align: right;
            }
        }
    }
    .subtask-info .card-item-header {
        width: 100%;
    }
    .synopsis-title {
        margin: 0;
        font-size: 24px;
    }
    .synopsis-caption {
        margin-top: 0;
        margin-bottom: 20px;
    }
    .subtask-summary-title {
        font-size: 16px;
        line-height: 18px;
    }
    .subtask-summary-image {
        flex-shrink: 0;
        position: relative;
        width: 16.6666667%;
        height: 200px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: opacity 300ms linear;
        will-change: opacity;
        z-index: 1;
        &:before {
            content: '\f00e';
            opacity: 0;
            position: absolute;
            display: block;
            top: 50%;
            left: 50%;
            right: 50%;
            width: 22px;
            margin: {
                top: -12px;
                left: -12px;
                right: -11px;
            }
            font-size: 24px;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            color: rgba($light-color, 0.5);
            z-index: 3;
            cursor: pointer;
        }
        &:after {
            content: '';
            position: absolute;
            display: block;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.56);
            z-index: 2;
            cursor: pointer;
        }
        &:hover:before,
        &:hover:after {
            opacity: 1;
        }
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            width: 25%;
        }
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: 50%;
        }
    }
    .subtask-summary-photos {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        position: relative;
        transform: translate3d(0,0,0);
        width: 100%;
        height: 200px;
        margin: {
            bottom: 20px;
        }
        border-radius: 3px;
        background-color: $border-color;
        overflow: hidden;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: auto;
            margin: {
                top: 0;
                bottom: 0;
                left: -20px;
                right: -20px;
            }
        }
    }
    .subtask-summary-more {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 16.6666667%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
        color: $light-color;
        text-align: center;
        z-index: 2;
        transition: box-shadow 300ms ease-out;
        &:hover {
            box-shadow: inset 0px 0px 15px 2px $navbar-link-color;
        }
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            width: 25%;
        }
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: 50%;
        }
        a {
            display: block;
            position: absolute;
            width: 100%;
            top: 50%;
            margin-top: -7px;
            color: $body-background-color;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
        }
    }
    .subtask-summary-info {
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            order: 2;
        }
    }
    .subtask-summary-offers {
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            order: 1;
        }
    }
    .subtask-summary-properties {
        border-collapse:separate;
        width: 100%;
        margin-bottom: 30px;
        border-radius: 3px;
        background-color: $light-color;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px $border-color;
        tr {
            box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
        }
        td {
            padding: {
                left: 14px;
                right: 35px;
            }
            font-size: 14px;
            font-weight: 300;
            line-height: 2.5;
            color: $dark-color;
            &:first-child {
                font-weight: normal;
            }
        }
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            margin-bottom: 0;
            td {
                line-height: 1.4;
                padding: {
                    top: 4px;
                    bottom: 4px;
                }
            }
        }
    }
    .subtask-summary-properties-wrapper {
        overflow-x: auto;
        display: flex;
        justify-content: space-between;
        background-color: $light-color;
        padding: 12px 7px;
        border-radius: 4px;
        margin-bottom: 30px;
        @include max-width-and-breakpoint($breakpoint-desktop-middle, $breakpoint-desktop-mid) {
            flex-wrap: wrap;
        }
        @include max-width-breakpoint($breakpoint-pad-landscape) {
            flex-wrap: wrap;
        }

        table{
            width: calc(50% - 15px);
            margin-bottom: 0;
            border: none;
            box-shadow: none;
            @include max-width-and-breakpoint($breakpoint-desktop-middle, $breakpoint-desktop-mid) {
                width: 100%;
            }
            @include max-width-breakpoint($breakpoint-pad-landscape) {
                width: 100%;
            }

            tr{
                box-shadow: none;

                td{
                    font-size: 14px;
                    color: $dark-text;
                    line-height: 16px;
                    padding: 4px 0 !important;
                    //border-bottom: 1px solid $border-color;
                    @include max-width-breakpoint($breakpoint-pad-landscape) {
                        height: auto !important;
                    }

                    &:first-child{
                        text-align: left;
                    }
                    &:last-child{
                        text-align: right;
                        font-weight: bold;
                    }
                }

            }

        }
    }
    .map-wrapper {
        height: 400px;
        padding-bottom: 10px;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: auto;
            margin: {
                left: -20px;
                right: -20px;
            }
        }
    }

    .td__merge-line{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: end;
            -ms-flex-align: end;
                align-items: flex-end;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        span{
            font-weight: normal;
            padding-right: 10px;
            text-align: left;
            @include max-width-breakpoint($breakpoint-iphones){
                font-size: 12px;
            }
        }
        b{
            padding-left: 10px;
            @include max-width-breakpoint($breakpoint-iphones){
                font-size: 12px;
            }
        }
        .merge-line{
            -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                    flex-grow: 1;
            height: 1px;
            background-color: $border-color;
            margin-bottom: 2px;
        }
    }
    .card-item-row:first-child{
        border-bottom: none;
    }
    .card-slide {
        &[data-is-slide=true] {
            .card-slide__title {
                border-bottom: 1px solid $border-color;
            }
            .card-slide__button{
                &::before{
                    transform: rotate(180deg);
                }
            }
        }
        &__title {
            border-bottom: none;
        }
        &__content {
            display: none;
            padding-top: 10px;
        }
        &__button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 5px;
            &::before{
                content: '\f078';
                display: block;
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                color: $primary-color;
                font-size: 16px;
                transition: all .25s ease;
            }
            &:hover{
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }
}

#download-pdf-button{
    &:hover{
        background-color: $primary-color;
        color: #ffffff;
    }
}