@charset "UTF-8";
.property-subtask-conditions {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    padding: {
        top: 0;
        right: 0;
        bottom: 30px;
        left: 28px;
    }
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        padding: {
            left: 20px;
            right: 10px;
        }
    }
    @include max-width-breakpoint($breakpoint-iphones) {
        padding-right: 20px;
    }
    .subtask-item {
        width: 24%;
        margin: {
            right: 1%;
            bottom: 20px;
        }
        border-radius: 3px;
        background-color: $light-color;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
        border: solid 1px $border-color;
        @include max-width-breakpoint($breakpoint-desktop-wide) {
            width: 32.3333333%;
        }
        @include max-width-breakpoint($breakpoint-desktop) {
            width: 49%;
        }
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: 48%;
            margin-right: 2%;
        }
        @include max-width-breakpoint($breakpoint-iphones) {
            width: 92%;
            margin-right: 0;
        }

        .unit-title {
            color:#337ab7;
        }
    }
    .subtask-item-header {
        padding: {
            top: 10px;
            bottom: 10px;
            left: 14px;
            right: 14px;
        }
        background-color: #f5f5f5;
        border-bottom: solid 1px $border-color;
        vertical-align: top;
        line-height: 18px;
        a {
            color: #3e8acc;
        }
    }
    .subtask-item-title {
        font-size: 16px;
        .fa {
            font-size: 18px;
        }
    }
    .subtask-item-body {
        &.form {
            padding: {
                top: 10px;
                bottom: 0;
                left: 14px;
                right: 14px;
            }
            .row {
                margin-bottom: 15px;
            }
            .billing-period {
                display: inline-block;
            }
            .checkbox-list {
                padding-top: 5px;
                margin-bottom: 10px;
            }
            .checkbox-list.form-group {
                padding-top: 5px;
                margin-bottom: 0;
            }
            .checkbox-list .control-label {
                padding-bottom: 13px;
            }
            .checkbox-list .row {
                margin-bottom: 0;
            }
            .checkbox {
                margin-bottom: 13px;
            }
            .checkbox + .checkbox {
                padding-top: 5px;
            }
            .advanced-checkbox {
                margin-top: 0;
                padding: {
                    top: 0;
                    bottom: 0;
                }
            }
            .textarea {
                height: 100px;
                max-height: 100%;
            }
            .form-group {
                max-width: 100%;
            }
            .input-group {
                .form-control {
                    width: 50%;
                }
                .form-control + .form-control {
                    margin-left: -1px;
                }
            }
            .input-group-full {
                .form-control {
                    width: 100%;
                }
            }
            .map {
                &, &-image-wrapper {
                    margin-top: -10px;
                    border: 0;
                    border-radius: 0;
                }
            }
        }
    }
    .subtask-item-footer {
        @include clearfix;
        padding: {
            top: 10px;
            bottom: 11px;
            left: 14px;
            right: 14px;
        }
        background-color: #f5f5f5;
        border-top: solid 1px $border-color;
    }
}
