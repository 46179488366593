@charset "UTF-8";
.kanban-board {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    background-color: $body-background-color;
    &-wrapper {
        overflow-x: auto;
        cursor: -webkit-grab;
        cursor: grab;
        &.search-read-results {
            margin-left:  18px !important;
            margin-right: 18px !important;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                margin-left:  12px !important;
                margin-right: 12px !important;
            }
        }
        height: calc(100vh - 155px);
    }
    &-col {
        position:      relative;
        width:         269px;
        padding-left:  10px;
        padding-right: 10px;
        white-space: nowrap;
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        &:last-child {
            width:         285px;
            padding-right: 45px;
        }
    }
    &-items {
        padding-bottom: 120px;
    }
    &-step {
        font-size: 12px;
        border-bottom: 2px solid $dark-color;
        &-green {
            border-color: $success-color;
        }
        &-red {
            border-color: $danger-color;
        }
        &-blue {
            border-color: $primary-color;
        }
        &-cyan {
            border-color: $info-color;
        }
        &-orange {
            border-color: $warning-color;
        }
        &-grey {
            border-color: $secondary-color;
        }
    }
    &-item {
        margin-top:  15px;
        white-space: initial;
        &:hover {
            cursor: move;
        }
        &.ghost {
            opacity: 0.8;
        }
        &-placeholder {
            border-radius:    3px;
            background-color: $border-color;
            box-shadow:       0 2px 6px 0 rgba(0, 0, 0, 0.05);
            border:           solid 1px $border-color;
        }
    }
    
}

[data-fixed-title]{
    position: relative;
    z-index: 100;
}