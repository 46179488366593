.send-variants-form {
    .lot-link {
        color: $alter-color;
    }

    .variants-table {
        margin-bottom: 10px;
        .variant-link {
            padding-left: 15px;
            color: $link-color;
            a{
                color: $link-color;
            }
        }
    }

    .no-active {
        b {
            color: $primary-color;
            text-decoration: underline;
            &:hover{
                text-decoration: none;
                cursor: pointer;
            }
        }
    }

    .btn[disabled=disabled]{
        pointer-events: none;
    }

}