@charset "UTF-8";

.map-area-select-modal-container{
    padding-right: 0 !important;
    @include max-width-breakpoint($breakpoint-iphones) {
        .modal-title{
            margin-right: 0;
            padding-right: 15px;
            padding-left: 15px;
        }
    }
}

.map-area-select {
    .map-container {
        width:  100%;
        height: 100%;
    }
}