@charset "UTF-8";
.page-top-panel-checkbox {
    margin: {
        top: 10px;
        bottom: 0;
        left: 0;
        right: 0;
    }
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        max-width: 107px;
    }
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        .page-top-panel-checkbox-label {
            white-space: initial;
            line-height: 14px;
        }
    }
}
.account-agents {
    .agents-table {
        tr:hover td {
            cursor: pointer;
        }
        .name {
            width: 13%!important;
        }
        .department {
            width: 22%!important;
        }
        .email {
            width: 8%;
            white-space: nowrap;
        }
        .phones {
            width: 8%;
            white-space: nowrap;
        }
        .last-activity {
            width: 10%;
            white-space: nowrap;
        }
    }
}
