@charset "UTF-8";
 .campaign-form {
    .watermark-wrapper {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 15px;
        img {
            max-width: 100%;
        }
    }
}