@charset "UTF-8";
.map-with-area-selection-buttons-container {
    width: 25%;
    outline: none;
    &.hidden {
        display: block !important;
    }
}
.map-with-area-selection-address-input {
    margin: {
        left: 10px;
        top: 9px;
    }
    width: 70%;
    height: 33px;
    min-width: 200px;
    max-width: 900px;
}
.map-with-area-selection-button {
    display:     block;
    margin: {
        left: 9px;
        top: 9px;
    }
    float: left;
}
.map-with-area-selection-button-inner {
    height:           32px;
    width:            32px;
    background-color: #fff;
    color:            #777;
    border:           2px solid #fff;
    border-radius:    3px;
    box-shadow:       0 1px 6px rgba(0,0,0,.3);
    cursor:           pointer;
    display:          table-cell;
    text-align:       center;
    vertical-align:   middle;
    font-size:        16px;
    &:hover {
        color: #000;
    }
}
