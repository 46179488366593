@charset "UTF-8";
.complete-simple-subtask {
    .files-list {
        .file-name {
            padding-top: 10px;
            display:     inline-block;
        }
        .file-item {
            height: 30px;
        }
        .delete-file-button {
            display: none;
            min-height: 30px;
        }
        .file-item:hover .delete-file-button {
            display: inline-block;
        }
    }
}