@charset "UTF-8";
.page-top-panel-checkbox {
    margin: {
        top: 10px;
        bottom: 0;
        left: 0;
        right: 0;
    }
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        max-width: 107px;
    }
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        .page-top-panel-checkbox-label {
            white-space: initial;
            line-height: 14px;
        }
    }
}
.account-document-templates {
    .document-templates-table {
        th,
        td {
            white-space: nowrap;
        }
        .title {
            width: 8%;
        }
    }
}
