@charset "UTF-8";
.advert {
    .advert-anchors {
        margin: 0 0 10px 0;
        padding: 0;
        list-style: none;
        @include clearfix;
    }
    .advert-anchors li {
        float: left;
        margin-right: 20px;
        line-height: 1.43;
    }
    .form-control-map {
        margin-bottom: 0;
    }
    .disabled .period-title {
        color: #9b9b9b;
    }
    .form .property-card {
        margin-bottom: 25px;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            margin-top: 5px;
        }
    }
    .advanced-checkbox-wrapper {
        .advanced-checkbox {
            label {
                white-space: initial;
            }
        }
    }
    .panel-group {
        padding-top: 20px;
        .row {
            margin-bottom: 25px;
        }
        .panel-body {
            padding-bottom: 0;
        }
        .panel-title > a {
            color: inherit
        }
        .panel {
            margin-bottom: 20px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
        }
    }
    .help-link {
        color: #4990E2;
    }
    .alert-controls {
        float: right;
        @include max-width-breakpoint($breakpoint-iphones) {
            display: block;
            float: none;
            clear: both;
            margin-left: 0;
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
    .name-part-textbox {
        width: 33%;
    }

    .description-container {
        padding: 8px;
        background-color: $body-background-color;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #333;
        p {
            margin: 0 0 5px;
        }
    }
    .alert-info{
        .alert-controls{
            .alert-link {
                color: #31708f;
            }
            color: #31708f;
        }
    }
    .alert-danger{
        .alert-controls{
            .alert-link {
                color: $danger-color;
            }
            color: $danger-color;
        }
    }
    .alert-warning{
        .alert-controls{
            .alert-link {
                color: #8a6d3b;
            }
            color: #8a6d3b;
        }
    }
    .alert-warning,
    .alert-danger,
    .alert-info {
        .alert-link{
            font-weight: normal !important;
        }
        h4{
            a{
                font-weight: normal;
            }
            .fl-left{
                & + .tooltip{
                    left: 39px !important;
                    .tooltip-inner{
                        text-align: left;
                    }
                    .tooltip-arrow{
                        left: 14px !important;
                    }
                }
            }
        }
        .fl-left{
            margin-right: 10px;
            margin-top: -1px;
            .tooltip{
                left: 36px !important;
                .tooltip-inner{
                    text-align: left;
                }
                .tooltip-arrow{
                    left: 14px !important;
                }
            }
        }
    } 
    [data-adver-type="Room"],
    [data-adver-type="Apartment"] {
        .br {
            width: 100%;
        }
    }
}

.contract-variants-list-lot {
    display: flex;
    align-items: center;

    a { 
        display: block;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            order: 1;
        }
    }

    img {
        display: none;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            display: block;
            width: 30px;
            height: 30px;
            margin-right: 15px;
            border: none;
            border-radius: 0;
            padding: 0;   
        }
            
    }
    &.active--js{
        img{
            border: 1px solid $sidebar-bg
        }
    }
}

.gallery__hidden{
    display: none !important;
}