@charset "UTF-8";
.top-menu {
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: none;
            flex: none;
    height: $top-menu-height;
    margin-bottom: 0px;
    background-color: $header-background-color;
    border: none;
    .container {
        height: 100%;
        @include max-width-breakpoint($breakpoint-mobile-menu) {
            width: 100%;
        }
    }
    .top-navigation-settings-mobile {
        display: none;
        margin: 0;
        padding: 0;
        list-style-type: none;
        .fa, .glyphicon {
            display: none;
        }
        .dropdown-toggle {
            display: none;
        }
        .dropdown-menu {
            position: relative;
            display: block;
            float: none;
            padding: 0;
            margin: 0;
            border: 0;
            background-color: $header-background-color;
            color: $navbar-link-color;
            .divider {
                margin: 0;
                padding: 0;
                height: 1px;
                opacity: 0.3;
                background-color: $body-background-color;
            }
            li > a {
                display: block;
                padding: {
                    top: 16px;
                    bottom: 14px;
                    left: 15px;
                    right: 15px;
                }
                color: $navbar-link-color;
                &:hover {
                    background-color: $header-hover-color;
                    color: $light-color;
                }
            }
        }
        li {
            display: block;
            list-style-type: none;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font: {
                size: 14px;
                weight: 500;
            }
            text-align: left;
            a {
                display: block;
                padding: {
                    top: 16px;
                    bottom: 14px;
                    left: 15px;
                    right: 15px;
                }
                color: $navbar-link-color;
                &:hover {
                    background-color: $header-hover-color;
                    color: $light-color;
                }
            }
            &.account-name-wrapper {
                margin-top: 4px;
                padding: {
                    top: 10px;
                    left: 15px;
                    right: 15px;
                    bottom: 4px;
                }
                font-size: 11px;
                font-weight: bold;
                line-height: 1.27;
                color: $navbar-link-color;
            }
            &.account-current {
                padding: 10px 15px;
                color: $info-color;
                .fa {
                    display: inline-block;
                }
            }
            &.active {
                background-color: $header-hover-color;
                a {
                    background-color: $header-hover-color;
                    color: $light-color;
                }
            }
            .account-name {
                padding: 10px 20px;
                font: {
                    size: 16px;
                    weight: bold;
                }
                line-height: 1.17;
                color: $info-alter-color;
            }
        }
    }
    .brand {
        max-width: 100%;
        -o-object-fit: contain;
           object-fit: contain;
        cursor: pointer;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            max-width: 30px;
            max-height: 30px;
        }
    }
    .brand-desktop {
        @include max-width-breakpoint($breakpoint-mobile-menu) {
            display: none;
        }
    }
    .navbar-nav {
        margin: 0;
        .dropdown-menu {
            .divider:first-child {
                display: none;
                @include max-width-breakpoint($breakpoint-mobile-menu) {
                    display: block;
                }
            }
        }
        li {
            &.account-name {
                padding: 3px 20px;
                color: $navbar-link-color;
            }
            &.account-name-wrapper {
                padding: {
                    top: 6px;
                    left: 20px;
                    right: 20px;
                    bottom: 4px;
                }
                font: {
                    size: 11px;
                    weight: bold;
                }
                line-height: 1.27;
                color: $navbar-link-color;
            }
            &.account-current {
                padding: 3px 20px;
                color: $info-color;
            }
        }
        @include max-width-breakpoint($breakpoint-mobile-menu) {
            .divider {
                padding: 0;
                height: 1px;
                opacity: 0.3;
                background-color: #f2f2f2;
            }
        }
    }
    .navbar-toggle {
        float: left;
        width: 50px;
        height: $top-menu-height;
        margin: 0;
        padding: 15px;
        text-align: center;
        border: none;
        color: $navbar-link-color;
        &.collapsed {
            background-color: $header-background-color;
        }
        &:hover,
        &:focus {
            background-color: $header-hover-color;
            border-radius: 0;
        }
    }
    .navbar-right {
        margin-right: 0;
        float: right;
        @include max-width-breakpoint($breakpoint-mobile-menu) {
            .user-menu {
                display: none;
            }
        }
    }
    .btn-group {
        height: $top-menu-height;
        float: left;
        cursor: pointer;
        &.open {
            background-color: $header-hover-color;
            & > .dropdown-toggle {
                -webkit-box-shadow: none;
                        box-shadow: none;
                font-size: 14px;
            }
        }
    }
    .top-nav-btn {
        height: 50px;
        line-height: 1;
        text-align: center;
        border: none;
        &:focus {
            background-color: $header-hover-color;
            outline: none;
        }
        &-brand {
            display: block;
            float: left;
            width: $sidbar-menu-width;
            height: 50px;
            padding-left: 6px;
            padding-right: 6px;
            padding-top: 6px;
            background-color: $header-background-color;
            &:hover {
                background-color: transparent;
            }
        }
    }
    .search-variants-button {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        min-width: 50px;
        padding-left: 12px;
        padding-right: 12px;
        -ms-flex-align: center;
        -webkit-box-align: center;
        align-items: center;
        color: $light-color;
        font-size: 18px;
        &.active {
            color: #9d9d9d;
        }
    }
    a.search-variants-button:hover {
        background-color: $dark-color;
        cursor: pointer;
    }
    .add-menu {
        float: left;
        margin-top: 8px; //topheight - btn height, all / 2
        margin-right: 8px;
    }
    .user-menu {
        .glyphicon {
            vertical-align: middle;
        }
        .dropdown {
            &.open {
                & > .dropdown-toggle {
                    .glyphicon,
                    .fa {
                        color: $body-background-color;
                    }
                    color: $body-background-color;
                }
            }
        }
        .dropdown-toggle {
            color: $navbar-link-color;
            vertical-align: middle;
            &:hover {
                .glyphicon,
                .fa {
                    color: $body-background-color;
                }
                color: $body-background-color;
            }
        }
    }
    .dropdown-menu {
        margin-top: 0;
        padding-bottom: 5px;
        white-space: nowrap;
        max-height: calc(100vh - 100%);
        overflow-y: auto;
        @include max-width-breakpoint($breakpoint-mobile-menu) {
            position: absolute;
            float: left;
            background-color: $light-color;
            border: 1px solid rgba(0, 0, 0, .15);
            -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            right: 0;
            left: auto;
            & > li > a:focus,
            & > li > a:hover {
                color: $body-color;
            }
        }
        a {
            &:hover {
                background-color: #428BCA;
                color: $body-background-color;
                .glyphicon,
                .fa {
                    color: $body-background-color;
                }
            }
        }
        li.divider {
            margin: {
                top: 9px;
                bottom: 9px;
                left: 0;
                right: 0;
            }
        }
        li.active {
            a {
                color: $light-color;
            }
        }
        li > a {
            padding: {
                top: 6px;
                bottom: 4px;
            }
            color: #333;
            .glyphicon,
            .fa {
                color: #333;
            }
        }
    }
    .menu-toggle {
        display: none;
        width: $sidbar-menu-width;
        height: $top-menu-height;
        background-color: $sidebar-active;
        font-size: 21px;
        color: $light-color;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            float: left;
        }
        &.has-brand {
            background-color: $header-background-color;
        }
    }
    &.focus-input {
        .menu-toggle{
            display: none;
        }
        .sidebar-toggler {
            @include max-width-breakpoint($breakpoint-desktop-mid){
                display: none !important;
            }
        }
        .search-variants-button{
            display: none !important;
        }
        
        .quick-search{
            transition: none !important;
        }
        .quick-search-input-group{
            .form-control{
                padding-left: 30px !important;
            }
        }
    }
    &.no-empty-input{
        .search-variants-button{
            display: none !important;
        }
        .quick-search-input-group{
            .form-control{
                padding-left: 30px !important;
            }
        }
        .menu-toggle{
            display: none;
        }
        .sidebar-toggler {
            @include max-width-breakpoint($breakpoint-desktop-mid){
                display: none !important;
            }
        }
        .search-variants-button{
            display: none !important;
        }
        
    }
}