@charset "UTF-8";
.requests {
    @include custom-bs-grid;
    @include content-padding;
    @include clearfix;
    clear: both;
    .recrm-table.request-table {
        td {
            width:  auto;
        }
        th {
            color: $dark-color;
        }
        .center {
            text-align: center;
        }
        .title {
            white-space: nowrap;
            width:       20px;
        }
        .customer {
            min-width:  180px;
            width:      180px;
        }
        .assignees {
            min-width: 180px;
            width: 180px;
        }
        .divider {
            width: 0 !important;
            padding: 0 !important;
            margin: 0 !important;
            border-left: 1px solid $border-color;
        }
        .status {
            width: 100px;
            text-align: center;
        }
        .last {
            width: auto;
        }
    }
    .request-toggler-list {
        padding-right: 18px;
    }
    &.requests-table {
        padding: 0;
        margin: 0;
        .row {
            display: block;
            margin: 0;
        }
        .search-sort {
            margin-bottom: 0;
        }
        .content-title-wrapper {
            margin-bottom: 0;
        }
    }
}

.requests-sidebar {
    .control-label-data {
        .control-label-span {
            margin-left: 5px;
            color: $link-color;
            border-bottom: 1px dotted $link-color;
            cursor: pointer;
        }
    }
}
.kanban-board-col {
    &.kanban-board-hover {
        .btn-archive {
            background-color: #fff;
        }
        .kanban-board-item-placeholder{
            visibility: hidden;
            display: none !important;
        }
    }
    
}
.btn-archive {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px dotted #428bca;
    margin: 20px 0;
    &:hover {
        cursor: pointer;
        background-color: #fff; 
    }
    &::before {
        content: '';
        width: 100%;
        padding-top: 100%;
        display: block;
    }
    &__content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .icon {
        font-size: 50px;
    }
}