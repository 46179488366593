@charset "UTF-8";
.reports {
    .manual-message-wrapper {
        margin-left:  28px;
        margin-right: 28px;
    }
    .content-title {
        line-height: 2;
    }
    .customer-table.table {
        margin-bottom: 130px !important;
    }
    .reports-table.table {
        margin-bottom: 130px !important;
        td {
            width: auto;
        }
        th {
            color: $dark-color;
        }
        th.text-center {
            text-align: center;
        }
        th.text-right {
            text-align: right;
        }
        .cell-type {
            width: 200px;
            white-space: nowrap;
        }
        .cell-checkbox {
            width: 19px;
            padding-right: 0 !important;
            input[type="radio"], input[type="checkbox"] {
                margin-top: 0;
            }
            .radio, .checkbox {
                margin-top: 0;
                padding-top: 0;
                label > span {
                    padding-left: 0;
                }
                label > span::after,
                label > span::before {
                    top: -7px;
                }
            }
        }
        .cell-author{
            width: 90px;
            white-space: nowrap;
        }
        .cell-created {
            width: 80px;
            white-space: nowrap;
        }
        .cell-status {
            width: 70px;
        }
        .report-params {
            max-width: 963px;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                max-width: 209px;
            }
            @include max-width-breakpoint($breakpoint-pad-landscape) {
                max-width: 339px;
            }
        }
        .errors-list {
            padding-left: 15px;
            margin-bottom: 0px;
        }
    }
    .result-item-pager {
        margin-top:    40px;
        margin-bottom: 40px;
    }
}
