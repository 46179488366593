@charset "UTF-8";
.request-create {
    .customer-card {
        padding-bottom: 0px;
        margin: {
            top: 0;
            bottom: 0;
        }
        .panel-body {
            padding-top: 12px;
        }
        input {
            width: 100%;
        }
    }
    .modal-body {
        .alert-danger {
            margin-bottom: 15px;
        }
        .card-item-header {
            width: 100%;
        }
    }
    .form-control + .card-item,
    .dropdown-menu + .card-item {
        margin-top: 15px;
    }
    .customer-forbidden {
        padding: 12px;
        margin-bottom: 0px;
        border: 1px solid #faebcc;
        border-radius: 4px;
        font-size: 13px;
        color: #8a6d3b;
        background-color: #fcf8e3;
    }
    .form-control.description {
        min-height: 100px;
    }
    .card-item {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;
        transition: none;
        & + .form-control {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
            &:focus {
                border-top: 1px solid #66afe9;
            }
        }
    }
}