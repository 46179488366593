.advert-campaigns {
    .recrm-table.advert-campaigns-table {
        .title {
            width: 24.04%;
        }
        .agent,
        .type {
            width: 14.11%;
        }
        .last-share {
            width: 13.15%;
        }
        .status {
            width: 50px !important;
            text-align: center;
        }
        .status-item {
            display: inline-block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
        }
    }
}

.campaign-form{
    .checkbox {
        label{
            white-space: normal;
        }
    }
}