@charset "UTF-8";
.contact-create {
    .is-personal-checkbox-container {
        padding-left: 0;
    }
    .phone-additional-input {
        padding-right: 0;
    }
    .phone-help-block {
        margin: {
            left: 0;
            bottom: 0;
        }
    }
}
