@charset "UTF-8";
.user {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    width: $sidbar-menu-width;
    height: $sidbar-menu-width;
    @include max-width-breakpoint($breakpoint-desktop-mid) {
        width: auto;
    }
    .user-avatar {
        position: absolute;
        width: $user-size;
        height: $user-size;
        left: 11px;
        border-radius: 50%;
        overflow: hidden;
        background-color: $sidebar-active;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        &-text {
            color: $light-color;
            font-size: 16px;
            line-height: $user-size;
            text-transform: uppercase;
            text-align: center;
        }
    }
    .user-name {
        display: none;
        margin-left: $user-size;
        padding-left: 16px;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            display: block;
        }
    }
}