@charset "UTF-8";
.invite {
    .resend-button {
        margin-top: 10px;
    }
    .revoke-button {
        margin-top: 10px;
    }
    .form {
        .limit-reached-message {
            margin-top: 10px;
        }
        .property-card {
            margin-bottom: 30px;
            &:first-child {
                margin-top: 0;
            }
            .checkbox {
                margin: {
                    top: 4px;
                    bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .form-row {
                margin: {
                    top: 20px;
                    bottom: 15px;
                }
                .control-label {
                    &:first-child {
                        padding-top: 0;
                    }
                }
            }
        }
        .permission-table {
            padding-bottom: 5px;
            .property-table-container {
                margin-top: 0;
                .property-table {
                    margin: {
                        top: 0;
                        bottom: 0;
                    }
                }
            }
        }
        .property-table {
            th,
            td {
                padding: {
                    left: 20px;
                    right: 20px;
                }
            }
        }
    }
    .invite-avatar-wrapper {
        margin-top: 10px;
        .invite-avatar-image {
            width: 160px;
            height: 160px;
            border-radius: 4px;
            object-fit: cover;
        }
    }
    .invite-avatar-wrapper {
        .fileinput-button {
            margin-right: 10px;
        }
    }
}