@charset "UTF-8";
$margin-items: 2.955629%;

.property-main-link {
    margin-bottom: 12px;
}
.property-pictures {
    .pictures-container {
        @include clearfix;
        margin-left: -$margin-items;
        padding: {
            right: 0;
            bottom: 20px;
            left: 28px;
        }
    }
    .sortable-container {
        @include clearfix;
        padding-left: 28px;
    }
    .picture-item {
        position: relative;
        display: block;
        float: left;
        width: 210px;
        height: 210px;
        margin: {
            bottom: 20px;
            left: $margin-items;
        }
        zoom: 1;
        &:hover {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
        }
        .advanced-checkbox {
            position: absolute;
            top: 0px;
            right: 0px;
            margin: 0px;
            padding: 0px;
        }
        .picture-item-control {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
            background-color: $light-color;
            border-radius: 3px;
        }
        .picture-item-img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .picture-item-upload {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        outline: none;
        top: 0px;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        border: 1px dotted $primary-color;
        cursor: pointer;
        &:hover {
            background-color: $light-color;
        }
        &-text {
            text-decoration: underline;
            color: $link-color;
        }
        input {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
            opacity: 0;
            -ms-filter: 'alpha(opacity=0)';
            font-size: 200px;
            direction: ltr;
            cursor: pointer;
        }
        .fa {
            display: block;
            margin-bottom: 20px;
            font-size: 48px;
            color: $link-color;
        }
    }
    .progress-container {
        padding-right: 25px;
    }
}
.fancybox-thumbs-x {
    top: auto;
    width: auto;
    bottom: 0;
    left: 0;
    right : 0;
    height: 95px;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
}

.fancybox-show-thumbs .fancybox-inner {
    right: 0;
    bottom: 95px;
}
.fancybox-thumbs > ul > li:before {
    border-color: $primary-color;
}

.property-pictures-toolbar {
    .fileinput-button {
        margin-right: 0px;
    }
}