@charset "UTF-8";
.base-master-body {
    &.input-input {
        .quick-search {
            .quick-search-input-group{
                &::after,
                &::before {
                    opacity: 0;
                }
            }
        }
    }
    .quick-search {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-right: 28px;
        overflow: hidden;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            margin-left: 0;
            padding-right: 8px;

        }
        .quick-search-result-count {
            margin-top: 10px;
            font-size: 18px;
            line-height: 1.11;
            color: $muted-grey;
        }
        .quick-search-result-list {
            padding-top: 20px;
            @include custom-bs-grid;
            .card-item-header {
                width: 100%;
            }
        }
        .quick-search-input-group {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            width: 100%;
            height: 50px;
            position: relative;
            &::before{
                content: '\f002';
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
                color: $muted-grey;
                font-size: 20px;
                z-index: 1;
            }
            .form-control {
                border: 0;
                background-color: transparent;
                color: $muted-grey;
                padding-left: 50px !important;
                position: relative;
                z-index: 2;
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    padding-left: 0;
                }
            }
            .input-group-addon-icon {
                padding-left: 0;
                @include max-width-breakpoint($breakpoint-desktop-mid){
                    width: 60px;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    text-align: center;
                    font-size: 20px;
                }
            }
        }
        .quick-search-close-button {
            display: none;
            left: 0;
            right: auto;
            cursor: pointer;
            width: 60px;
            text-align: center;
            justify-content: center;
            z-index: 3;
        }
        .quick-search-result-container {
            display: none;
            padding-left: 12px;
            padding-right: 12px;
            border-top: 0;
            border-bottom-left-radius: $bs-witdh + px;
            border-bottom-right-radius: $bs-witdh + px;
            background-color: $header-background-color;
            &.is-open {
                display: block;
                padding-bottom: 8px;
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    position: fixed;
                    left: 0;
                    right: 0;
                    top: $top-menu-height;
                    height: 100%;
                    margin-top: 0;
                    padding-bottom: $top-menu-height;
                    overflow-y: auto;
                    z-index: $animation-layers-index;
                    &:after {
                        content: "";
                        display: block;
                        height: $top-menu-height;
                        width: 100%;
                    }
                }
                @include min-width-breakpoint($breakpoint-desktop-mid){
                    max-height: calc(100vh - 50px);
                    overflow-y: auto;
                }
            }
        }
    }
    &.sidebar-hidden {
        .quick-search {
            margin-left: 0;
            -webkit-transition: margin 200ms linear;
            transition: margin 200ms linear;
        }
    }
}
