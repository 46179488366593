@charset "UTF-8";
.customers {
    @include custom-bs-grid;
    @include content-padding;
    @include clearfix;
    clear: both;
}

.customers-sidebar {
    .control-label-data {
        .control-label-span {
            margin-left: 5px;
            color: $link-color;
            border-bottom: 1px dotted $link-color;
            cursor: pointer;
        }
    }
}