@charset "UTF-8";
.request-menu {
    .dropdown{
        margin-top: 10px;
    }
    .left-icon {
        width: 17px;
    }
    .right-icon {
        float: right;
        margin-top: 3px;
    }
}