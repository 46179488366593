@charset "UTF-8";
.contract-tasks {
    .tasks-container {
        padding: {
            top: 0;
            bottom: 0;
            left: 28px;
            right: 28px;
        };
        .divider {
            height: 28px;
        }
    }
    .no-tasks-message {
        margin: {
            top: 0 !important;
            bottom: 0 !important;
            left: 28px !important;
            right: 28px !important;
        };
    }
}