@charset "UTF-8";
.house {
    .form {
        .textarea {
            margin-top: 15px;
        }
    }
    .built-in-year-container {
        width: 60px;
        float: left;
    }
    .built-in-quarter-container {
        width: 117px;
        margin-left: 15px;
        float: left;
    }
    .map {
        height: 400px;
        margin: {
            left: 0;
            right: 0;
            bottom: 10px;
        }
    }
    .address-is-ok-button {
        margin-left: 10px;
    }
    .update-address-button {
        margin-left: 10px;
    }
    .do-not-update-address-button {
        margin-left: 5px;
    }
    .property-table-name {
        width: 200px;
    }
    .property-table-checkbox {
        width: 100px;
        text-align: center;
    }
    .property-table-checkbox .checkbox {
        margin-top: -3px;
        padding-top: 0;
    }
    .property-table-container {
        margin-top: 22px;
        .property-table {
            margin-top: 0;
        }
    }
}
