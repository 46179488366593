.request-variants {
    .nothing-found-message-wrapper {
        padding-top: 15px;
        padding-left: 28px;
        padding-right: 28px;
        padding-bottom: 15px;
        clear: both;
    }
    .content-title {
        line-height: 2;
    }
    .copy-textarea {
        width: 1px;
        height: 1px;
        position: absolute;
        top:-9999px;
    }
    .variants-table.table {
        margin-top: 0;
        td {
            width: auto;
        }
        th {
            color: $dark-color;
        }
        th.text-center {
            text-align: center;
        }
        th.text-left{
            text-align: left;
        }
        .th-images{
            padding: 0 !important;
        }
        .td-images{
            padding-right: 0 !important;
            font-size: 24px;
            color: $disabled;
            text-align: center;
            width: 36px;
            [data-popup-img]{
                width: 36px;
                height: 36px;
                display: block !important;
                margin-left: auto;
                margin-right: auto;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .cell-checkbox {
            width: 19px;
            padding-right: 0 !important;
            input[type="radio"], input[type="checkbox"] {
                margin-top: 0;
            }
            .radio, .checkbox {
                margin-top: 0;
                padding-top: 0;
                label > span {
                    padding-left: 0;
                }
                label > span::after,
                label > span::before {
                    top: -7px;
                }
            }
        }
        .cell-lot {
        }
        .cell-sent {
            white-space: nowrap;
        }
        .cell-received {
            white-space: nowrap;
        }
        .cell-viewed {
            white-space: nowrap;
        }
        .cell-link {
            white-space: nowrap;
        }
        .cell-status {
            white-space: nowrap;
        }
        .bootstrap-select {
            z-index: 2;
        }
    }
    .result-item-pager {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .contract-variants-list {
        &-lot {
            &:hover {
                .contract-variants-list-image {

                }
            }
        }
        &-image {
            @include min-width-breakpoint($breakpoint-desktop-mid){
                position: absolute;
                display: none;
                top: 24px;
                left: 0;
                transition: visibility 300ms ease-in;
                will-change: opacity;
                z-index: 2;
            }
        }
    }
    .auto-matching-toggler {
        margin-top: 15px;
        margin-right: 10px;
    }
    [data-variant-row].selected{
        td{
            background-color: #e9ffdb;
        }
    }
}

.img-popup__thumbs{
    &-item {
        position: absolute;
        display: none;
        z-index: 10000;
        transform: translateY(-105%);
        max-width: 210px;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            transform: translateY(0) translateX(43px);
        }
        &.active--js {
            display: block;
        }
    }
}

.close-popup-img{

    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $sidebar-bg;
    display: none;
    &::before{
        content: '';
        width: 15px;
        height: 2px;
        background-color: #ffffff;
        position: absolute;
        left: 3px;
        top: 9px;
        transform: rotate(45deg);
    }
    &::after{
        content: '';
        width: 15px;
        height: 2px;
        background-color: #ffffff;
        position: absolute;
        left: 3px;
        top: 9px;
        transform: rotate(-45deg);
    }

    @include max-width-breakpoint($breakpoint-desktop-mid){
        display: block;
    }
}