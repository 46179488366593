@charset "UTF-8";
.customer {
    @include custom-bs-grid;
    .customer-info {
        margin-top: 10px;
        &.form-content{
            overflow: visible !important;
        }
    }
    .customer-info-static {
        margin-top: 50px;
        @include max-width-breakpoint($breakpoint-iphones) {
            margin-top: 18px;
        }
    }

}
#create-customer-title-container,
#create-customer-phone-container {
    .typeahead {
        &::before {
            content: attr(data-head);
            font-size: 12px;
            line-height: 14px;
            color: #4A4A4A;
            width: 100%;
            display: block;
            text-align: left;
            padding: 5px 10px 10px 10px;
        }
    }
}