@charset "UTF-8";
.password-recovery,
.sign-in {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        height: auto;
    }
    a{
        color: $link-color;
    }
    .password-recovery__content,
    .sign-in__content{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        width: 50%;
        position: relative;
        padding-right: 15px;
        padding-left: 15px;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: 100%;
        }
        .logo{
            margin-left: 20px;
            margin-top: 35px;
            background-color: $header-background-color; 
            width: 56px;
            height: 56px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            -webkit-box-pack: center;
                -ms-flex-pack: center;
                    justify-content: center;
            @include max-width-breakpoint($breakpoint-tablet) {
                margin-left: 0;
                margin-top: 15px;
            }
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                margin-bottom: 70px;
            }
        }
        
    }
    .password-recovery__illustration,
    .sign-in__illustration{
        background-color: $link-color;
        width: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding-left: 15px;
        padding-right: 15px;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            display: none;
        }
    }
    .sign-in-form {
        max-width: 330px;
        width: 100%;
        padding: 15px;
        margin: auto;
    }
    .password-recovery-form{
        position: inherit;
        left: inherit;
        top: inherit;
        margin: auto !important;
        max-width: 330px;
        width: 100%;
        padding: 15px;
        .buttons{
            margin-top: 0 !important;
            text-align: center;
        }
    }
    .password-recovery-textbox,
    .sign-in-textbox {
        position: relative;
        height: auto;
        padding: 10px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        font-size: 16px;
    }

    .form-holder{
        margin-bottom: 25px !important;
    }

    .joint-textboxes-upper:focus, .joint-textboxes-lower:focus {
        z-index: 2;
    }

    .sign-in-alert-danger {
        margin-bottom: 10px;
    }
    .ortitle {
        margin-top: 10px;
        color:#cccccc;
    }
    .row-hr{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    hr {
        border-top-color: #cccccc;
    }
    .buttons {
        margin-top: 90px;
        @include max-width-breakpoint($breakpoint-desktop-notebook) {
            margin-top: 50px;
        }
    }

    .filedset-group{
        position: relative;
        label{
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;  
            display: -webkit-inline-box;  
            display: -ms-inline-flexbox;  
            display: inline-flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            margin-bottom: 10px;
            color: $black-color;
            width: 100%;
        }
    }

    .toggle-password{
        position: absolute;
        right: 10px;
        top: 40px;
        font-size: 18px;
        color: #cccccc;
        -webkit-transition: all .25s ease;
        -o-transition: all .25s ease;
        transition: all .25s ease;
        z-index: 20;
        &:hover{
            cursor: pointer;
            color: $black-color;
        }
    }

    .btn-outline-primary {
        color: $primary-color;
        border-color: $primary-color;
        width: 100%;
        font-size: 14px;
    }
      
    .btn-outline-primary:hover {
        color: #fff;
        background-color: $primary-color;
        border-color: $primary-color;
    }
    
    .btn-outline-primary:focus, .btn-outline-primary.focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }
    
    .btn-outline-primary.disabled, .btn-outline-primary:disabled {
        color: $primary-color;
        background-color: transparent;
    }
    
    .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
    .show > .btn-outline-primary.dropdown-toggle {
        color: #fff;
        background-color: $primary-color;
        border-color: $primary-color;
    }
    
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
        -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
    }
    .password-recovery__illustration-content,
    .sign-in__illustration-content{
        margin: auto;
        p{
            text-align: center;
            font-weight: bold;
            font-size: 28px;
            line-height: 33px;
            color: #ffffff;
            margin-bottom: 0;
            @include max-width-breakpoint($breakpoint-desktop-notebook) {
                font-size: 20px;
                line-height: 30px;
            }
        }
    }
    .password-recovery-content__img,
    .illustration-content__img{
        svg{
            width: 650px;
            @include max-width-breakpoint($breakpoint-notebook-big) {
                width: 500px;
            }
            
            @include max-width-breakpoint($breakpoint-desktop) {
                width: 300px;
            }
        }
        #cloud-small{
            -webkit-animation: cloud-small 10s ease-in-out infinite;
                    animation: cloud-small 10s ease-in-out infinite;
        }
        #book{
            -webkit-animation: book 10s ease-in-out infinite;
                    animation: book 10s ease-in-out infinite;
        }
        #calendare{
            -webkit-animation: calendare 10s ease-in-out infinite;
                    animation: calendare 10s ease-in-out infinite;
        }
        #notebook{
            -webkit-animation: notebook 10s ease-in-out infinite;
                    animation: notebook 10s ease-in-out infinite;
        }
        #cup{
            -webkit-animation: cup 10s ease-in-out infinite;
                    animation: cup 10s ease-in-out infinite;
            .cup-child__1{
                -webkit-animation: cupChild1 10s ease-in-out infinite;
                        animation: cupChild1 10s ease-in-out infinite;
            }
            .cup-child__2{
                -webkit-animation: cupChild2 10s ease-in-out infinite;
                        animation: cupChild2 10s ease-in-out infinite;
            }
            .cup-child__3{
                -webkit-animation: cupChild3 10s ease-in-out infinite;
                        animation: cupChild3 10s ease-in-out infinite;
            }
            .cup-child__4{
                -webkit-animation: cupChild4 10s ease-in-out infinite;
                        animation: cupChild4 10s ease-in-out infinite;
            }
        }
        #tel{
            -webkit-animation: list 10s ease-in-out infinite;
                    animation: list 10s ease-in-out infinite;
        }
        #list{
            -webkit-animation: list 10s ease-in-out infinite;
                    animation: list 10s ease-in-out infinite; 
        }
        #cloud-smal-2{
            -webkit-animation: cloudSmal2 10s ease-in-out infinite;
                    animation: cloudSmal2 10s ease-in-out infinite;
        }
        #cloud-big{
            -webkit-animation: cloud-big 10s ease-in-out infinite;
                    animation: cloud-big 10s ease-in-out infinite;
            -webkit-transform-origin: 50% 0;
                -ms-transform-origin: 50% 0;
                    transform-origin: 50% 0;
        }
        #person{
            -webkit-animation: person 10s ease-in-out infinite;
                    animation: person 10s ease-in-out infinite;
            -webkit-transform-origin: 50% 0;
                -ms-transform-origin: 50% 0;
                    transform-origin: 50% 0;
        }
    }
}

@-webkit-keyframes person {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-10%);
                transform: translateY(-10%);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes person {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    50% {
        -webkit-transform: translateY(-10%);
                transform: translateY(-10%);
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@-webkit-keyframes cloud-big {
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50%{
        -webkit-transform: scale(.9);
                transform: scale(.9);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes cloud-big {
    0%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    50%{
        -webkit-transform: scale(.9);
                transform: scale(.9);
    }
    100%{
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes list {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(-5%,-5%);
                transform: translate(-5%,-5%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@keyframes list {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(-5%,-5%);
                transform: translate(-5%,-5%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@-webkit-keyframes listChild {
    0%{
        -webkit-transform: translateX(0) ;
                transform: translateX(0) ;
    }
    50%{
        -webkit-transform: translateX(5%);
                transform: translateX(5%);
    }
    100%{
        -webkit-transform: translateX(0) ;
                transform: translateX(0) ;
    }
}

@keyframes listChild {
    0%{
        -webkit-transform: translateX(0) ;
                transform: translateX(0) ;
    }
    50%{
        -webkit-transform: translateX(5%);
                transform: translateX(5%);
    }
    100%{
        -webkit-transform: translateX(0) ;
                transform: translateX(0) ;
    }
}

@-webkit-keyframes cloudSmal2{
    0%{
        -webkit-transform: translateX(0) ;
                transform: translateX(0) ;
    }
    50%{
        -webkit-transform: translateX(3%);
                transform: translateX(3%);
    }
    100%{
        -webkit-transform: translateX(0) ;
                transform: translateX(0) ;
    }
}

@keyframes cloudSmal2{
    0%{
        -webkit-transform: translateX(0) ;
                transform: translateX(0) ;
    }
    50%{
        -webkit-transform: translateX(3%);
                transform: translateX(3%);
    }
    100%{
        -webkit-transform: translateX(0) ;
                transform: translateX(0) ;
    }
}

@-webkit-keyframes cup {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(-4%,-5%);
                transform: translate(-4%,-5%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@keyframes cup {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(-4%,-5%);
                transform: translate(-4%,-5%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@-webkit-keyframes cupChild1 {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(1%,-2%);
                transform: translate(1%,-2%);
    }
    100%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
}

@keyframes cupChild1 {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(1%,-2%);
                transform: translate(1%,-2%);
    }
    100%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
}

@-webkit-keyframes cupChild2 {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(-1%,-1%);
                transform: translate(-1%,-1%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@keyframes cupChild2 {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(-1%,-1%);
                transform: translate(-1%,-1%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@-webkit-keyframes cupChild3 {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(1%,-1%);
                transform: translate(1%,-1%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@keyframes cupChild3 {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(1%,-1%);
                transform: translate(1%,-1%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}
@-webkit-keyframes cupChild4 {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(-1%,1%);
                transform: translate(-1%,1%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}
@keyframes cupChild4 {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(-1%,1%);
                transform: translate(-1%,1%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@-webkit-keyframes notebook {
    0%{
        -webkit-transform: translate(0,0) scale(1);
                transform: translate(0,0) scale(1);
    }
    50%{
        -webkit-transform: translate(1%,-4%) scale(.98);
                transform: translate(1%,-4%) scale(.98);
    }
    100%{
        -webkit-transform: translate(0,0) scale(1);
                transform: translate(0,0) scale(1);
    }
}

@keyframes notebook {
    0%{
        -webkit-transform: translate(0,0) scale(1);
                transform: translate(0,0) scale(1);
    }
    50%{
        -webkit-transform: translate(1%,-4%) scale(.98);
                transform: translate(1%,-4%) scale(.98);
    }
    100%{
        -webkit-transform: translate(0,0) scale(1);
                transform: translate(0,0) scale(1);
    }
}

@-webkit-keyframes calendare {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(2%,-2%);
                transform: translate(2%,-2%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@keyframes calendare {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(2%,-2%);
                transform: translate(2%,-2%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@-webkit-keyframes book {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(5%,-3%);
                transform: translate(5%,-3%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@keyframes book {
    0%{
        -webkit-transform: translate(0,0);
                transform: translate(0,0);
    }
    50%{
        -webkit-transform: translate(5%,-3%);
                transform: translate(5%,-3%);
    }
    100%{
        -webkit-transform: translate(0,0) ;
                transform: translate(0,0) ;
    }
}

@-webkit-keyframes cloud-small{
    0%{
        -webkit-transform: translateX(0) translateY(-1%) ;
                transform: translateX(0) translateY(-1%) ;
    }
    50%{
        -webkit-transform: translateX(7%) translateY(-1%);
                transform: translateX(7%) translateY(-1%);
    }
    100%{
        -webkit-transform: translateX(0) translateY(-1%);
                transform: translateX(0) translateY(-1%);
    }
}

@keyframes cloud-small{
    0%{
        -webkit-transform: translateX(0) translateY(-1%) ;
                transform: translateX(0) translateY(-1%) ;
    }
    50%{
        -webkit-transform: translateX(7%) translateY(-1%);
                transform: translateX(7%) translateY(-1%);
    }
    100%{
        -webkit-transform: translateX(0) translateY(-1%);
                transform: translateX(0) translateY(-1%);
    }
}