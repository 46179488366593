@charset "UTF-8";
$bs-witdh: 3;
$card-radius: $bs-witdh;
$card-padding: 10;
$card-padding-px: $card-padding + px;

.card {
    position: relative;
    margin: {
        top:    0;
        left:   0;
        right:  0;
        bottom: 20px;
    }
    padding: {
        top:    $card-padding-px;
        left:   $card-padding-px;
        right:  $card-padding-px;
        bottom: $card-padding-px;
    }
    border-radius:      $card-radius + px;
    background-color:   $light-color;
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
    box-shadow:         0 2px 6px 0 rgba(0, 0, 0, 0.05);
    border:             solid 1px $border-color;
    overflow:           hidden;

    .card-header {
        line-height: normal;
        .title {
            font-size: 15px;
        }

        .subheader {
            float: right;
            &.checkbox {
                margin-top:   2px;
                margin-right: -3px;
            }
            .advanced-checkbox {
                margin-top: 0px;
                margin-bottom: 0px;
            }

        }
        &.card-header__flex{
            display: flex;
            justify-content: space-between;
            .lock-icon{
                margin-left: 15px;
                color: $disabled;
                font-size: 15px;
                &.lock--complite{
                    color: $success-color;
                }
                &.lock--close{
                    color: $danger-color;
                }
            }
        }
        

    }

    .card-content {
        margin-top: $card-padding-px;
        font-size: 14px;

        .picture {
            padding: 0;
            margin: {
                left:  -$card-padding-px;
                right: -$card-padding-px;
            }
            background-color: #eee;
            border: none;
            .image {
                -o-object-fit: cover;
                   object-fit: cover;
                width: 100%;
                height: 160px;
                @include max-width-breakpoint($breakpoint-iphones) {
                    height: 100px;
                }
            }
        }

        .paragraph {
            margin:      $card-padding-px 0 0 0!important;
            line-height: normal;
        }

        .note {
            margin:      $card-padding-px 0 0 0!important;
            line-height: normal;
            font-size:   14px;
            color:       darkgrey;
            font-style:  italic;
        }
    }

    .separator {
        border-top: 1px solid #E8E8E8;
        margin: {
            top:    $card-padding-px;
            right: -$card-padding-px;
            bottom: $card-padding-px;
            left:  -$card-padding-px;
        }
    }

    .card-footer {
        font-size: 14px;
        line-height: normal;
        .left-part {
            font-style:    italic;
            padding-right: $card-padding / 2 + px;
            vertical-align: bottom;
            white-space:    nowrap;
        }
        .right-part {
            font-style:   italic;
            padding-left: $card-padding / 2 + px;
            text-align:   right;
            width:        100%;
        }
    }

    .text-success {
        color: $success-color;
    }

    &.selected {
        background-color: $success-lt-color;
    }
    &.deferred {
        background-color: $body-background-color;
    }
    .success{
        @extend .text-success
    }
    .fail{
        color: $danger-color;
    }
}

.card-colored {
    @extend .card;

    padding-left: $card-padding + $bs-witdh + px;
    border-left:  0 !important;

    &:before {
        content:       '';
        position:      absolute;
        width:         $bs-witdh + px;
        height:        100%;
        top:           0;
        bottom:        0;
        left:          0;
        border-radius: $card-radius + px 0px 0px $card-radius + px;
    }
}

.card-red {
    @extend .card-colored;
    &:before {
        background-color: $danger-color;
    }
}
.card-green {
    @extend .card-colored;
    &:before {
        background-color: $success-color;
    }
}
.card-blue {
    @extend .card-colored;
    &:before {
        background-color: $primary-color;
    }
}
.card-cyan {
    @extend .card-colored;
    &:before {
        background-color: $info-color;
    }
}
.card-orange {
    @extend .card-colored;
    &:before {
        background-color: $warning-color;
    }
}
.card-grey {
    @extend .card-colored;
    &:before {
        background-color: $secondary-color;
    }
}

.base-master-body{
    &:not(.sidebar-hidden){
        .card-footer{
            @include max-width-and-breakpoint($breakpoint-desktop-small, $breakpoint-desktop-mid){
                tr{
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                        -ms-flex-direction: column;
                            flex-direction: column;
                    .left-part{
                        padding-right: 0;
                        -webkit-box-ordinal-group: 3;
                            -ms-flex-order: 2;
                                order: 2;
                        width: 100%;
                    }
                    .right-part{
                        padding-left: 0;
                        width: 100%;
                        -webkit-box-ordinal-group: 2;
                            -ms-flex-order: 1;
                                order: 1;
                        padding-bottom: 5px;
                        text-align: left;
                    }
                }
            }
        }
    }
}
