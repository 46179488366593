.partner-account-card {
  font-size: 13px;
  line-height: 1.54;
  color: $body-color;

  .agent-item {
    display: flex;
    .popover-content-userpic {
      width: 80px;
      height: 80px;
      object-fit: cover;
      margin-right: 8px;
    }
  }
}