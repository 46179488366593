@charset "UTF-8";
.base-master {
    .checkbox-in-label + .advanced-checkbox {
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            padding-top: 6px;
            margin-top: 7px;
        }
    }
    .pull-right .advanced-checkbox {
        padding-top: 6px;
        margin-top: 12px;
    }
    .advanced-checkbox-wrapper {
        margin-top: 20px;
        @include max-width-breakpoint(991px) {
            margin-top: 15px;
        }
    }
    .highslide-container {
        z-index: 1060 !important;
    }
    .intl-tel-input {
        width: 100%;
        .selected-flag {
            background-color: initial;
            border-right: 1px solid #CCC;
            &:focus {
                outline: none;
            }
            .iti-arrow {
                display: none;
            }
        }
        &.allow-dropdown .selected-flag,
        &.separate-dial-code .selected-flag {
            width: 38px;
        }
    }
    .fileinput-button {
        margin-right: 13px;
        &.disabled {
            cursor: default;
        }
        &:not(.disabled):hover {
            background-color: $primary-color;
            color: $light-color;
        }
    }
    .text-controls {
        .highslide-next {
            span {
                height: 22px;
            }
        }
    }
    .base-master-body {
        .language-button {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
        .noty_modal {
            display: none;
        }
        .notifications-holder {
            position: fixed;
            width: $notification-width;
            left: initial;
            right: 20px;
            top: 59px;
            z-index: $animation-layers-index;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                right: 10px;
            }
        }
        .notifications-modal {
            z-index: $animation-layers-index + 11;
        }
        .control-label-with-checkbox {
            width: 100%;
            text-align: left;
            .checkbox {
                float: right;
                padding-top: 0;
                line-height: 1;
                min-height: initial;
                label {
                    line-height: 1.67;
                    font-size: 12px;
                    color: #333333;
                    &:before {
                        margin-top: 1px;
                    }
                }
            }
        }
        .validator-service-container {
            position: relative;
            .form-control-feedback {
                right: 0;
            }
        }
    }
    .control-label-with-toggler,
    .property-table-checkbox,
    .checkbox-with-subtitle {
        .advanced-toggler {
            margin-top: 0;
            padding-top: 0;
        }
    }
    .control-label-with-toggler {
        width: 100%;
        text-align: left;
        .advanced-toggler {
            float: right;
            margin-top: 3px;
        }
    }

    .ql-wrapper {
        border-radius: 4px;
        border: 1px solid #ccc;
    }

    .ql-toolbar {
        border: none;
        border-bottom: 1px solid #ccc;
    }
    .ql-container {
        border: none;
        padding: 0px;
        font-size: 14px;
        .ql-editor {
            min-height: 200px;
        }
    }
}
