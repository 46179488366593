@charset "UTF-8";
.customer-synopsis {
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        .panel-separator {
            display: none;
        }
        .synopsis-states {
            margin-bottom: 20px;
        }
    }
    .communications-table-container {
        height: auto !important;
        padding-bottom: 5px;
        margin-top: 0;
        .communications-table {
            margin: {
                top: 0;
                bottom: 0;
            }
            th, td {
                padding: {
                    left:  20px!important;
                    right: 20px!important;
                }
            }
            td {
                font-weight: normal;
                white-space: nowrap;
                &.cell-moment {
                    font-weight: normal !important;
                }
                width: auto;
                ul {
                    margin-bottom: 0px;
                    padding-left:  0px;
                    list-style:    none;
                    li:before {
                        content:       "–";
                        padding-right: 5px;
                        color:         #4990E2;
                    }
                }
            }
            th {
                color: $dark-color;
            }
            th.text-center {
                text-align: center;
            }
            .cell-type {
                width: 190px;
            }
            .cell-moment {
                width:       80px;
                white-space: nowrap;
            }
            .cell-item {
                width:       100px;
                white-space: nowrap;
            }
            .cell-direction {
                width:         10px;
                white-space:   nowrap;
                padding-left:  0!important;
                padding-right: 0!important;
                font-size:     15px;
            }

            .cell-info {
                width:       80px;
                white-space: nowrap;
                text-align:  left;
                .play-button {
                    font-size:    1.0em;
                    margin-right: 5px;
                }
                .duration {
                    padding-left: 5px;
                }

                .play {
                    padding-top:    3px;
                    padding-bottom: 2px;
                    padding-left:   7px;
                    padding-right:  7px;
                    i {
                        font-size: 1.0em;
                    }
                }
                .mejs__container {
                    display: inline-block;
                    vertical-align: middle;
                }
                .mejs__duration {
                    color: $body-color;
                }
            }
            .cell-contract {
                width:       100px;
                white-space: nowrap;
            }
            .cell-comment {
                width:   400px;
                display: none;
            }
        }
    }
    .synopsis-subtitle {
        margin-right: 10px;

    }
}