@charset "UTF-8";
.non-residential-premises-rent-offer {
    .offer-panel {
        .offer-panel-separator {
            margin: {
                top: 0;
                bottom: 0;
            }
        }
        .offer-panel-footer {
            .buttons {
                padding-right: 5px;
            }
        }
    }
    .map-holder-row {
        margin-top: -15px;
        .map-holder {
            height: 450px;
        }
    }
}