@charset "UTF-8";
.base-master {
    .bootstrap-select {
        &.disabled {
            background-color: #eeeeee;
            color: #333333;
            -webkit-box-shadow: inset 0 1px 0 0 #ececec;
            box-shadow: inset 0 1px 0 0 #ececec;
            //border: solid 1px #cccccc;
            .dropdown-toggle{
                &.disabled {
                    background: transparent;
                }
            }
        }
    }
    .input-group {
        width: 100%;
    }
    .form-control {
        border-radius: $card-radius + px;
        background-clip: padding-box;
        &:hover {
            border-color: $sidebar-active;
        }
    }
    textarea.form-control {
        max-width: 100%;
    }
    .input-group-btn {
        &.blank {
            width: 0;
        }
    }
    .bootstrap-select {
        .btn-default {
            &:hover {
                background-color: #fff;
                border-color: $sidebar-active;
            }
        }
        &.open {
            .btn-default {
                background-color: #fff;
                border-color: none;
            }
        }
    }
    .bootstrap-select.-blank:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
        width: auto;
        margin-left: 20px;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            margin-left: 0;
            margin-top: 22px;
        }
        .dropdown-toggle {
            left: auto;
            right: 0;
            padding: {
                top: 0;
                bottom: 0;
            }
            font-size: 14px;
            line-height: 1.43;
            color: $info-alter-color;
            background-color: transparent;
            border: 0;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                padding-left: 0;
                padding-right: 16px;
                .caret {
                    right: 5px;
                }
            }
        }
    }
    .bootstrap-select .no-results {
        display: block;
        margin: 0 0 -5px;
        padding: 10px 20px;
        background: #f0f0f0;
        color: #999;
        font-size: 11px;
        font-style: italic;
        font-weight: 500;
        line-height: 1;
        clear: both;
        overflow: hidden;
    }
    .modal-backdrop {
        z-index: $animation-layers-index;
    }
    .modal {
        z-index: $animation-layers-index + 20;
        .modal-content {
            background-color: $body-background-color;
            border-radius: 3px;
        }
        .modal-footer {
            margin-top: -14px;
        }
        .checkbox {
            margin-top: 5px;
            margin-bottom: 0;
        }
        .input-group .form-control {
            width: 50%;
        }
        .input-group-split .form-control + .form-control {
            width: 50%;
        }
        .input-group .form-control + .form-control {
            margin-left: -1px;
        }
        .input-group-full .form-control {
            width: 100%;
        }
        .bootbox-body {
            padding-bottom: 14px;
        }
    }
    .modal-open-noscroll {
        padding-right: 0 !important;
    }
    .text-warning {
        color: $warning-color;
    }
    .text-danger {
        color: $danger-color;
    }
    .text-success {
        color: $success-color;
    }
    .text-link {
        color: $link-color;
    }
    .text-info {
        color: $info-color;
    }
    .text-muted {
        color: $muted-color;
    }
    .bg-warning {
        background-color: $warning-color;
    }
    .bg-danger {
        background-color: $danger-color;
    }
    .bg-success {
        background-color: $success-color;
    }
    .bg-alter {
        background-color: $alter-color;
    }
    .bg-light {
        background-color: $light-color;
    }
    .btn {
        border-radius: $card-radius + px;
        min-height: 34px;//хак для зума
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        &:focus {
            outline: none;
        }
        .fa-trash-alt{
            & + span{
                @include max-width-breakpoint($breakpoint-tablet) {
                    display: none;
                }
            }
        }
    }
    .btn-info {
        background-color: $info-color;
        border-color: $info-color;
    }
    .btn-success {
        color: $light-color;
    }
    .btn-outline {
        &-primary {
            background-color: transparent;
            background-image: none;
            color: $link-color;
            border-color: $primary-color;
            &:enabled:hover {
                background-color: $primary-color;
                color: $light-color;
            }
        }
        &-secondary {
            background-color: transparent;
            background-image: none;
            color: $secondary-color;
            border-color: $secondary-color;
            &:enabled:hover {
                background-color: $secondary-color;
                color: $light-color;
            }
        }
        &-success {
            background-color: transparent;
            background-image: none;
            color: $success-color;
            border-color: $success-color;
            &:enabled:hover {
                background-color: $success-color;
                color: $light-color;
            }
        }
        &-danger {
            background-color: transparent;
            background-image: none;
            color: $danger-color;
            border-color: $danger-color;
            &:enabled:hover {
                background-color: $danger-color;
                color: $light-color;
            }
        }
        &-warning {
            background-color: transparent;
            background-image: none;
            color: $warning-color;
            border-color: $warning-color;
            &:enabled:hover {
                background-color: $warning-color;
                color: $light-color;
            }
        }
        &-info {
            background-color: transparent;
            background-image: none;
            color: $info-color;
            border-color: $info-color;
            &:enabled:hover {
                background-color: $info-color;
                color: $light-color;
            }
        }
        &-light {
            background-color: transparent;
            background-image: none;
            color: #f8f9fa;
            border-color: #f8f9fa;
            &:enabled:hover {
                background-color: #f8f9fa;
                color: #212529;
            }
        }
        &-dark {
            background-color: transparent;
            background-image: none;
            color: $dark-color;
            border-color: $dark-color;
            &:enabled:hover {
                background-color: $dark-color;
                color: $light-color;
            }
        }
    }
    .popover {
        max-width: initial;
    }
    .base-master-body {
        .input-group-btn:last-child {
            & > .btn,
            & > .btn-group {
                z-index: auto;
            }
        }
        .alert {
            margin: {
                top: $header-margin-top * 4;
                bottom: $header-margin-top * 4;
                left: 0;
                right: 0;
            }
        }
        .nav-pills {
            li {
                &.disabled {
                    padding: {
                        top: 12px;
                        bottom: 11px;
                        left: 12px;
                        right: 12px;
                    }
                }
                &.disabled {
                    a, span {
                        &:hover {
                            cursor: not-allowed;
                        }
                    }
                }
            }
        }
        .form-group-lg .form-control {
            font-size: 24px;
            font-size: calc(8px + 1vw);
        }
        .form-control {
            &[disabled],
            &[readonly] {
                background-color: #eeeeee;
                color: #333333;
                -webkit-box-shadow: inset 0 1px 0 0 #ececec;
                        box-shadow: inset 0 1px 0 0 #ececec;
                border: solid 1px #cccccc;
            }
        }
        .control-label {
            margin: {
                top: 18px;
                bottom: 5px;
                left: 0;
                right: 0;
            }
            font: {
                size: 12px;
                weight: bold;
            }
            line-height: 1.67;
            color: #333333;
            &:first-child {
                margin-top: 0;
            }
        }
        .has-feedback {
            label ~ .form-control-feedback {
                top: 26px;
            }
        }
        .input-group {
            .selectpicker:first-child + .btn-group {
                .btn {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    &:hover {
                        z-index: 3;
                    }
                }
            }
        }
        .input-group-split {
            .form-control {
                width: 108px;
                padding: {
                    top: 8px;
                    bottom: 6px;
                }
                line-height: 1.54;
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    width: 88px;
                }
                & + .form-control {
                    width: 109px;
                    border-right: 0;
                    &:focus {
                        border-right: 1px solid #66afe9;
                    }
                    @include max-width-breakpoint($breakpoint-desktop-mid) {
                        width: 89px;
                    }
                    margin-left: -1px;
                }
            }
            .input-group-btn,
            .input-group-addon {
                width: 64px;
                height: 34px;
                .dropdown-toggle {
                    background-color: #eeeeee;
                    width: 64px;
                }
            }
        }
        .input-group-full {
            width: 100%;
        }
        .input-group-btn-split {
            width: 0;
            & + .form-control {
                border-left: 0;
            }
        }
        .input-group-btn {
            .btn {
                background-color: #eeeeee;
            }
        }
        .input-group-btn-fixed {
            width: 64px;
            .btn {
                width: 64px;
                height: 34px;
                background-color: #eeeeee;
            }
        }
        .input-group-btn + .form-control {
            margin-left: -1px;
        }
        .checkbox-with-subtitle {
            float: right;
        }
        .checkbox {
            &.checkbox-with-title {
                margin: 0;
                padding: 0;
                float: right;
                .advanced-checkbox {
                    margin: 0;
                    padding: 0;
                }
            }
            &.checkbox-with-subtitle {
                margin: 0;
                padding: 0;
                float: right;
            }
            label {
                display: block;
                line-height: 1.26;
                text-align: left;
                &:before {
                    margin-top: -2px;
                }
                @include max-width-breakpoint($breakpoint-pad-landscape) {
                    white-space: initial;
                }
            }
            input[type="checkbox"],
            input[type="radio"]{
                width: 17px;
                height: 17px;
            }
            input[type="checkbox"]:disabled + label,
            input[type="radio"]:disabled + label {
                opacity: 1;
            }
        }
        .table {
            empty-cells: show;
            border-collapse:separate;
            td {
                width: 160px;
                &:last-child {
                    width: auto;
                }
            }
        }
    }
    .alert {
        & > ul:not(.list-unstyled) {

            padding-left: 15px;
        }
    }
}