@charset "UTF-8";
.search-read {
    @include custom-bs-grid;
    @include content-padding;
    @include clearfix;
    clear: both;
    .with-tabs {
        border-top: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }
    .search-read-header {
        margin: {
            bottom: 22px;
        }
    }
    .search-read-title {
        margin: 0;
        font-size: 18px;
        color: $dark-color;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            margin-top: 14px;
            width: 100%;
            float: initial;
            & + .search-sort-group{
                padding-top: 20px;
                overflow: hidden;
            }
        }
    }
    .search-sort-group {
        float: right;
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: 100%;
            float: initial;
        }
        .dropdown-menu {
            left: auto;
            right: 0;
        }
    }
    .search-list {
        margin: {
            top: 0;
            bottom: 0;
            right: -0.89%;
            left: 0;
        }
        padding: 0;
        list-style-type: none;
        font-size: 0;
        @include max-width-breakpoint($breakpoint-desktop-wide) {
            margin-right: -1.0416666%;
        }
        @include max-width-breakpoint($breakpoint-desktop) {
            margin-right: -1.5%;
        }
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            margin-right: -3%;
        }
        @include max-width-breakpoint($breakpoint-iphones) {
            margin-right: 0;
        }
    }
    .search-list-item {
        display: inline-block;
        width: 15.7696366%;
        margin: {
            right: 0.89%;
        }
        vertical-align: top;
        zoom: 1;
        @include max-width-breakpoint($breakpoint-desktop-wide) {
            width: 23.9583333%;
            margin-right: 1.0416666%;
        }
        @include max-width-breakpoint($breakpoint-desktop) {
            width: 31.8%;
            margin-right: 1.5%;
        }
        @include max-width-breakpoint($breakpoint-pad-landscape) {
            width: 47%;
            margin-right: 3%;
        }
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: 100%;
        }
    }
    .search-list-alert {
        margin-left: 0;
        font-size: 14px;
    }
    .result-item-pager {
        margin: {
            top: 20px;
            bottom: 20px;
        }
    }
}
.sidebar-filter {
    .map {
        width: 280px;
        height: 280px;
        margin: {
            bottom: 20px;
        }
        border-radius: 3px;
        border: solid 1px #cccccc;
        cursor: pointer;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            width: 240px;
        }
    }
    .form.search-form {
        padding: 0;
        max-width: 100%;
    }
}