%card-maps{
    margin-bottom: 0;
    border-bottom: none;
    box-shadow: none;
    padding-top: 30px;
}

.find-variants {
    .card-item {
        .card-item-header {
            width: 100%;
        }
    }
    .sidebar.sidebar-filter {
        .sidebar-body {
            padding-bottom: 0;
        }
    }
    .search-list {
        position: relative;
    }
    .search-read-title{
        @include max-width-breakpoint($breakpoint-tablet){
            margin-bottom: 20px;
        }
    }
}

[class$="-b-cluster-content"],
[class$="-b-cluster-tabs"]{
    display: flex !important;
    height: auto !important;

    & > ymaps{
        float: none !important;
    }
}

[class$="-b-cluster-tabs__content-item"]{
    padding-top: 10px;

    .card{
        border-bottom: solid 1px #DDD;
        padding-top: 10px;
        width: 300px;
    }
}
[class$="-b-cluster-tabs__menu"]{
    padding-top: 10px;
    padding-left: 10px !important;
    padding-bottom: 30px !important;
}

[class*="-cluster-tabs__menu-item-text"]{
    padding: 5px;
}

[class*="-balloon__content"]{
    padding: 0 !important;
    margin-right: 0 !important;
    & > ymaps{
        height: auto !important;
    }
    .card{
        @extend %card-maps;

        .card-header{
            padding-right: 30px;
        }
    }
}

.gm-style{

    .gm-style-iw-c{
        padding: 0 !important;
        border-radius: 0 !important;

        & > div{
            overflow: hidden !important;
        }

        .card{
            @extend %card-maps;
        }

        .gm-style-iw-d{

            & + button{
                right: 0 !important;
                top: 0 !important;
            }

        }

    }

}

.recrm-table-wrapper{
    &.find-variants-table__wrapper{
        @include min-width-breakpoint($breakpoint-pad-portrait){
            margin-left: -30px;
            margin-right: -30px;
            max-width: none;
            width: -webkit-calc(100% + 58px);
            width: calc(100% + 58px);
        }
    }
    .lock-icon{
        color: $disabled;
        font-size: 15px;
        text-align: center;
        &.lock--complite{
            color: $success-color;
        }
        &.lock--close{
            color: $danger-color;
        }
    }
    .find-variants-table{
        tr{
            &.selected{
                td{
                    background-color: #e9ffdb;
                }
            }
            &.deferred {
                td {
                    background-color: $body-background-color;
                }
            }
            td {
                width: auto !important;
                .fa-vcard{
                    display: none;
                }
            }
            th {
                color: $dark-color;
                &:first-child{
                    border-left: 1px solid $border-color;
                }
                &:last-child{
                    border-right: 1px solid $border-color;
                }
            }
            .text-nowrap{
                white-space: nowrap;
            }
            .text-center {
                text-align: center;
            }
            .text-left{
                text-align: left;
            }
            .text-right{
                text-align: right;
            }
            .cell-checkbox {
                width: 19px;
                padding-right: 0 !important;
                input[type="radio"], input[type="checkbox"] {
                    margin-top: 0;
                }
                .radio, .checkbox {
                    margin-top: 0;
                    padding-top: 0;
                    label > span {
                        padding-left: 0;
                    }
                    label > span::after,
                    label > span::before {
                        top: -7px !important;
                    }
                }
                .checkbox {
                    label{
                        span{
                            &::before{
                                top: 0;
                            }
                        }
                    }
                }

            }
            th.th-images{
                padding: 0 !important;
            }
            td.td-images{
                padding-right: 0 !important;
                font-size: 24px;
                color: $disabled;
                text-align: center;
                width: 36px !important;
                text-align: center;
                a.height-td__inherit{
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    text-align: center;
                    justify-content: center;
                    img{
                        width: 36px;
                        height: 36px;
                        display: block !important;
                        margin-left: auto;
                        margin-right: auto;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    .fas{
                        color: $disabled;
                    }
                }
                [data-popup-img]{
                    width: 36px;
                    height: 36px;
                    display: block !important;
                    margin-left: auto;
                    margin-right: auto;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            @include max-width-breakpoint($breakpoint-1800){

                th, td{
                    padding-left: 18px !important;
                    padding-right: 18px !important;
                }

            }
            @include max-width-breakpoint($breakpoint-notebook-big){
                th, td{
                    padding-left: 13px !important;
                    padding-right: 13px !important;
                }
                .cell-updated{
                    white-space: normal;
                }
            }
        }
    }
}


#find-variants-search-header{
    flex-wrap: wrap;

    .search-sort-group__flex{
        @include max-width-breakpoint($breakpoint-tablet){
            width: 100%;
        }
    }
}