@charset "UTF-8";
.bind-customer {
    .panel-group {
        margin-top: -18px;
    }

    .panel-default {
        margin-top: 20px!important;
    }

    .panel-title > a {
        color:     inherit!important;
        font-size: 14px;
    }
    .communications-table td {
        cursor: pointer;
    }
}