@charset "UTF-8";
.account-search-subform {
    .mCSB_container{
        @include min-width-breakpoint($breakpoint-pad-landscape) {
            width: 100% !important;
        }
    }
    .mCSB_scrollTools{
        @include min-width-breakpoint($breakpoint-pad-landscape) {
            display: none !important;
        }
    }
    table.recrm-table {
        margin-bottom: 130px !important;
    }
    .hint {
        position: relative;
        float: right;
        margin-top: 7px;
        &:hover {
            .hint-popup {
                height: auto;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .hint-popup {
        position: absolute;
        min-width: 165px;
        height: 0;
        top: 12px;
        right: 0;
        margin: 0;
        padding: {
            top: 5px;
            bottom: 5px;
            left: 8px;
            right: 8px;
        }
        opacity: 0;
        visibility: hidden;
        border-radius: 3px;
        background-color: $light-color;
        -webkit-box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px rgba(0, 0, 0, 0.1);
        -webkit-transition: opacity 300ms ease-out;
        transition: opacity 300ms ease-out;
        list-style-type: none;
        z-index: 6;
    }
    .hint-popup-item {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        .hint-box {
            margin: {
                top: 6px;
                right: 7px;
            }
        }
    }
    .hint-text {
        float: left;
        white-space: nowrap;
        color: #9b9b9b;
        font-size: 11px;
        font-style: italic;
        line-height: 1.82;
    }
    .hint-box {
        float: left;
        margin-left: 4px;
        width: 8px;
        height: 8px;
        &.type {
            &0 {
                background-color: #4c7db7;
            }
            &1 {
                background-color: #a4d56f;
            }
            &2 {
                background-color: #cb7fdb;
            }
            &3 {
                background-color: #88674a;
            }
            &4 {
                background-color: #f7c97e;
            }
            &5 {
                background-color: #7ec9e0;
            }
        }
    }
    .table{
        width: 100%;
        tbody{
            width: 100%;
        }
        .ui-sortable-handle{
            &:hover{
                cursor: pointer;
                .td-name{
                    &::before{
                        display: block;
                    }
                }
            }
            &.ui-sortable-helper{
                width: 100% !important;
                .td-name{
                    &::before{
                        display: block;
                    }
                }
            }
        }
        
        .td-name{
            position: relative;
            @include min-width-breakpoint($breakpoint-pad-landscape) {
                padding-left: 30px !important;
            }
            &::before{
                content: "\f142";
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                color: #9B9B9B;
                position: absolute;
                left: 6px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 16px;
                display: none;
                @include min-width-breakpoint($breakpoint-pad-landscape) {
                    left: 13px;
                }
            }
        }
        .td-confirm{
            text-align: right;
        }
    }
}