@charset "UTF-8";
.change-password {
    .change-password-form {
        width: 330px;
        padding: 15px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: {
            top: -169px;
            left: -165px;
        }
        .textbox {
            position: relative;
            height: auto;
            padding: 10px;
            font-size: 16px;
            box-sizing: border-box;
        }
    }
    /* Glued text fields */
    .joint-textboxes-upper:focus, .joint-textboxes-lower:focus {
        z-index: 2;
    }
    .joint-textboxes-upper {
        margin-bottom: -1px;
        border-bottom-left-radius:  0;
        border-bottom-right-radius: 0;
    }
    .joint-textboxes-lower {
        margin-bottom: 10px;
        border-top-left-radius:  0;
        border-top-right-radius: 0;
    }
    .error-message {
        margin-bottom: 10px;
    }
}