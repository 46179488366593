.recrm-campaign {
    .campaign-form {
        .property-card {
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                margin-top: 5px;
            }
        }
        .visit-card {
            .row {
                margin-left: 0;
                margin-right: 0;
            }
            .form-control-feedback {
                right: 0;
            }
            .form-row {
                &:last-child {
                    margin-bottom: 0;
                    @include max-width-breakpoint($breakpoint-pad-portrait) {
                        margin-bottom: 15px;
                    }
                }
            }
        }
        .input-group-addon {
            &, &.bg-light {
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                &:hover {
                    background-color: #EEE;
                }
            }
        }
    }
}