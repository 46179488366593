﻿@charset "UTF-8";
.base-master {
    height: 100%;
    font-family: $font-family;
    a {
        color: $link-color;
    }
    hr {
        border-top: 1px solid $border-color;
    }
    sup {
        top: -0.8em;
        font-size: 50%;
    }
    &.menu-collapsed {
        position: relative;
        overflow: hidden;
        .wrapper,
        .base-master-body {
            overflow: hidden;
        }
        .overlay {
            display: block;
        }
        .sidebar-toggler {
            background-color: darken($sidebar-bg, 50%) !important;
            color: darken($light-color, 50%) !important;
        }
    }
    .base-master-body {
        height: 100%;
        font-family: $font-family;
        background-color: $body-background-color;
        color: $body-color;
        -webkit-overflow-scrolling: touch;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            -webkit-transition: -webkit-transform 300ms ease;
            transition: -webkit-transform 300ms ease;
            transition: transform 300ms ease;
            transition: transform 300ms ease, -webkit-transform 300ms ease;
            -webkit-transition: transform 300ms ease;
            -webkit-overflow-scrolling: auto;
        }
        .overlay {
            position: fixed;
            display: none;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: inherit;
            z-index: $animation-layers-index - 30;
            -webkit-overflow-scrolling: auto;
        }
        .wrapper {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            min-height: 100%;
            padding-top: $top-menu-height;
        }
        .container {
            width: $container-width;
            margin: {
                left: auto;
                right: auto;
            }
            padding: 0;
            text-align: center;
            .brand-desktop {
                display: inline-block;
            }
            .navbar-nav {
                float: none;
                display: inline-block;
                margin: {
                    bottom: 0;
                    top: 0;
                }
                & > li {
                    float: none;
                    display: inline-block;
                    & > a {
                        padding: {
                            bottom: 15px;
                            top: 15px;
                        }
                    }
                }
                @include max-width-breakpoint($breakpoint-mobile-menu) {
                    display: none;
                }
            }
            .mobile-menu-toggler {
                display: none;
                color: $navbar-link-color;
                cursor: pointer;
                @include max-width-breakpoint($breakpoint-mobile-menu) {
                    display: inline-block;
                    position: relative;
                    top: 4px;
                    padding: {
                        bottom: 10px;
                        top: 10px;
                    }
                }
                .caret {
                    border-top: 4px solid; /*ios fix, ff broke*/
                }
            }
            .mobile-menu-toggler-text {
                vertical-align: middle;
                display: inline-block;
                max-width: 175px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font: {
                    size: 14px;
                    weight: 500;
                }
                color: $navbar-link-color;

                @include max-width-breakpoint(380px) {
                    max-width: 150px;
                }
                @include max-width-breakpoint(360px) {
                   max-width: 110px;
                }
            }
        }
        .toolbar {
            display: none;
            position: fixed;
            width: 100%;
            padding: {
                top: 12px;
                bottom: 12px;
            }
            background-color: $body-background-color;
            -webkit-box-shadow: 0px 0px 8px 0px rgba(163, 163, 163, 1);
            box-shadow: 0px 0px 8px 0px rgba(163, 163, 163, 1);
            z-index: 900;
            .sidebar {
                margin-top: 2px;
                hr {
                    margin: {
                        top: 10px;
                        bottom: 10px;
                        right: 0;
                        left: -10px;
                    }
                }
            }
        }
        .actionbar {
            position: fixed;
            bottom: 0;
            width: 100%;
            padding: {
                top: 12px;
                bottom: 12px;
            }
            -webkit-box-shadow: 0px 0px 8px 0px rgba(163, 163, 163, 1);
            box-shadow: 0px 0px 8px 0px rgba(163, 163, 163, 1);
            background-color: $body-background-color;
            z-index: 900;
        }
        .content-title {
            margin: 0;
            font-size: 24px;
            color: $body-color;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                line-height: 2;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
        .content-title-wrapper {
            width: 100%;
            clear: both;
            padding: {
                top: 15px;
                bottom: 20px;
                left: 28px;
                right: 28px;
            }
            @include clearfix;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                padding: {
                    top: 10px;
                    bottom: 10px;
                    left: 20px;
                    right: 20px;
                }

                display: flex;
                flex-direction: column;

                .content-title-sort-group{
                    order: 2;

                    & +  .content-title{
                        order: 1;
                        width: 100%;
                    }
                }
            }
        }
        .content-title-checkbox {
            padding-top: 0;
            margin-top: 16px;
            margin-bottom: 0;
        }
        .content-title-sort-group {
            margin-top: 14px;
        }
        .content-subtitle {
            margin: {
                top: 15px;
                bottom: 5px;
            }
            padding: 0px;
            font: {
                size: 18px;
                weight: bold;
            }
            line-height: 1.44;
            color: #333;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                margin: {
                    top: 0;
                    bottom: 7px;
                }
            }
        }
        .main {
            position: relative;
            width: 100%;
            max-width: 100%;
            overflow-x: auto;
            left: 0;
            -webkit-transition: left 150ms linear;
            transition: left 150ms linear;
            will-change: left;
        }
        .main-content {
            position: relative;
            -webkit-box-flex: 0;
                -ms-flex: none;
                    flex: none;
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                width: 100%;
            }
            .map {
                margin-bottom: 10px;
            }
            .form-content{
                &:not(.request-form){
                    overflow-x: hidden;
                }
                
                &.campaign-form{
                    padding-left: 1px;
                }
            }
        }
        .input-group-icon {
            position: relative;
        }
        .input-group-addon-icon {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            padding-left: 12px;
            .fa {
                color: $muted-grey;
            }
            & + .form-control {
                padding-left: 46px;
                @include max-width-breakpoint($breakpoint-pad-portrait) {
                    padding-left: 36px;
                }
            }
        }
        .form-group-lg {
            .input-group-addon-icon {
                .fa {
                    font-size: 24px;
                    font-size: calc(12px + 1vw);
                    line-height: 0.83;
                }
            }
        }
    }
    .middle-modal-dialog {
        width: auto;
        max-width: $breakpoint-desktop-mid;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
    .modal-dialog.fullscreen-modal-dialog {
        position: relative;
        width: auto;
        height: calc(100% - 5px);
        margin: 2px;
        overflow: hidden;
        border-radius: 6px;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            margin: 0;
            height: 100vh;
        }
        .modal-content {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            width: 100%;
            height: 100%;
            -webkit-transition: padding 300ms ease-out;
            transition: padding 300ms ease-out;
            will-change: padding;
            .modal-footer {
                margin-top: 0;
            }
        }
        .modal-body {
            padding: 0;
            height: 100%;
            overflow: hidden;
        }
        .modal-header,
        .modal-footer {
            background-color: $light-color;
        }
        .modal-header {
            padding-left: 28px;
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                padding-left: 20px;
            }
        }
        .modal-title {
            line-height: 1.028571;
        }
        .main {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            overflow: hidden;
            .main-content {
                width: 100%;
                overflow-y: auto;
                -webkit-transform: none;
                transform: none;
                -webkit-transition: none;
                transition: none;
                min-height: 100%;
                z-index: 3;
            }
        }
        .sidebar-modal-close {
            float: right;
            padding: 5px;
            cursor: pointer;
        }
        .sidebar {
            position: fixed;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            top: 0;
            bottom: 0;
            width: $sidebar-modal-width-full;
            margin: 0;
            border-top: 1px solid;
            border-bottom: 1px solid;
            -webkit-transform: translateX(-100%);
                    transform: translateX(-100%);
            will-change: transform;
            @include max-width-breakpoint($breakpoint-iphones) {
                width: 100%;
                .form-group {
                    max-width: 100%;
                }
            }
            .sidebar-body {
                bottom: 0;
                overflow-y: auto;
            }
            .sidebar-header {
                display: block;
                height: auto;
                padding-top: 20px;
                padding-bottom: 19px;
            }
            .sidebar-title {
                padding-bottom: 0;
                font-size: 18px;
                color: $body-color;
            }
            & + .main-content {
                -webkit-transform: translateX(0);
                        transform: translateX(0);
                will-change: transform;
            }
            &.is-active + .main-content {
                padding-left: $sidebar-modal-width-full;
                will-change: padding;
            }
            .submenu {
                margin-bottom: 15px;
                margin-left: -20px;
                margin-right: -20px;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid $border-color;
            }
        }
    }
    .modal {
        &-controls {
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                margin-bottom: -15px;
            }
            .btn {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    margin-bottom: 15px;
                }
            }
        }
        .modal-filter-button {
            float: left;
            margin-right: 20px;
            cursor: pointer;
            -webkit-appearance: none;
            @include max-width-breakpoint($breakpoint-iphones){
                margin-bottom: 10px;
            }
        }
        &.is-sidebar-active {
            .sidebar {
                -webkit-transform: translateX(0);
                        transform: translateX(0);
                will-change: transform;
            }
            .modal-content {
                padding-left: $sidebar-modal-width-full;
                will-change: padding;
            }
            .modal-filter-button {
                display: none;
            }
        }
        .modal-dialog {
            .modal-content {
                .main {
                    margin-left: 0;
                }
            }
        }
        &-stages {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            padding: 5px 15px;
            @include max-width-breakpoint($breakpoint-iphones) {
                padding-top: 10px;
            }
            &-item {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                padding: 10px 0;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-flex: 0;
                    -ms-flex-positive: 0;
                        flex-grow: 0;
                opacity: 0.8;
                @include max-width-breakpoint($breakpoint-iphones) {
                    padding: 0;
                }
                &.is-active {
                    opacity: 1;
                    font-weight: bold;
                }
            }
            &-circle {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                width: 30px;
                height: 30px;
                margin-right: 5px;
                border: 1px solid $border-color;
                border-radius: 50%;
            }
            &-name {
                @include max-width-breakpoint($breakpoint-iphones){
                    display: none;
                }
            }
            &-line {
                padding-left: 5px;
                padding-right: 5px;
                -webkit-box-flex: 1;
                    -ms-flex-positive: 1;
                        flex-grow: 1;
                &:before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $border-color;
                }
            }
        }
    }


    .content-title-sort-group{
        .dropdown-menu{
            display: flex !important;
            position: inherit;
            left: inherit;
            top: inherit;
            float: none;
            padding: 0;
            margin: 0;
            border: none;
            border-radius: 0;
            box-shadow: none;
            background-color: transparent;
            margin-left: -15px;
            margin-right: -15px;
            li{
                padding-left: 15px;
                padding-right: 15px;
                a{
                    padding: 0;
                    color: #568FE1;
                    font-size: 14px;
                    &:focus{
                        outline: none;
                    }
                }
                &.selected{
                    display: flex;
                    align-items: center;
                    a{
                        font-weight: bold;
                    }
                    &::after{
                        content: '\f885';
                        font-family: 'Font Awesome 5 Free';
                        font-size: 14px;
                        color: #568FE1;
                        font-weight: bold;
                        margin-left: 5px;
                    }
                }
            }
        }
        .bootstrap-select{
            margin-left: 0;
            & + .bootstrap-select{
                margin-left: 30px;
            }
        }
        .dropdown-toggle{
            display: none !important;
        }
        [data-original-index="1"]{
            &::after{
                transform: scaleY(-1);
            }
        }
    }

    .group-btn-sort{
        list-style-position: none;
        padding: 0;
        margin: 0;
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        li{
            padding-left: 15px;
            padding-right: 15px;
            display: flex;
            align-items: center;
        }
        button{
            padding: 0;
            margin: 0;
            border: none;
            background-color: transparent;
            color: $link-color;
            font-size: 14px;
            position: relative;
            &.active{
                font-weight: bold;
                &::after{
                    //content: '\f885';
                    font-family: 'Font Awesome 5 Free';
                    font-size: 14px;
                    color: $link-color;
                    font-weight: bold;
                    margin-left: 5px;
                    transition: transform .25s ease;
                    display: inline-block;
                }
                &.up{
                    &::after{
                        content: '\f885';
                    }
                }
                &.down{
                    &::after{
                        content: '\f160';
                    }
                }
            }
            &:focus{
                outline: none;
            }
            &:disabled {
                color: $disabled;
                &::after{
                    color: $disabled;
                }
            }
        }
    }
}
.headway-widget {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 100%;
    z-index: 2;
    @include max-width-breakpoint(375px) {
        display: none;
    }
    & > span {
        height: 100% !important;
    }
    #HW_badge.HW_softHidden {
        background: transparent;
    }
    #HW_badge {
        top: 2px
    }
}
.headway-blank-dot {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    background-color: #CD4B5B;
}
.dot-status {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
}
.dd-control-cell {
    position: relative;
    width: 30px !important;
}
.dd-control {
    position: absolute;
    top: 50%;
    padding-left: 10px;
    padding-right: 5px;
    margin-top: -12px;
    font-size: 18px;
    color: $muted-color;
    cursor: pointer;
}

.widget_init_button {
    display: none !important;
}
iframe {
    &[name^="omni_widget_iframe"]{
        &[scrolling=no] {
            display: none !important;
            visibility: hidden !important;
            opacity: 0 !important;
            top: -100000px !important;
            left: -10000px !important;
        }
    }
}

/* emoji-list */
#tab-panel {
    max-height: 190px;
    .ap {
        background-image: none;
        text-indent: 0px;
        font-size: 15px;
        line-height: 1.5;
        &:hover {
            cursor: pointer;
            opacity: 0.8;
        }
    }
}
.ql-editor {
    .ap {
        background-image: none;
        text-indent: 0px;
        font-size: 15px;
        line-height: 1.5;
    }
}