.sidebar-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: $sidbar-menu-full-width;
    background-color: $sidebar-bg;
    z-index: $animation-layers-index + 15;
    @include max-width-breakpoint($breakpoint-desktop-mid) {
        display: none;
        padding-top: $top-menu-height;
        width: 100%;
        &:before {
            content: '';
            display: block;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            width: 100vw;
            height: 100%;
            height: 100vh;
            background-color: inherit;
            z-index: $animation-layers-index - 40;
        }
        &.is-active {
            display: block;
            width: 100% !important;
            z-index: $animation-layers-index - 30;
            zoom: 1;
            -webkit-overflow-scrolling: auto;
        }
    }

    &.is-collapsed {
        width: $sidbar-menu-width;

        & + .sidebar:not(.is-mobile-active) + .main .navbar-body-wrapper,
        & + .sidebar:not(.is-mobile-active) {
            margin-left: $sidbar-menu-width !important;
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                margin-left: 0 !important;
            }
        }

        .user-name,
        .sidebar-menu-text {
            display: none !important;
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                display: block !important;
            }
        }
        .user-avatar {
            left: 0;
        }
        .sidebar-menu-brand {
            .brand-collapsed {
                display: block;
            }

            .brand-expanded {
                display: none;
            }
        }
        .sidebar-menu-item.open {
            @include min-width-breakpoint($breakpoint-desktop-mid) {
                .sidebar-dropdown {
                    margin-left: $sidbar-menu-width;
                }
            }
        }
    }

    & + .sidebar:not(.is-mobile-active) + .main .navbar-body-wrapper,
    & + .sidebar:not(.is-mobile-active) {
        margin-left: $sidbar-menu-full-width;
    }
    .sidebar-menu-top {
        background-color: $header-background-color;
    }
    .sidebar-menu-brand {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 9px;
        padding-right: 9px;
        line-height: 1;
        text-align: center;
        border: none;
        background-color: $header-background-color;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            display: none;
        }
        &:focus {
            background-color: $header-hover-color;
            outline: none;
        }
        .fa {
            vertical-align: middle;
        }
        .brand {
            width: 32px;
            max-width: 100%;
            height: 32px;
            font-size: 21px;
            color: $navbar-link-color;
        }
        .brand-expanded {
            display: block;
            width: auto;
            height: 32px;
        }
        .brand-collapsed {
            display: none;
        }
        &:hover {
            background-color: $dark-color;
        }
    }
    .sidebar-menu-list {
        position: relative;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            z-index: $animation-layers-index;
        }
    }
    .sidebar-menu-item {
        color: $light-color;
        overflow: hidden;
        &:focus,
        &:hover {
            background-color: $sidebar-hover;
        }
        &.active {
            background-color: $sidebar-active;
        }
        &.open {
            .sidebar-dropdown {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                margin-left: $sidbar-menu-full-width;
                @include max-width-breakpoint($breakpoint-desktop-mid) {
                    display: block;
                    margin: 0;
                    width: 100%;
                }
            }
        }
    }
    .sidebar-menu-link {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 10px 11px;
        color: $light-color;
        border: 0;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            padding: 10px 14px;
        }
        &:focus,
        &:hover {
            background-color: $sidebar-hover;
            cursor: pointer;
        }
        &.dropdown-toggle {
            /*iOS fix*/
            display: -webkit-box;
            -webkit-box-orient: horizontal;
        }
        .user {
            width: 100%;
            height: 40px;

            &-avatar {
                left: -2px;
            }

            &-name {
                display: block;
                padding-left: 3px;
                text-align: left;
                color: $muted-grey;
            }
        }
    }
    .sidebar-menu-icon {
        width: 22px;
        font-size: 21px;
        text-align: center;
    }
    .sidebar-menu-text {
        display: block;
        padding-left: 8px;
        font-size: 12px;
    }
    .sidebar-menu-controller {
        color: $muted-grey;
        @include max-width-breakpoint($breakpoint-desktop-mid) {
            display: none;
        }

        .sidebar-menu-icon {
            font-size: 13px;
        }
    }
    .sidebar-menu-last {
        font-size: 10px;
    }
    li {
        &[data-guide-step="help"] {
            .sidebar-header {
                background-color: $sidebar-help;
            }
            ul.sidebar{
                background-color: $sidebar-help;
                li {
                    a {
                        &:hover {
                            background-color: #fff;
                        }
                    }
                }
            }
        }
        &.open {
            .btn-link {
                background-color: #598bc8;
            }
        }
    }
    
}

.sidebar {
    li {
        a {
            &:hover {
                background-color: $sidebar-help;
            }
        }
    }
}