@charset "UTF-8";
.account-pipelines {
    .cell-pipeline-name {
        min-width: 250px !important;
    }
    .cell-pipeline-description {
        width: 100% !important;
    }
    th.text-center {
        text-align: center !important;
    }
}