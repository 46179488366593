@charset "UTF-8";
.synopsis-pipeline {
    .synopsis-pipeline-title {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .synopsis-pipeline-stage {
        margin-bottom: 0;
        padding-bottom: 12px;
        color: #656565;
        line-height: 13px;
        &.synopsis-pipeline-stage-current {
            color: $dark-color;
        }
        &.synopsis-pipeline-stage-done {
            text-decoration: line-through;
        }
    }
    .synopsis-pipeline-tasklist {
        color: #656565;
        &.synopsis-pipeline-tasklist-current {
            color: $dark-color;
            .synopsis-pipeline-task,
            .synopsis-pipeline-task-required {
                .fa-circle {
                    color: $success-color;
                }
            }
        }
    }
    .synopsis-pipeline-task {
        position: relative;
        padding-bottom: 12px;
        padding-left: 15px;
        font-size: 12px;
        line-height: 1;
        .fa-li {
            width: 11px;
            left: 0;
            font-size: 7px;
            text-align: center;
        }
        &.synopsis-pipeline-task-required {
            .fa-li {
                color: #a1a1a1;
            }
        }
        &.synopsis-pipeline-task-done {
            text-decoration: line-through;
            .fa-li {
                top: 0;
                font-size: 11px;
                color: $success-color;
            }
        }
    }
}