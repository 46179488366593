.request-adverts {
    .nothing-found-message-wrapper {
        padding-top: 15px;
        padding-left: 28px;
        padding-right: 28px;
        padding-bottom: 15px;
        clear: both;
    }
    .content-title {
        line-height: 2;
    }
    .adverts-table.table {
        margin-bottom: 130px !important;
        td {
            width: auto;
            
        }
        td{
            &:not(.content-title-wrapper){
                &:last-child{
                    display: none;
                }
            }
        }
        th {
            color: $dark-color;
        }
        th.text-center {
            text-align: center;
        }
        .content-title-wrapper {
            padding-top: 0 !important;
            padding-bottom: 0 !important;

            background-color: $body-background-color;
        }
        .cell-checkbox {
            width: 19px;
            padding-right: 0 !important;
            input[type="radio"], input[type="checkbox"] {
                margin-top: 0;
            }
            .radio, .checkbox {
                margin-top: 0;
                padding-top: 0;
                label > span {
                    padding-left: 0;
                }
                label > span::after,
                label > span::before {
                    top: -7px;
                }
            }
        }
        .cell-updated {
            width: 80px;
            white-space: nowrap;
        }
        .cell-cian-auction-bet {
            width: 100px;
        }
        .cell-status {
            width: 70px;
        }
        .table-input-container {
            .form-control, .btn {
                padding: 3px 6px;
                height: auto;
                min-height: auto;
            }
            .form-control {
                min-width: 80px;
            }
        }
        .edit-button {
            color: $link-color;
            padding: 0px;
            margin: 0px;
            border: none;
            border-bottom: 1px dotted $link-color;
            background: none;
            line-height: inherit;
            font-size: inherit;
            min-height: initial;
            float: none;
            vertical-align: baseline;
            cursor: pointer;
            white-space: nowrap;
            &:disabled {
                cursor: not-allowed;
                color: $muted-color;
                opacity: 1.0;
            }
        }
        .th-images{
            padding: 0 !important;
        }
        .td-images{
            padding-right: 0 !important;
            font-size: 24px;
            color: $disabled;
            text-align: center;
            [data-popup-img]{
                width: 36px;
                height: 36px;
                display: block !important;
                margin-left: auto;
                margin-right: auto;
                &:hover{
                    cursor: pointer;
                }
            }
        }
        .adverts-item-title {
            min-width: 160px;
            @include max-width-breakpoint($breakpoint-notebook-big){
                min-width: 130px;
            }
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                min-width: 160px;
            }
        }
        @include max-width-breakpoint($breakpoint-1800){
            
            th, td{
                padding-left: 18px !important;
                padding-right: 18px !important;
            }
            
        }
        @include max-width-breakpoint($breakpoint-notebook-big){
            th, td{
                padding-left: 13px !important;
                padding-right: 13px !important;
            }
            .cell-updated{
                white-space: normal;
            }
        }
    }
}

.fancybox-toolbar{
    opacity: 1 !important;
    visibility: visible !important;
}