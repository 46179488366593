@charset "UTF-8";
.account-department {
    .form {
        .property-card {
            margin-bottom: 30px;
            &:first-child {
                margin-top: 0;
            }
            .checkbox {
                margin: {
                    top: 4px;
                    bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .form-row {
                margin: {
                    top: 20px;
                    bottom: 15px;
                }
                .control-label {
                    &:first-child {
                        padding-top: 0;
                    }
                }
            }
        }
        .agents-table {
            padding-bottom: 5px;
            .property-table-container {
                margin-top: 0;
                .property-table {
                    margin: {
                        top: 0;
                        bottom: 0;
                    }
                    td {
                        font-weight: normal;
                        cursor: pointer;
                    }
                    tr {
                        &:hover {
                            td {
                                background-color: $body-background-color;
                            }
                        }
                    }
                }
            }
        }
        .property-table {
            th,
            td {
                padding: {
                    left: 20px;
                    right: 20px;
                }
            }
        }
    }
}
