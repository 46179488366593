@charset "UTF-8";
.activity-create {
    .modal-footer {
        margin-top: -14px;
    }
    .description {
        min-height: 175px;
    }
    .fileinput-button {
        &:hover {
            background-color: $primary-color;
            color: $light-color;
        }
    }
}