@charset "UTF-8";
.personal-settings {
    .general-personal-settings-form {
        margin-bottom: 20px;
        .alert {
            margin-bottom: 0px;
        }
    }
    .language-button {
        text-align: left;
    }
    .form {
        hr {
            margin-top: 6px;
        }
    }
    .input-group {
        .form-control.time-textbox {
            width: 50%;
        }
        .btn {
            background-color: white !important;
        }
    }
}