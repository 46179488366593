@charset "UTF-8";
.task-item {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    border-radius: 0;
    background-color: $light-color;
    border-bottom: 1px solid #EEE;
    font-size: 14px;
    @include max-width-breakpoint($breakpoint-tablet){
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
                flex-direction: column;
    }
    .task-type {
        font-style: italic;
    }
    .task-item-result {
        font-style: italic;
    }

    &.task-in-progress {
        .task-item-duration.expired {
            color: $danger-color;
        }
    }
    &.task-closed {
        background-color: #fafafa;
    }
    &.task-disabled {
        background-color: #fafafa;
    }
    .task-item-content {
        padding: {
            top: 13px;
            bottom: 13px;
            left: 12px;
            right: 12px;
        }
        -webkit-box-flex: 1;
                flex: 1 1 auto;
    }
    .task-item-description {
        font-weight: bold;
    }
    .task-item-duration {
        font-style: italic;
    }
    .task-item-agents {
        font-style: italic;
    }
    .task-item-buttons {
        display: none;
        @include max-width-breakpoint($breakpoint-tablet){
            display: -webkit-box;
            display: flex;
            -webkit-box-pack: end;
                    justify-content: flex-end;
            margin-left: -10px;
            padding-bottom: 15px;
            padding-right: 13px;
            .task-item-button{
                display: flex;
                align-items: center;
                justify-content: center;
                width: auto;
                opacity: 1;
                margin-left: 10px;
                min-height: 34px;
                border-radius: 3px;
                padding: 6px 12px;
                &::after{
                    content: attr(title);
                    display: inline-block;
                }
                span{
                    margin-right: 5px;
                }
                &.return-button {
                    background-color: $primary-color;
                }
                &.close-button {
                    background-color: $success-color;
                }
                &.complete-button {
                    background-color: $success-color;
                }
                &.edit-button {
                    background-color: $primary-color;
                };
                &.delete-button {
                    background-color: $danger-color;
                }
            }
        }
        @include max-width-breakpoint($breakpoint-iphones){
            .task-item-button{
                &::after{
                    display: none;
                }
                span{
                    margin-right: 0;
                }
            }
        }
    }
    &:hover {
        .task-item-buttons {
            display: -webkit-box;
            display: flex;
            -webkit-box-flex: 0;
                    flex: 0 1 auto;
            display: -webkit-flex;
        }
    }
    .task-item-row {
        &:not(:first-child) {
            margin-top: 10px;
        }
    }
    .task-item-button {
        margin: 0px;
        height: 100%;
        display: block;
        border: 0px;
        width: 34px;
        background-color: #000;
        opacity: 0.25;
        color: #FFF;
        &:hover {
            opacity: 1.0;
            &.return-button {
                background-color: $primary-color;
            }
            &.close-button {
                background-color: $success-color;
            }
            &.complete-button {
                background-color: $success-color;
            }
            &.edit-button {
                background-color: $primary-color;
            };
            &.delete-button {
                background-color: $danger-color;
            }

        }
        
    }
    .task-item-buttons{
        &.task-group__buttons{
            display: flex;
            position: inherit;
            left: inherit;
            top: inherit;
            width: 100%;
            padding: 0 10px 10px;
            @include max-width-breakpoint($breakpoint-tablet) {
                padding-right: 0;
                padding-left: 0;
                button{
                    margin-right: 0 !important;
                    margin-left: 10px;
                }
            }
            button{
                margin-right: 10px;
            }
        }
    }

    .task-item-fileslist {
        padding-top: 10px;
    }

    &:before {
        content: '';
        position: absolute;
        width: 4px;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
    }

    &.task-red:before {
        background-color: $danger-color;
    }
    &.task-green:before {
        background-color: $success-color;
    }
    &.task-orange:before {
        background-color: $warning-color;
    }
    &.task-grey:before {
        background-color: $lighter-secondary-color;
    }

    .task-status {
        font-style: italic;
        float: right !important;
    }

    .task-status-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;

        &.task-status-circle-green {
            background: $success-color;
        }
        &.task-status-circle-orange {
            background: $warning-color;
        }
        &.task-status-circle-gray {
            background: $lighter-secondary-color;
        }
    }
}