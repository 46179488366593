@charset "UTF-8";
.advert-pictures {
    .pictures-container {
        @include clearfix;
        padding: {
            top: 15px;
            right: 0;
            bottom: 20px;
            left: 28px;
        }
    }
    .picture-item {
        position: relative;
        display: block;
        float: left;
        width: 15.7196969%;
        height: 210px;
        margin: {
            bottom: 20px;
            right: 0.9369696%;
        }
        zoom: 1;
        border-radius: 3px;
        background-color: #eee;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        @include max-width-breakpoint($breakpoint-desktop-wide) {
            width: 23.3443708%;
            margin-right: 1.555629%;
        }
        @include max-width-breakpoint($breakpoint-desktop) {
            width: 31.5972222%;
            margin-right: 1.645629%;
        }
        @include max-width-breakpoint($breakpoint-pad-portrait) {
            width: 47%;
            height: 200px;
            margin-right: 2.9%;
        }
        @include max-width-breakpoint($breakpoint-iphones) {
            width: calc(100% - 20px);
            height: 200px;
            margin-right: 20px;
        }
        .picture-item-control {
            width: 100%;
            height: 100%;
            cursor: pointer;
        }
    }
}
.fancybox-thumbs-x {
    top: auto;
    width: auto;
    bottom: 0;
    left: 0;
    right : 0;
    height: 95px;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.3);
}

.fancybox-show-thumbs .fancybox-inner {
    right: 0;
    bottom: 95px;
}
.fancybox-thumbs > ul > li:before {
    border-color: $primary-color;
}