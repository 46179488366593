@charset "UTF-8";
.request {
    .form.request-form {
        max-width: 1082px;
        padding: {
            top: 4px;
            left: 28px;
            right: 28px;
            bottom: 15px;
        }
        @include clearfix;
        @include max-width-breakpoint($breakpoint-iphones) {
            padding: {
                top: 0;
                left: 20px;
                right: 20px;
            }
        }
        hr {
            margin: {
                right: 10px;
                top: 5px;
            }
        }
        .btn-add-client {
            padding: {
                top: 6px;
                bottom: 6px;
                left: 10px;
                right: 10px;
            }
            float: right;
            font-size: 14px;
            line-height: 1.43;
        }
        .description {
            max-width: 100%;
        }
        .customer-search-select {
            @include max-width-breakpoint($breakpoint-iphones) {
                max-width: 238px;
            }
        }
        .card-item {
            margin-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: none;
        }
        .search-input {
            margin-bottom: 15px;
        }
    }
    .panel-filter {
        .panel-heading {
            .action {
                display: block;
                float: right;
                margin: {
                    top: 9px;
                    right: 0;
                    bottom: 5px;
                    left: 8px;
                }
            }
        }
        hr {
            margin: {
                top: 0;
                bottom: 0;
            }
        }
        .head {
            margin-top: -5px;
            font-size: 14px;
            color: #909090;
        }
    }
    .customer-forbidden {
        padding: 12px;
        margin-bottom: 0px;
        border: 1px solid #faebcc;
        border-radius: 4px;
        font-size: 13px;
        color: #8a6d3b;
        background-color: #fcf8e3;
    }
}
