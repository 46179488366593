@charset "UTF-8";
.apartment {
    .form {
        .button-separator {
            margin-top: 6px;
        }
        .textarea {
            margin-top: 15px;
        }
    }
    .built-in-year-container {
        width: 60px;
        float: left;
    }
    .built-in-quarter-container {
        width: 117px;
        margin-left: 15px;
        float: left;
    }
    .panel-separator {
        margin: {
            top: 0;
            bottom: 0;
        }
    }
    .map {
        height: 450px;
        margin: {
            left: 0;
            right: 0;
            top: 0;
        }
        & + .property-card {
            margin-top: -5px;
        }
    }
    .address-is-ok-button {
        margin-left: 10px;
    }
    .update-address-button{
        margin-left: 10px;
    }
    .do-not-update-address-button{
        margin-left: 5px;
    }
}
