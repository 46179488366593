@charset "UTF-8";

.account-reject-reasons {
    .reject-reasons-table {
        .cell-checkbox {
            width: 19px !important;
            padding-right: 0 !important;
            input[type="radio"], input[type="checkbox"] {
                margin-top: 0;
            }
            /*
            .radio, .checkbox {
                margin-top: 0;
                padding-top: 0;
                label > span {
                    padding-left: 0;
                }
                label > span::after,
                label > span::before {
                    top: -7px;
                }
            }
            */
        }
    }
}