@charset "UTF-8";
.create-simple-subtask {
    .create-simple-subtask-deadline-date-textbox {
        width: 65% !important;
    }
    .create-simple-subtask-deadline-time-textbox {
        width: 35% !important;
    }
    .files-list {
        .file-name {
            padding-top: 10px;
            display:     inline-block;
        }
        .file-item {
            height: 30px;
        }
        .delete-file-button {
            display: none;
            min-height: 30px;
        }
        .file-item:hover .delete-file-button {
            display: inline-block;
        }
    }
    .description {
        box-sizing: border-box;
        min-height: 60px;
        max-height: 150px;
    }
}
