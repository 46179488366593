@charset "UTF-8";
a:hover, a:visited, a:focus, .btn-link:hover, .btn-link:focus {
    text-decoration: none!important;
}
/* Remove all shadows */
input {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
    &.datetimepicker {
        &[readonly]:not([disabled]) {
            background-color: #fff !important;
        }
    }
}

textarea {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}

.filedset-group{
    margin-bottom: 20px;
}

.well {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}

.panel {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}
.bootstrap-tagsinput {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}
.modal {
    overflow-y: auto;
}
.modal-content {
    -webkit-box-shadow:none!important;
    box-shadow: none!important;
}
.modal-open {
    overflow: hidden !important;
    padding-right: 0 !important;
}
.modal-footer .btn+.btn {
    @include min-width-breakpoint($breakpoint-iphones){
        margin-left: 20px;
    }
}
.btn {
    -webkit-box-shadow:none!important;
    box-shadow:none!important;
    .active {
        -webkit-box-shadow:none!important;
        box-shadow:none!important;
    }
}
.btn-mobile-full {
    @media screen and (max-width: 424px){
        margin-top: 15px;
        width: 100%;
        float: none;
        clear: both;
    }
}
.toggle-handle {
    border-width: 0px;
}

/* Forms and tables tuning */
.panel {
    .panel-body {
        padding-bottom: 0px;
    }
    tbody {
        border-bottom: none!important;
    }
}
.table {
    td, th {
        padding: {
            top: 9px !important;
            right: 8px !important;
            bottom: 8px !important;
            left: 8px !important;
        }
        .checkbox {
            margin: {
                top: 2px;
                bottom: 0;
            }
            label:after {
                margin-left: -21px;
            }
        }
        .radio {
            margin: {
                top: 2px;
                bottom: 0;
            }
        }
    }
}
.form-vertical {
    .form-group {
        .form-control {
            padding: {
                top: 7px;
                right: 12px;
                bottom: 6px;
                left: 12px;
            }
            font-size: 13.8px;
        }
        .control-label {
            padding: {
                left: 15px;
                right: 0;
            }
            font-size:      12px;
            color:          rgb(70, 70, 70);
            text-align:     left;
            white-space:    nowrap;
        }
    }
}
.form-horizontal {
    .form-group {
        .form-control {
            padding: {
                top: 7px;
                right: 12px;
                bottom: 6px;
                left: 12px;
            }
            font-size: 13.8px;
        }
        .control-label {
            padding: {
                left: 15px;
                right: 0;
            }
            font-size:      12px;
            color:          rgb(70, 70, 70);
            text-align:     left;
            white-space:    nowrap;
        }
        .two-lines {
            white-space: normal;
            padding-top: 1px;
            margin-top:  0px;
        }
    }
    .table {
        th, td {
            padding: {
                top: 6px !important;
                right: 8px !important;
                bottom: 6px !important;
                left: 8px !important;
            }
            .checkbox {
                margin: {
                    top: 0px;
                    bottom: 0;
                }
                padding-top:   5px;
                label:after {
                    margin-left: -21px;
                }
            }
            .radio {
                margin: {
                    top: 0px;
                    bottom: 0;
                }
                padding-top:   5px;
            }
        }
    }
}

.has-warning {
    .control-label {
        color: #8a6d3b!important;
    }
    .bootstrap-select {
        button {
            border-color: #8a6d3b!important;
            span {
                color: #8a6d3b!important;
            }
        }
    }
    .input-group {
        input {
            color: #8a6d3b!important;
        }
    }
    .form-control {
        color: #8a6d3b!important;
        border-color: #8a6d3b!important;
    }
    .help-block {
        color: #8a6d3b!important;
    }
    .input-group-btn {
        button {
            color: #8a6d3b!important;
            border-color: #8a6d3b!important;
        }
    }
}

.has-error {
    .control-label {
        color: #b94a48!important;
    }
    .bootstrap-select {
        button {
            span {
                color: #b94a48!important;
            }
        }
    }
    .input-group {
        input {
            color: #b94a48!important;
        }
    }
    .form-control {
        color: #b94a48!important;
        border-color: #b94a48!important;
    }
    .help-block {
        color: #b94a48!important;
    }
    .input-group-btn {
        button {
            color: #b94a48!important;
            border-color: #b94a48!important;
        }
    }
}
.has-success {
    .control-label {
        color: #3c763d!important;
    }
    .bootstrap-select {
        button {
            span {
                color: #3c763d!important;
            }
        }
    }
    .input-group {
        input {
            color: #3c763d!important;
        }
    }
    .form-control {
        color: #3c763d!important;
        border-color: #3c763d!important;
    }

    .help-block {
        color: #3c763d!important;
    }
    .input-group-btn {
        button {
            color: #3c763d!important;
            border-color: #3c763d!important;
        }
    }
}
.help-block {
    margin: {
        top: 2px;
        bottom: -2px;
    }
    font-size: 13px;
}

.alert-sm {
    font-size: 13px;
    padding: 10px;
}

.checkbox {
    &.text-free {
        padding: 0;
        vertical-align: top;
        line-height: 1;
        label {
            font-size: 0;
            vertical-align: top;
            line-height: 1 !important;
        }
        input {
            display: none;
        }
        span {
            padding: 0;
        }
    }
}


.form-control-static {
    padding-top: 6px;
}

.bootstrap-tagsinput {
    width:100%;
}

.required-asterisk {
    color: red;
    font-weight: bold;
}

/* Recrm table */
.recrm-pager{
    .recrm-table-wrapper{
        .recrm-table{
            td {
                .text-right{
                    text-align: right;
                }
                &.pl-25{
                    padding-left: 25px !important;
                }
                &.pr-25{
                    padding-right: 25px !important;
                }
            }
        }
    }
}
.recrm-table {
    thead {
        border-bottom: none;
        tr {
            border-top: none;
            td {
                text-align: center;
                font-weight: bold;
                border-bottom-width: 1px;
            }
            th {
                text-align: center;
                font-weight: bold;
                border-bottom-width: 1px;
            }
        }
    }
    tbody {
        border-bottom: 1px solid $border-color;
    }
    td {
        vertical-align: middle!important;
        font-size: 12px;
    }
    th {
        vertical-align: middle!important;
        font-size: 12px;
    }
    .justify-content-end{
        justify-content: flex-end;
    }
    
}

/* Pretty callouts */
.bs-callout {
    font-size: 13px;
    padding:  13px;
    margin: {
        top: 0;
        bottom: 10px;
        left: 0;
        right: 0;
    }
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
    h4 {
        margin: {
            top: 0;
            bottom: 5px;
        }
        font-size: 16px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}
.bs-callout-danger {
    border-left-color: #ce4844 !important;
    h4 {
        color: #ce4844;
    }
}
.bs-callout-info {
    border-left-color: $info-alter-color !important;
    h4 {
        color: $info-alter-color;
    }
}
.bs-callout-warning {
    border-left-color: #aa6708 !important;
    h4 {
        color: #aa6708;
    }
}
.bs-callout-success {
    border-left-color: #3c763d !important;
    h4 {
        color: #3c763d;
    }
}
/* Some necessary stuff */
code {
    font-size: 80%;
}
.center {
    text-align: center;
}
.red {
    background-color: red!important;
}
input[type=checkbox]:hover, input[type=radio]:hover {
    cursor: pointer;
}
input[type=checkbox]:disabled, input[type=radio]:disabled {
    cursor: not-allowed;
}
.bad {
    color: red;
}
.bold {
    font-weight: bold;
}
.nowrap {
    white-space: nowrap;
}
.left {
    text-align: left!important;
}
.right {
    text-align: right!important;
}
.cursor-pointer {
    cursor: pointer;
}
.pagination {
    li {
        cursor: pointer;
    }
    .disabled {
        cursor: none;
    }
}
/* Хак для Google.Maps */
.pac-container, .pac-item{
    z-index: 2147483647 !important;
}
.row-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.row-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
}
.column-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.table-card {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(163,163,163,1);
    box-shadow:         0px 0px 8px 0px rgba(163,163,163,1);
    margin: {
        top: 10px;
        bottom: 20px;
        left: 10px;
        right: 10px;
    }
    .recrm-pager {
    }
}

/* Здесь возник конфликт. Возможно, этот код перенесен, или больше не нужен - проверить */
.card {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(163,163,163,1);
    box-shadow:         0px 0px 8px 0px rgba(163,163,163,1);
    padding: {
        top: 15px;
        bottom: 15px;
        left: 15px;
        right: 20px;
    }
    margin: 10px;
    .header {
        margin: {
            top: 0px;
            bottom: 5px;
        }
        .header-title {
            margin-left: 5px;
        }
    }
}
/* Конец фрагмента кода с конфликтом */

.info-table {
    width: 100%;
    .bottom-border {
        border-bottom: 1px solid #eee;
    }
    .danger {
        color: #d9534f;
    }
    .warning {
        color: #f0ad4e;
    }
    .title {
        width: 120px;
        padding: {
            top: 8px;
            bottom: 8px;
        }
        font-size: 12px;
        color: #a0a0a0;
        white-space: nowrap;
        vertical-align: middle;
    }
    .value {
        padding: {
            top: 8px;
            bottom: 8px;
            left: 10px;
        }
        font-size: 12px;
    }
    ul {
        margin: {
            left: 0;
            bottom: 0;
        }
        padding-left:  0px;
        font-size: 12px;
        list-style: none;
        li {
            font-size: 12px;
            margin-left: 0px;
        }
        li::before {
            content: "\2013";
        }
    }
}

.base-master {
    .files-list {
        padding: {
            left: 5px;
            right: 5px;
        }
        border-radius: 3px;
        background-color: $light-color;
    }
    .files-list-item {
        padding-top: 10px;
        line-height: 1;
        &:last-child {
            padding-bottom: 10px;
        }
    }
    .breadcrumb {
        display: inline-block;
        clear: both;
        margin-bottom: 30px;
        background-color: $light-color;
        .breadcrumb-item {
            font-size: 14px;
            line-height: 1.71;
            color: #8f76dc;
        }
        .active {
            color: #e2e1e0;
        }
    }
    .recrm-table {
        border-top: 1px solid $border-color;
        margin-bottom: 12px;
        &-wrapper {
            @include max-width-breakpoint($breakpoint-pad-landscape) {
                overflow-y: auto;
            }
        }
        tr:hover td {
            background: $td-hover !important;
        }
        tbody,
        thead,
        tfoot {
            tr {
                th,
                td {
                    padding: {
                        top: 16px !important;
                        bottom: 16px !important;
                        left: 25px !important;
                        right: 25px !important;
                    }
                    @include max-width-breakpoint($breakpoint-pad-portrait) {
                        padding: {
                            left: 18px !important;
                            right: 18px !important;
                        }
                    }
                }
                th {
                    background-color: $body-background-color;
                    text-align: left;
                    line-height: 17px;
                }
                td {
                    background-color: $light-color;
                }
            }
        }
        .disabled {
            color: #cccccc !important;
        }
    }
    .recrm-table-wrapper {
        width: 100%;
        max-width: 100%;
        overflow-y: auto;
        &.fix-sidebar {
            width: calc(100vw - #{$sidebar-width-full});
            @include max-width-breakpoint($breakpoint-desktop-mid) {
                width: 100%;
            }
        }
    }
    .base-master-body {
        .visit-card {
            padding-top: 18px;
            .panel-body {
                padding: {
                    top: 5px;
                    right: 0;
                    left: 0;
                }
                .col-half {
                    padding: {
                        left: 20px;
                        right: 20px;
                    }
                }
                .row:first-child {
                    margin-bottom: 20px;
                }
            }
            .form-row {
                margin: {
                    top: 20px;
                    bottom: 15px;
                }
                .control-label {
                    &:first-child {
                        padding-top: 0;
                    }
                }
            }
        }
        .period-title,
        .unit-title {
            color: $unit-color;
        }
        .map {
            @mixin map {
                width: 100%;
                max-width: $breakpoint-pad-landscape;
                border-radius: 5px;
                border: 1px solid $border-color;
                overflow: hidden;
            }
            &-message {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                position: absolute;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 10px;
                background-color: rgba($header-hover-color, 0.3);
                color: $body-background-color;
                font-size: 3rem;
                text-align: center;
                vertical-align: middle;
                opacity: 0;
                visibility: hidden;
                -webkit-transition: opacity 300ms ease-in;
                transition: opacity 300ms ease-in;
                @include max-width-breakpoint($breakpoint-iphones) {
                    font-size: 2rem;
                }
            }
            &-static {
                height: auto !important;
            }
            &-image {
                width: 100%;
            }
            &-image-wrapper {
                position: relative;
                height: 100%;
                @include map;
                &.no-hover {
                    &:hover {
                        cursor: default;
                    }
                }
                &:hover {
                    cursor: pointer;
                    .map-message {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
            @include map;
            height: 400px;
        }
        .form {
            max-width: $breakpoint-pad-landscape + 59px;
            padding: {
                top: 0;
                bottom: 20px;
                left: 30px;
                right: 28px;
            }
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                padding: {
                    top: 0;
                    bottom: 20px;
                    left: 20px;
                    right: 20px;
                }
            }
            clear: both;
            .checkbox-inline {
                margin-top: 0;
            }
            .form-group {
                max-width: 320px;
                .form-group {
                    margin: 0;
                }
            }
            .form-row {
                margin-top: 18px;
                &:first-child {
                    margin-top: 0;
                }
                &:last-child {
                    margin-bottom: 18px;
                }
            }
        }
        .form-control-map {
            margin: {
                top: 10px;
                bottom: 10px;
            }
        }
        .result-item-pager {
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                .pager-button {
                    font-size: 0;
                    .fa {
                        font-size: 14px;
                        margin: {
                            right: 0;
                            left: 0;
                        }
                    }
                }
            }
        }
        .property-card {
            margin: {
                top: 5px;
                bottom: 30px;
            }
            @include custom-bs-grid;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                margin: {
                    top: 30px;
                    bottom: 0px;
                }
            }
            .panel-separator {
                margin: {
                    top: 30px;
                    bottom: 15px;
                }
                & + .checkbox-list {
                    padding-top: 0;
                    margin-top: -10px;
                }
            }
            .input-group-split {
                .form-control {
                    width: 90px;
                }
            }
            .control-label {
                padding: {
                    top: 15px;
                    bottom: 5px;
                }
                margin: 0;
                @include max-width-breakpoint($breakpoint-pad-portrait) {
                    padding: {
                        top: 15px;
                        bottom: 5px;
                    }
                }
                .form-control-feedback {
                    top: 40px;
                    right: 0;
                }
            }
            .has-feedback:not(.form-row) {
                .control-label ~ .form-control-feedback {
                    top: 40px;
                    right: 0;
                }
            }
            .checkbox-list {
                .checkbox {
                    padding-top: 20px;
                }
            }
        }
        .property-card-sub {
            margin-bottom: 15px;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                margin-top: 15px;
                .panel-separator {
                    padding-top: 15px;
                }
            }
        }
        .variants-table,
        .property-table {
            width: 100%;
            margin: {
                top: 22px;
                bottom: 5px;
            }
            thead {
                th {
                    padding: {
                        top: 17px;
                        bottom: 16px;
                        left: 10px;
                        right: 10px;
                    }
                    font: {
                        size: 12px;
                        weight: bold;
                    }
                    line-height: 1.4;
                    color: $dark-color;
                }
            }
            tbody {
                td {
                    padding: {
                        top: 17px;
                        bottom: 16px;
                        left: 10px;
                        right: 10px;
                    }
                    font: {
                        size: 12px;
                    }
                    line-height: 1.4;
                    border-top: 1px solid $border-color;
                    &:first-child {
                        font-weight: bold;
                    }
                }
            }
        }
        .variants-table,
        .property-table,
        .recrm-table {
            .bootstrap-select {
                padding-right: 10px;
                min-width: 100px;
            }
            .dropdown-toggle.btn {
                padding: {
                    left: 0;
                    top: 0;
                    bottom: 0;
                }
                border: 0;
                -webkit-box-shadow: none;
                        box-shadow: none;
                font: {
                    size: 14px;
                    weight: 500;
                }
                color: $body-color;
                &:hover,
                &:focus {
                    background-color: $light-color;
                    outline: none;
                }
            }
        }
        .recrm-table {
            &.recrm-table-clickable {
                tr:hover td {
                    cursor: pointer;
                }
            }
        }
        .property-table-container {
            width: 100%;
            max-width: 100%;
            height: 100%;
            background-color: $light-color;
            border: solid 1px #cccccc;
            border-radius: 3px;
            @include max-width-breakpoint($breakpoint-pad-portrait) {
                overflow-x: auto;
            }
            .bootstrap-select.btn-group .dropdown-menu {
                position: absolute;
                &.inner {
                    position: relative;
                }
            }
        }

    }
}

a {
    &.visitable {
        &:visited{
            color: $alter-color;
            * {
                color: $alter-color;
            }
        }
    }
    &.btn {
        padding: 6px 12px !important;
    }
}

.text-red {
    color: $danger-color;
}
.text-green {
    color: $success-color;
}
.text-blue {
    color: $primary-color;
}
.text-cyan {
    color: $info-color;
}
.text-orange {
    color: $warning-color;
}
.text-grey {
    color: $secondary-color;
}

.bg-red {
    background-color: $danger-color;
}
.bg-green {
    background-color: $success-color;
}
.bg-blue {
    background-color: $primary-color;
}
.bg-cyan {
    background-color: $info-color;
}
.bg-orange {
    background-color: $warning-color;
}
.bg-grey {
    background-color: $secondary-color;
}

.valign-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.modal-footer{
    .btn-outline-secondary{
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 0;
        padding: 0;
        border: none;
        width: 25px;
        height: 25px;
        &::before{
            content: '\f00d';
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%,-50%);
                    transform: translate(-50%,-50%);
            font-size: 14px;
            color: #9d9d9d;
        }
        &:hover{
            background-color: transparent !important;
            color: #9d9d9d !important;
        }
    }
    .btn{
        &:not(.btn-outline-secondary){
            @include max-width-breakpoint($breakpoint-iphones){
                width: 100%;
                margin-bottom: 15px;
                float: none !important;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        @include max-width-breakpoint($breakpoint-iphones){
           & + .btn{
               margin-left: 0;
           }
        }
    }
}

.modal-title{
    padding-right: 20px;
}

.has-feedback{
    .form-control{
        @include max-width-breakpoint($breakpoint-iphones){
            padding-right: 10px;
        }
    }
}

.item-images-lot{
    img{
        position: absolute;
        transform: translate(40px, calc(-66% - 36px));
        display: none;
        z-index: 99999;
    }
    .top-offset{
        transform: translate(40px, 0) !important;
    }

}

.guide-start{

    %glow {
        box-shadow: 0 0 10px 0px rgba(255,255,255,.5) !important;
    }

    .introjs-tooltiptext{
        font-size: 12px;
        line-height: 14px;
        min-width: 270px;
        h4{
            margin-top: 0;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 10px;
            font-weight: bold;
        }
        b{
          &:last-child{
            color: $success-color;
          }
        }
    }

    .sidebar-menu{
        &.introjs-fixParent{
            z-index: 99999999 !important;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,.5);
                z-index: 10;
            }

            ul{
                li{
                    opacity: 0.2;
                    z-index: -1;

                    &.introjs-showElement{
                        opacity: 1 !important;
                        background-color: $primary-color;
                        @extend %glow;
                    }
                }
            }
        }
    }

    .side-bar-menu__guide-fixed{
        opacity: 0;

        & + .introjs-fixedTooltip {

            .introjs-helperNumberLayer{
                left: 10px;
            }
        }
    }

    .sidebar-filter{
        &.introjs-fixParent{
            z-index: 99999999 !important;
            position: relative;
            border-right: none !important;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,.5);
                z-index: 10;
            }

            .sidebar-header{
                position: relative !important;
            }

            .sidebar-close {
                &.introjs-showElement {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    background-color: #fff;
                    align-items: center;
                    justify-content: center;
                    @extend %glow;
                }
            }
        }

    }

    .sidebar-toggler{
        &.introjs-showElement{
            @extend %glow;
        }
    }

    .side-bar-guide__filter{
        &.introjs-fixParent{
            z-index: 99999999 !important;
            position: relative;
            border-right: none !important;
            &::before{
                display: none !important;
            }

            .sidebar-header{
                position: relative !important;
            }

            .sidebar-close {
                &.introjs-showElement {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    background-color: #fff;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }


    .side-bar-filter__guide-fixed{
        opacity: 0;

        &::before{
            display: none !important;
        }
    }

    .side-bar__guide-fixed{
        opacity: 0;

        & + .introjs-tooltipReferenceLayer {

            .introjs-helperNumberLayer{
                left: 20px;
                top: -4px;
            }
        }
    }

    .side-bar-open__guide-fixed{
        opacity: 0;

        & + .introjs-tooltipReferenceLayer {

            .introjs-helperNumberLayer{
                top: 13px;
                left: -34px;
            }
        }
    }

    .top-menu{
        &.introjs-fixParent{
            z-index: 9999999 !important;
            position: relative;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: rgba(0,0,0,.5);
                z-index: 10;
            }
        }
    }

    .sorting__guide-fixed{
        @extend %glow;
    }

    .introjs-button{
        &[data-disabled]{
            pointer-events: none;
            position: relative;
            opacity: 0.5;
            display: none !important;

            &::before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
            }
        }
    }

    .introjs-tooltip{
        border-radius: 5px;
        padding: 10px 15px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
    }

    .introjs-prevbutton{
        display: none !important;
    }

    [data-guide-step=sorting]{
        &.introjs-showElement{
            background-color: $body-background-color;
            z-index: 999999999 !important;
        }
    }

    .sorting__guide-fixed{
        & + .introjs-tooltipReferenceLayer{
            .introjs-helperNumberLayer{
                left: -35px;
            }
        }
    }

    .adverts__guide-fixed{
        & + .introjs-tooltipReferenceLayer{
            .introjs-helperNumberLayer{
                left: 11px;
            }
        }
    }


    .introjs-helperLayer.introjs-fixedTooltip{
        opacity: 0;
    }

    .adverts-checkbox__guide-fixed{
        opacity: 0 !important;
        & + .introjs-tooltipReferenceLayer{
            margin-left: 20px;
        }
    }

    .communications-checkboxes__guide-fixed,
    .adverts-checkboxes__guide-fixed{
        opacity: 0;
    }

    .adverts{
        .adverts-table.table{
            .cell-checkbox{
                &.introjs-showElement{
                    padding-right: 20px !important;
                }
            }
            .highlight-guide{
                position: relative;
                z-index: 9999999 !important;
                @extend %glow;
            }
        }
    }

    .communications{
        .communications-table.table{
            .cell-checkbox{
                padding-right: 20px !important;
            }
            .highlight-guide{
                position: relative;
                z-index: 9999999 !important;
            }
        }
    }



    .navbar-body-wrapper{
        &.introjs-fixParent{
            z-index: 1000000 !important;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                background-color: rgba(0,0,0,.7);
            }
            ul{
                li{
                    position: relative;
                    z-index: 20;
                    button{
                        background-color: $light-color;
                        @extend %glow;
                    }
                }
            }
        }
        &.highlight-guide{
            z-index: 1000000;
            &::before{
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 1;
                background-color: rgba(0,0,0,.7);
            }
            ul{
                li{
                    position: relative;
                    z-index: 20;
                    button{
                        background-color: $light-color;
                        @extend %glow;
                    }
                }
            }
        }
    }
    .introjs-overlay{
        background: rgba(0,0,0,.9);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
    }
    .notifications-holder{
        z-index: 9999999 !important;
    }

    .toggle-menu__guide-fixed,
    .profile__guide-fixed,
    .settings__guide-fixed,
    .help__guide-fixed,
    .reports__guide-fixed,
    .tasks__guide-fixed,
    .communications__guide-fixed{
        & + .introjs-fixedTooltip{
            .introjs-helperNumberLayer{
                top: -21px;
                left: 11px;
            }
        }
    }

    .logo__guide-fixed{
        z-index: 99999999;

        & + .introjs-fixedTooltip{
            .introjs-helperNumberLayer{
                top: 50px;
                left: 10px;
            }
        }
    }
    .sidebar-menu-brand{
        &.introjs-showElement{
            @extend %glow;
        }
    }

    .search-icon__guide-fixed{
        z-index: 9999999;
        opacity: 0;

        & + .introjs-tooltipReferenceLayer{
            .introjs-helperNumberLayer{
                top: 16px;
                left: -27px;
            }
        }
    }

    .search-input__guide-fixed{
        opacity: 0;
        z-index: 9999999;
        & + .introjs-tooltipReferenceLayer{
            .introjs-helperNumberLayer{
                top: 7px;
                left: -37px;
            }
        }
    }

    .create-button__guide-fixed{
        opacity: 0;
        z-index: 9999999;
        & + .introjs-tooltipReferenceLayer{
            .introjs-helperNumberLayer{
                top: 6px;
                left: -35px;
            }
        }
    }

    .profile__guide-fixed,
    .help__guide-fixed,
    .settings__guide-fixed{
        opacity: 0;
        z-index: 99999999;
    }

    .modal{
        z-index: 999999999 !important;
    }

    .search__parent{
        position: relative;
        z-index: auto !important;
        & + .wrapper {
            .sidebar-header{
                margin-top: -50px !important;
            }
        }
        .form-control,
        .search-variants-button{
            background-color: $header-background-color !important;
            @extend %glow;
        }
    }

    .introjs-nextbutton{
        &.introjs-disabled{
            display: none !important;
        }
    }

    .navbar-nav{
        &.highlight-guide{
            li{
                z-index: auto !important;
            }
        }
    }
    .sidebar{
        &.highlight-guide{
            z-index: 9999999 !important;
            &::before{
                content: '';
                width: calc(100% + 1px);
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                background: rgba(0,0,0,.6);
            }
            .sidebar-header{
                &.introjs-fixParent{
                    z-index: 10 !important;
                }
            }
        }
    }

    .up-filter-button__guide-fixed{
        opacity: 0;
        z-index: 9999999;
        & + .introjs-tooltipReferenceLayer{
            .introjs-helperNumberLayer{
                top: -4px;
                left: -30px;
            }
        }
    }

    .sidebar-nav-item{
        &.introjs-showElement{
            background-color: $light-color;
        }
        &.active{
            .sidebar-nav-button.introjs-showElement{
                background-color: $primary-color;
                @extend %glow;
            }
        }
        .sidebar-nav-button.introjs-showElement{
            background-color: $light-color;
        }
    }

    [data-guide-step="create-button"]{
        &.introjs-showElement{
            @extend %glow;
        }
    }

    .z-auto{
        z-index: auto !important;
    }

    %center{
        left: 50% !important;
        top: 50% !important;
        right: inherit !important;
        bottom: inherit !important;
        transform: translate(-50%,-50%);
    }

    .center__guide-fixed{
        @extend %center;
        min-width: 350px;
        opacity: 0;
        .introjs-arrow{
            display: none !important;
        }
        & + .introjs-tooltipReferenceLayer{
            left: 50% !important;
            top: 50% !important;
            transform: translate(-50%,-50%);
            .introjs-helperNumberLayer{
                display: none !important;
            }
        }
    }
    .center__guide{
        @extend %center;
        min-width: 350px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        .introjs-arrow{
            display: none !important;
        }
    }

    .finish__guide-fixed{
        & + .introjs-fixedTooltip{
            @extend %center;
            .introjs-helperNumberLayer{
                display: none !important;
            }
        }
    }

    .finish__guide{
        @extend %center;
        min-width: 350px;
        .introjs-arrow{
            display: none !important;
        }
    }

    .sidebar-menu-item{
        &.introjs-showElement{
            &.open{
                ul > li{
                    opacity: 1 !important;
                    z-index: 1 !important;
                }
            }
        }
    }

    .introjs-tooltipbuttons{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .introjs-button{
            display: inline-block;
            margin-bottom: 0;
            font-weight: normal;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            border: 1px solid $success-color;
            padding: 6px 12px;
            font-size: 12px;
            line-height: 1.42857143;
            border-radius: 4px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            box-sizing: border-box;
            text-shadow: none;
            margin-right: 10px;
            & + .introjs-button{
                margin-right: 0;
                margin-left: 10px;
            }
            &:focus{
                outline: none;
            }
        }
        .introjs-donebutton,
        .introjs-nextbutton{
            color: $light-color;
            background-color: $success-color;
            &:hover{
                background-color: darken($success-color, 5%);
                border-color: darken($success-color, 10%);
            }
            &:active{
                background-color: darken($success-color, 15%);
                border-color: darken($success-color, 10%);
            }
        }
        .introjs-skipbutton:not(.introjs-donebutton){
            background-color: transparent;
            color: #fff;
            border: none;
            position: absolute;
            bottom: -11px;
            left: 50%;
            transform: translateX(-50%) translateY(100%);
            &::before{
                content: '\f00d';
                font-weight: 900;
                font-family: 'Font Awesome 5 Free';
                color: #fff;
                display: inline-block;
                margin-right: 7px;
            }
            &:hover{
                color: #fff;
                background-color: transparent;
                opacity: 0.8;
                box-shadow: none;
            }
            &:active{
                color: #fff;
                background-color: transparent;
                opacity: 0.8;
                box-shadow: none;
            }
        }
    }

    .introjs-arrow{
        border: none;
        &::before{
            font-weight: 900;
            font-family: 'Font Awesome 5 Free';
            color: #ffffff;
            font-size: 23px;
            margin-left: 9px;
        }
        &.left{
            &::before{
                content: '\f0d9';
            }
        }
        &.top-middle,
        &.top-right,
        &.top{
            &::before{
                content: '\f0d8';
            }
        }
        &.right{
            &::before{
                content: '\f0da';
            }
        }
    }

    .introjs-overlay{
        & ~ .introjs-overlay{
            display: none !important;
        }
    }

    %step-position-left{
        margin-left: 10px;
        margin-top: 5px;
        .introjs-arrow{
            left: -26px;
        }
    }
    %step-position-top{
        margin-left: 5px;
        margin-top: 18px;
        .introjs-arrow{
            left: 20px;
            top: -31px;
        }
    }
    %step-position-right{
        margin-right: 20px;
        .introjs-arrow{
            right: -26px;
            top: 9px;
        }
    }

    &.this-step{

        &-detail_deals{
            .introjs-nextbutton{
                //display: none;
            }
        }
        &-detail_deals-empty{
            .introjs-nextbutton{
                display: block !important;
                opacity: 1 !important;
                pointer-events: inherit !important;
            }
        }
        &-deals{
            border: none;
            .introjs-tooltip{
                @extend %step-position-left;
            }
        }
        &-close_sidebar{
            .introjs-tooltip{
                margin-left: -5px;
                margin-top: 5px;
                .introjs-arrow{
                    left: 8px;
                    top: -28px;
                    &::before{
                        margin-left: -8px;
                    }
                }
            }
        }
        &-open_sidebar{
            .introjs-tooltip{
                @extend %step-position-top;
                .introjs-arrow{
                    &::before{
                        margin-left: -2px;
                    }
                }
            }
        }
        &-filter_view{
            .introjs-tooltip{
                margin-top: -35px;
            }
        }
        &-filter_view, &-customers, &-filter_contacts{
            .introjs-tooltip{
                @extend %step-position-left;
            }
        }
        &-filter_contacts{
            .introjs-tooltip{
                margin-top: 272px;
                .introjs-arrow{
                    &::before{
                        display: block;
                        margin-top: -7px;
                    }
                }
            }
        }
        &-filter_client{
            .introjs-tooltip{
                margin-left: 40px;
                .introjs-arrow{
                    left: -28px;
                    top: 3px;
                }
            }
        }
        &-sorting{
            .introjs-tooltip{
                margin-top: 25px;
                .introjs-arrow{
                    left: 50%;
                    transform: translateX(-50%);
                    top: -31px;
                }
            }
        }
        &-adverts,&-adverts_checkbox{
            .introjs-tooltip{
                @extend %step-position-left;
            }
        }
        &-adverts_toggle{
            .introjs-tooltip{
                margin-right: 10px;
                .introjs-arrow{
                    right: -16px;
                    top: 9px;
                }
            }
        }
        &-communications, &-communications_checkbox{
            .introjs-tooltip{
                @extend %step-position-left;
            }
        }
        &-communications_checkbox{
            .introjs-tooltip{
                margin-left: 40px;
            }
        }
        &-tasks,&-tasks_button{
            .introjs-tooltip{
                @extend %step-position-left;
            }
        }
        &-tasks_button{
            .introjs-tooltip{
                .introjs-arrow{
                    top: 3px;
                }
            }
        }
        &-reports, &-reports_filter, &-settings, &-help, &-personal_settings, &-menu_toggle, &-new_update{
            .introjs-tooltip{
                @extend %step-position-left;
            }
        }
        &-findvariants_button, &-search{
            .introjs-tooltip{
                @extend %step-position-top;
            }
        }
        &-create_task{
            .introjs-tooltip{
                margin-left: 5px;
                margin-top: 18px;
                .introjs-arrow{
                    left: inherit;
                    right: 17px;
                    top: -31px;
                }
            }
        }
        &-detail_deals{
            .introjs-tooltip{
               display: none !important;
            }
        }
        &-note_create_button, &-lot_create_button, &-task_create_button, &-place_create_button, &-transfer_create_button, &-add-button{
            .introjs-tooltip{
                @extend %step-position-top;
            }
        }
        &-deals_delete_button{
            .introjs-tooltip{
                @extend %step-position-top;
                margin-top: 20px;
                .introjs-arrow{
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        &-deals_reject_button{
            .introjs-tooltip{
                @extend %step-position-top;
                margin-top: 20px;
                .introjs-arrow{
                    right: 30px;
                    left: inherit;
                }
            }
        }
        &-return_list{
            .introjs-tooltip{
                @extend %step-position-left;
                transform: translateX(-28%);
                margin-top: 45px;
                .introjs-arrow{
                    top: -5px;
                    display: none !important;
                }
            }
        }
        &-synopsis_link, &-funnel_link, &-advert_link, &-feature_link, &-images_link, &-conditions_link, &-help_menu, &-finish{
            .introjs-tooltip{
                @extend %step-position-left;
            }
        }
    }

    &.guide__lock-event{
        .introjs-tooltiptext{
            span,
            b{
                display: none;
            }
        }
        .introjs-fixedTooltip{
            z-index: 1000000000;
        }
    }

}

.guide-finish{
    .sidebar-filter{
        opacity: 1;
    }
}

#guide-start{
    @include max-width-breakpoint($breakpoint-desktop-mid) {
        display: none !important;
    }
}

.search-sort-group__flex{
    display: flex;
    align-items: center;
    float: none !important;
    flex-wrap: wrap;
    justify-content: flex-end;
    ul{
        float: none;
    }
}
.search-read-header{
    .view-block{
        float: right;
    }
}

.view-block{
    color: $link-color;
    font-size: 18px;
    display: flex;
    align-items: center;
    margin-left: 30px;
    button{
        padding: 0;
        margin: 0;
        border: none;
        line-height: 1;
        display: block;
        &:focus{
            outline: none;
        }
        @include max-width-breakpoint($breakpoint-tablet) {
            display: none !important;
        }
    }
    &:hover{
        cursor: pointer;
        opacity: 0.8;
    }
}

.recrm-table-wrapper{
    &.contract-table__wrapper,
    &.customer-table__wrapper{
        @include min-width-breakpoint($breakpoint-pad-portrait){
            margin-left: -18px;
            margin-right: -18px;
            max-width: none;
            width: -webkit-calc(100% + 40px);
            width: calc(100% + 40px);
        }
    }
    .customer-table{
        tbody{
            tr{
                th{
                    @include min-width-breakpoint($breakpoint-pad-landscape){
                        white-space: nowrap;
                    }
                }
                td{
                    .link-item__contact{
                        & + .link-item__contact{
                            margin-top: 5px;
                        }
                        &:last-child{
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
    .find-variants-table,
    .customer-table,
    .contract-table{
        tbody{
            tr{
                .success{
                    color: $success-color !important;
                }
                .fail{
                    color: $danger-color !important;
                }
                td:not(.td__padding){
                    padding: 0 !important;
                    margin: 0 !important;
                    .height-td__inherit{
                        display: flex !important;
                        align-items: center;
                        padding: 5px 25px !important;
                        width: 100%;
                        color: $dark-text;
                        min-height: 60px;
                    }
                    .link-item__contact{
                        a{
                            display: inline-block !important;
                            width: auto !important;
                            color: $primary-color;
                            padding: 0 !important;
                            &:hover{
                                text-decoration: underline !important;
                            }
                        }
                    }
                    .customer-contact{
                        display: flex;
                        padding: 5px 0;
                        .customer-contact__item{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            &:last-child{
                                flex-grow: 2;
                            }
                            br{
                                &:last-child{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .customer-table,
    .contract-table{
        tr{
            td {
                width: auto !important;
                .fa-vcard{
                    display: none;
                }
                .contract-table__lot-content{
                    display: flex;
                    align-items: center;
                }
                .contract-table__lot-img{
                    width: 36px;
                    height: 36px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 15px;
                    background-size: cover;
                    background-position: center;
                    & + .contract-table__lot-name {
                        width: -webkit-calc(100% - 51px);
                        width: calc(100% - 51px);
                    }
                }
                .contract-table__lot-name{
                    display: flex;
                    flex-direction: column;
                }
                p{
                    margin: 0;
                }
                &.nowrap{
                    @include max-width-breakpoint($breakpoint-iphones) {
                        white-space: normal;
                    }
                }
            }
            th {
                color: $dark-color;
                &:first-child{
                    border-left: 1px solid $border-color;
                }
                &:last-child{
                    border-right: 1px solid $border-color;
                }
            }
            .text-nowrap{
                white-space: nowrap;
            }
            .text-center {
                text-align: center;
            }
            .text-left{
                text-align: left;
            }
            .text-right{
                text-align: right;
            }
            .cell-checkbox {
                width: 19px !important;
                padding-left: 25px !important;
                padding-right: 0 !important;
                input[type="radio"], input[type="checkbox"] {
                    margin-top: 0;
                }
                .radio, .checkbox {
                    margin-top: 0;
                    padding-top: 0;
                    label > span {
                        padding-left: 0;
                    }
                    label > span::after,
                    label > span::before {
                        top: -7px !important;
                    }
                }
                .checkbox {
                    label{
                        span{
                            &::before{
                                top: 0;
                            }
                        }
                    }
                }

            }
            th.th-images{
                padding: 0 !important;
            }
            td.td-images{
                padding-right: 0 !important;
                font-size: 24px;
                color: $disabled;
                text-align: center;
                width: 36px !important;
                text-align: center;
                a{
                    img{
                        width: 36px;
                        height: 36px;
                        display: block !important;
                        margin-left: auto;
                        margin-right: auto;
                        &:hover{
                            cursor: pointer;
                        }
                    }
                    .fas{
                        color: $disabled;
                    }
                }
                [data-popup-img]{
                    width: 36px;
                    height: 36px;
                    display: block !important;
                    margin-left: auto;
                    margin-right: auto;
                    &:hover{
                        cursor: pointer;
                    }
                }
            }
            @include max-width-breakpoint($breakpoint-1800){

                th, td{
                    padding-left: 18px !important;
                    padding-right: 18px !important;
                }

            }
            @include max-width-breakpoint($breakpoint-notebook-big){
                th, td{
                    padding-left: 13px !important;
                    padding-right: 13px !important;
                }
                .cell-updated{
                    white-space: normal;
                }
            }
        }
    }
}
.circle-borders {
    border-radius: 50%;
}

.alert-warning,
.alert-danger,
.alert-info {
    .fl-left{
        margin-right: 10px;
        margin-top: -1px;
        .tooltip{
            left: 36px !important;
            .tooltip-arrow{
                left: 14px !important;
            }
        }
    }
}

#drop-list-toggle{
    &.active-button{
        background-color: $primary-color;
        color: #ffffff;
    }
}

.drop-list__wrapper{
    position: fixed;
    left: 10px;
    top: 100%;
    z-index: 100;
    background-color: #ffffff;
    width: calc(100% - 20px);
    border: 1px solid $border-color;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    overflow: hidden;
    display: none;
    visibility: hidden;
    margin-top: 5px;
    &.open{
        display: block;
        visibility: visible;
    }
    ul{
        list-style-type: none;
        padding: 0;
        margin: 0;
        li{
            padding: 0 !important;
            margin: 0 !important;
            button{
                background-color: transparent;
                width: 100%;
                display: block;
                padding: 11px 12px;
                border: none;
                border-bottom: 1px solid $border-color;
                text-align: left;
                color: $primary-color;
                line-height: 1;
                font-size: 14px;
                white-space: nowrap;
                &:active,
                &:hover{
                    background-color: $body-background-color;
                }
                &:focus{
                    outline: none;
                }
            }
            &:last-child{
                button{
                    border-bottom: none;
                }
            }
        }
    }
}

.width-text{
    position: absolute;
    width: auto;
    visibility: hidden;
    left: -9999px;
}

.navbar-body-wrapper{
    & ~ .attention--default{
        margin-top: 50px;
    }
}

.attention{
    background-color: $sidebar-bg;
    padding: 30px 40px;
    display: flex;
    align-items: center;

    &--default{
        & ~ .main-content{
            padding-top: 30px !important;
        }
    }
    @include max-width-breakpoint($breakpoint-desktop) {
        padding: 15px;
        &__text{
            padding-right: 40px !important;
            h2{
                font-size: 18px !important;
            }
            p{
                font-size: 14px !important;
            }
        }
    }
    @include max-width-breakpoint($breakpoint-pad-landscape) {
        flex-direction: column;
        &__text{
            order: 2;
            padding-right: 0 !important;
            h2{
                font-size: 18px !important;
            }
            p{
                font-size: 14px;
            }
        }
        &__img{
            order: 1;
            margin-bottom: 20px;
        }
    }
    &__text{
        padding-right: 70px;
        color: #ffffff;
        h2{
            margin-top: 0;
            margin-bottom: 20px;
            font-size: 18px;
            line-height: 21px;
        }
        p{
            margin: 0;
            font-size: 14px;
            line-height: 21px;
            & + p{
                margin-top: 20px;
            }
            a{
                text-decoration: underline;
                color: $warning-color;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}

.white-space-md-nowrap{
    @include min-width-breakpoint($breakpoint-pad-landscape) {
        white-space: nowrap;
    }
}

.dropdown-menu{
    z-index: 1050;
}

[name=omni_widget_iframe_widget_13892-p5j7h8hr_name] {
    z-index: 20000 !important;
}
.mt{
    &-20 {
        margin-top: 20px;
    }
}