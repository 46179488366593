@charset "UTF-8";
.non-residential-premises {
    .form {
        .textarea {
            margin-top: 15px;
        }
    }
    .map {
        height: 400px;
        margin: {
            left: 0;
            right: 0;
            bottom: 10px;
        }
    }
    .address-is-ok-button {
        margin-left: 10px;
    }
    .update-address-button {
        margin-left: 10px;
    }
    .do-not-update-address-button {
        margin-left: 5px;
    }
    .content-subtitle-with-checkbox {
        width: 100%;
        text-align: left;
    }
    .buildings-block {
        .advanced-checkbox {
            span {
                white-space: normal;
            }
        }
    }
    [data-resize-height] {
        overflow: hidden;
        resize: none;
        height: 34px;
        min-height: 34px;
    }
}
