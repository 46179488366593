@charset "UTF-8";

.card-item {
    position: relative;
    min-height: 100px;
    margin: {
        top: 0;
        left: 0;
        right: 0;
        bottom: 20px;
    }
    padding: {
        top: 7px;
        left: $paddingSideItem;
        right: $paddingSideItem;
        bottom: 8px;
    }
    border-radius: $card-radius + px;
    background-color: $light-color;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.05);
    border: solid 1px $border-color;
    overflow: hidden;
    &:hover {
        .card-item-btn-absolute {
            display: block;
        }
    }
    &.bs-callout {
        position: relative;
        padding-left: $paddingSideItem + $bs-witdh;
        border: solid 1px $border-color !important;
        &:before {
            content: '';
            position: absolute;
            width: $bs-witdh + px;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            border-radius: $card-radius + px 0px 0px $card-radius + px;
        }
        &.bs-callout-red {
            &:before {
                background-color: $danger-color;
            }
        }
        &.bs-callout-green {
            &:before {
                background-color: $success-color;
            }
        }
        &.bs-callout-blue {
            &:before {
                background-color: $primary-color;
            }
        }
        &.bs-callout-cyan {
            &:before {
                background-color: $info-color;
            }
        }
        &.bs-callout-orange {
            &:before {
                background-color: $warning-color;
            }
        }
        &.bs-callout-grey {
            &:before {
                background-color: $secondary-color;
            }
        }
        .card-item-title {
            margin-bottom: 0;
        }
    }
    &.isolate {
        margin: 0;
        padding: 0;
        border: 0;
        box-shadow: none;
        border-radius: 0;
        min-height: 0;
    }
    &.table-view {
        display: table;
        width: 100%;
        padding: 0;
        @include max-width-breakpoint($breakpoint-iphones) {
            display: block;
        }
        .card-item-row {
            padding: {
                top: 18px;
                bottom: 18px;
                left: 10px;
                right: 10px;
            }
            &:first-child {
                margin: {
                    left: 0;
                    right: 0;
                }
            }
        }
        .card-item-cell {
            @include clearfix;
            display: table-cell;
            font-size: 13px;
            line-height: 1.23;
            vertical-align: middle;
            & + .card-item-cell {
                padding-left: 40px;
            }
            @include max-width-breakpoint($breakpoint-iphones) {
                display: block;
                & + .card-item-cell {
                    padding-left: 0;
                    margin-top: 10px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    &.selected {
        background-color: $success-lt-color;
    }
    &.deferred {
        background-color: #f2f2f2;
    }
    &.issue-list-item {
        .card-item-header {
            margin-bottom: 5px;
        }
    }
    &-add {
        display: flex;
        white-space: nowrap;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        padding: 20px;
        background: $light-color;
        border: 1px dotted $primary-color;
        border-radius: 3px;
        color: $link-color !important;
        cursor: pointer;
        .fa {
            font-size: 24px;
        }
    }
    .contact {
        font: {
            size: 12px;
        }
        line-height: 2;
        .fa {
            width: 12px;
            font-size: 12px;
            text-align: center;
            vertical-align: middle;
        }
        .fa-2x {
            font-size: 1.7em;
        }
    }
    .contacts {
        padding-top: 4px;
    }
    .description + .contacts {
        padding-top: 4px;
    }
    .status {
        width: 20%;
        float: right;
        padding-top: 6px;
        font-size: 12px;
        line-height: 1;
        color: $info-alter-color;
        overflow: hidden;
        text-transform: capitalize;
        text: {
            align: right;
            overflow: ellipsis;
        }
        white-space: nowrap;
        &.text-success {
            color: $success-color;
        }
    }
    .agent-name {
        font-weight: bold;
    }
    .description {
        position: relative;
        font-size: 13px;
        line-height: 1.5;
        padding-right: 40px;
    }
    .text {
        padding-top: 7px;
        padding-bottom: 7px;
    }
    .address {
        font-size: 15px;
        line-height: 1.33;
        color: #333333;
    }
    .picture {
        padding: 0;
        margin: {
            left:  -$paddingSideItem;
            right: -$paddingSideItem;
        }
        background-color: #eee;
        border: none;
        .image {
            object-fit: cover;
            width: 100%;
            height: 160px;
            @include max-width-breakpoint($breakpoint-iphones) {
                height: 100px;
            }
        }
    }
    .subtask-picture {
        padding: 0;
        .image {
            object-fit: cover;
            width: 100%;
            height: 120px;
            border-radius: 5px;
        }
    }
    .subtask-picture + .address {
        padding: {
            top: 5px;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .property-title {
        font-size: 15px;
        line-height: 1.6;
        sup {
            font-size: 55%;
            top: -0.7em;
        }
    }
    .price {
        font: {
            weight: normal;
            size: 16px;
        }
        line-height: 1.88;
        text-align: left;
        display: inline-block;
        text-align: right;
        @include max-width-breakpoint($breakpoint-desktop-notebook) {
            line-height: 1;
            padding-top: 6px;
            margin-bottom: 7px;
        }
    }
    .price-secondary {
        font-size: 14px;
        font-style: italic;
        line-height: 1.14;
        color: #9b9b9b;
        .price-small {
            color: #9b9b9b;
        }
    }
    .price-small {
        font-size: 14px;
        line-height: 2.14;
        @include max-width-breakpoint($breakpoint-desktop-notebook) {
            display: inline-block;
            line-height: 1.3;
        }
    }
    .price-period {
        padding-bottom: 9px;
    }
    .period {
        font: {
            weight: bold;
            size: 14px;
        }
        line-height: 1.14;
    }
    .duration {
        padding: {
            top: 4px;
            bottom: 2px;
        }
        font: {
            weight: bold;
            size: 14px;
        }
        line-height: 1.14;
    }
    .customer {
        font-size: 14px;
        line-height: 1.43;
        vertical-align: bottom;
    }
    .map {
        height: 200px;
        border: {
            radius: 0;
            left: 0;
            right: 0;
        }
    }
    .query-property {
        padding: {
            top: 10px;
            left: 0;
            right: 0;
            bottom: 5px;
        }
        & + .query-property {
            border-top: 1px solid $border-color;
        }
        .property-title {
            margin-bottom: 4px;
        }
    }
    &.checkboxed {
        min-height: 40px;
        margin-top: 10px;
        .checkbox {
            margin: {
                top: 0;
                bottom: 0;
            }
        }
    }
    .synopsis-pipeline {
        margin-bottom: 8px;
        .done {
            text-decoration: line-through;
        }
    }
}
.card-item-btn-absolute {
    display: block;
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
    @include max-width-breakpoint($breakpoint-tablet) {
        background-color: $danger-color !important;
        color: #ffffff !important;
    }
}
.card-item-row {
    @include clearfix;
    padding: {
        top:    10px;
        bottom: 8px;
    }
    font-size: 12px;
    line-height: 1.23;
    &:first-child {
        padding: {
            top: 0;
            left: $paddingSideItem;
            right: $paddingSideItem;
        }
        margin: {
            left: -$paddingSideItem;
            right: -$paddingSideItem;
        }
        border-bottom: 1px solid #E8E8E8;
    }
    &:last-child {
        padding-bottom: 0;
    }
    &.text-small {
        font-size: 12px;
        .fa {
            width: 14px;
            margin-right: 4px;
            text-align: center;
        }
    }
    &.with-map {
        padding: 0;
        margin: {
            left: -$paddingSideItem;
            right: -$paddingSideItem;
        }
        .map {
            height: 200px;
            margin-bottom: 0 !important;
            border: {
                left: 0;
                right: 0;
                top: 0;
                radius: 0;
            }
        }
    }
}
.card-item-title {
    margin: {
        top: 0;
        bottom: 0;
    }
    font: {
        size: 15px!important;
    }
    line-height: 17px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    sup {
        font-size: 55%;
        top: -0.7em;
    }
    a {
        vertical-align: middle;
    }
}
.card-item .card-item-customer {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.233333px;
}
.card-item-separator {
    position: relative;
    display: block;
    margin: {
        left: -$paddingSideItem;
        right: -$paddingSideItem;
    }
    height: 1px;
    background-color: #E8E8E8;
    z-index: 2;
}
.card-item-lots-item {
    font-size: 13px;
    line-height: 15px;
    & + & {
        margin-top: 10px;
    }
}
.card-item-date {
    margin-bottom: 5px;
}
.card-item-header {
    width: 80%;
    float: left;
    font: {
        size: 12px;
        weight: normal;
    }
    line-height: 18px;
    color: $navbar-link-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    .fa {
        font-size: 13px;
    }
    &.is-short {
        width: 50%;
    }
    &.is-short  + .status {
        width: 50%;
    }
}
.card-item-table {
    width: 100%;
    padding: 0;
    border: 0;
    box-shadow: none;
    font-size: 12px;
    line-height: 2.5;
    margin-bottom: -9px;
    td {
        width: 50%;
    }
    tr + tr {
        border-top: 1px solid #EEE;
    }
    @include max-width-breakpoint($breakpoint-iphones) {
        td {
            line-height: 1.4;
            padding: {
                top: 4px;
                bottom: 4px;
            }
        }
    }
}
.card-item-agent {
    font-style: italic;
    font-weight: normal;
}
.card-item-date {
    font-style: italic;
    line-height: 1.4;
    padding-top: 4px;
    color: $navbar-link-color;
}
.card-item-description-btn {
    color: $muted-color;
    border-bottom: 1px dotted $muted-color;
    &:hover {
        border-bottom-color: transparent;
        cursor: pointer;
    }
}