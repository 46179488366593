@charset "UTF-8";
.request-synopsis {
    @include max-width-breakpoint($breakpoint-pad-portrait) {
        .panel-separator {
            display: none;
        }
        .synopsis-info {
            margin-bottom: 20px;
        }
        .request-synopsis-caption {
            margin-bottom: 20px;
        }
    }
    .synopsis-info .card-item-header {
        width: 100%;
    }
    .synopsis-info .card-item-header .card-item-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .request-synopsis-workflow {
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .request-synopsis-workflow .synopsis-subtitle {
        display: block;
        margin-bottom: 20px;
    }
    .request-synopsis-workflow-status {
        width: 18px;
        display: inline-block;
    }
    .request-synopsis-workflow li {
        margin-bottom: 10px;
        font-size: 13px;
        line-height: 1.23;
    }

    .request-synopsis-activities {
        padding-top: 5px;
        padding-bottom: 10px;
        clear: both;
        .delete-button {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 34px;
            border: none;
            background-color: $dark-color;
            opacity: 0.25;
            color: $light-color;
            z-index: 999;
            &:hover {
                background-color: $danger-color;
                opacity: 1.0;
            }
            @include max-width-breakpoint($breakpoint-tablet) {
                position: inherit;
                top: inherit;
                bottom: inherit;
                right: inherit;
                display: block;
                padding: 6px 12px;
                opacity: 1;
                min-height: 34px;
                border-radius: 3px;
                margin-left: auto;
                background-color: $danger-color;
                color: #ffffff;
                font-size: 14px;
                width: auto;
                margin-top: 13px;
            }
        }
        .activity-icon {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 34px;
            border: none;
            z-index: 999;
            background: transparent;
        }
        .activity-item:hover .delete-button {
            display: block;
        }
    }
    .request-synopsis-activities .synopsis-subtitle {
        display: block;
        margin-bottom: 20px;
    }

    .synopsis-pipeline {
        .checkbox {
            margin: 0;
        }
        .synopsis-pipeline-task {
            padding-left: 0;
            .checkbox label {
                padding-left: 12px;
                line-height: 12px;
                min-width: 12px;
            }
            .checkbox label > span::before {
                width: 14px;
                height: 14px;
                top: -2px;
                margin-left: -12px;
            }
            .advanced-checkbox .checked + span::after {
                margin-left: -12px;
                font-size: 12px;
            }
        }
        .synopsis-pipeline-task-required {
            font-weight: bold;
            .checkbox {
                label {
                    font-weight: bold;
                }
            }
        }
        .synopsis-pipeline-tasklist {
            .synopsis-pipeline-task-done {
                .checkbox {
                    label {
                        & > span {
                            text-decoration: line-through;
                        }
                    }
                }
            }
        }
    }
}