@charset "UTF-8";
.tasks-calendar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  white-space: nowrap;
  &-wrapper {
    overflow-x: auto;
    cursor: -webkit-grab;
    cursor: grab;
    &.search-read-results {
      margin-left:  18px !important;
      margin-right: 18px !important;
      @include max-width-breakpoint($breakpoint-pad-portrait) {
        margin-left:  12px !important;
        margin-right: 12px !important;
      }
    }
    height: calc(100vh - 155px);
  }
  &-col {
    position:      relative;
    width:         269px;
    padding-left:  10px;
    padding-right: 10px;
    white-space: nowrap;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    &:last-child {
      width:         285px;
      padding-right: 45px;
    }
  }
  &-items {
    padding-bottom: 120px;
  }
  &-date {
    font-size: 12px;
    border-bottom: 2px solid $dark-color;
    &-green {
      border-color: $success-color;
    }
    &-red {
      border-color: $danger-color;
    }
    &-blue {
      border-color: $primary-color;
    }
    &-cyan {
      border-color: $info-color;
    }
    &-orange {
      border-color: $warning-color;
    }
    &-grey {
      border-color: $secondary-color;
    }
  }
  &-item {
    margin-top:  15px;
    position: relative;
    width: 100%;
    border-radius: 0;
    background-color: $light-color;
    border-bottom: 1px solid #EEE;
    font-size: 14px;
    .task-type {
      font-style: italic;
    }
    .task-item-result {
      font-style: italic;
    }

    &.task-in-progress {
      .task-item-duration.expired {
        color: $danger-color;
      }
    }
    &.task-closed {
      background-color: #fafafa;
    }
    &.task-disabled {
      background-color: #fafafa;
    }
    .task-item-content {
      padding: {
        top: 13px;
        bottom: 13px;
        left: 12px;
        right: 12px;
      }
    }
    .task-item-description {
      font-weight: bold;
      white-space: normal;
    }
    .task-item-duration {
      font-style: italic;
    }
    .task-item-agents {
      font-style: italic;
    }
    .task-item-row {
      &:not(:first-child) {
        margin-top: 10px;
      }
      white-space: normal;
    }
    .task-item-buttons {
      display: table;
    }
    .task-item-button {
      float: left;
      margin-right: 10px;
    }

    .task-item-fileslist {
      padding-top: 10px;
    }

    &:before {
      content: '';
      position: absolute;
      width: 4px;
      height: 100%;
      top: 0;
      bottom: 0;
      left: 0;
    }

    &.task-red:before {
      background-color: $danger-color;
    }
    &.task-green:before {
      background-color: $success-color;
    }
    &.task-orange:before {
      background-color: $warning-color;
    }
    &.task-grey:before {
      background-color: $lighter-secondary-color;
    }

    .task-status {
      font-style: italic;
    }

    .task-status-circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: inline-block;

      &.task-status-circle-green {
        background: $success-color;
      }
      &.task-status-circle-orange {
        background: $warning-color;
      }
      &.task-status-circle-gray {
        background: $lighter-secondary-color;
      }
    }
  }
}
